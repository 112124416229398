import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Card, Button, OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import CsLineIcons from '../../../cs-line-icons/CsLineIcons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { fetchPhotoShootList } from '../actions/photoshootAction';
import { SORTING_ORDER } from '../../../constants/index';

import moment from 'moment';

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from 'reactstrap';
//import {useIntl} from 'react-intl';
import CountUp from 'react-countup';

const ManageProductList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();
  //const { formatMessage: f } = useIntl();
  //const [countryCode, setCountryCode] = useUrlSearchParams({ country: "" });

  const [sortingName, setSortingName] = useState({ id: 'sort.date_asc' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [customActiveTab, setcustomActiveTab] = useState('All');
  const [orderMode, setOrderMode] = useState('All');
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);
  // console.log("country-----------",country);

  const splitVal = location?.pathname.split('/');

  useEffect(() => {
    setDataLoading(true);
    fetchPhotoShootList({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        orderBy: 'id',
      },
      dispatch,
    });
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    country,
    orderMode,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { PhotoShootLists = [], isLoading } = useSelector(
    (state) => state.photoshoot
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = PhotoShootLists;
  const orderData = listOrders?.orderList;

  let LsSts = 'New';
  const [allCnts, setallCnts] = useState(0);
  const [merchandisingCnts, setMerchandisingCnts] = useState(0);
  const [photoshootCnts, setPhotoshootCnts] = useState(0);
  const [contentCnts, setContentCnts] = useState(0);
  const [websiteCnts, setWebsiteCnts] = useState(0);
  const [completedCnts, setCompletedCnts] = useState(0);

  const stsCounts = PhotoShootLists?.data;
  //console.log("stsCounts--------",stsCounts)
  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

  useEffect(() => {
    statusArr = [];
    let allCounts = 0;

    if (stsCounts) {
      Object.keys(stsCounts).forEach(function (key) {
        // console.log("Key------------",key)
        let Sts = key.replace(/\s+/g, '');
        let Counts = stsCounts[key];
        allCounts += Number(stsCounts[key]);
        const countsArr = { [Sts]: Counts };
        statusArr.push(countsArr);
      });
      statusArr.push({ allCounts: allCounts });
    }
    setallCnts(getValue(statusArr, 'allCounts'));
    setMerchandisingCnts(getValue(statusArr, 'merchandising'));
    setPhotoshootCnts(getValue(statusArr, 'photoshoot'));
    setContentCnts(getValue(statusArr, 'content'));
    setWebsiteCnts(getValue(statusArr, 'website'));
    setCompletedCnts(getValue(statusArr, 'completed'));
  }, [stsCounts]);

  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: { id: 'sort.date_asc' } },
    { key: 'date', order: SORTING_ORDER.DESC, label: { id: 'sort.date_desc' } },
    {
      key: 'status',
      order: SORTING_ORDER.ASC,
      label: { id: 'sort.status_asc' },
    },
    {
      key: 'status',
      order: SORTING_ORDER.DESC,
      label: { id: 'sort.status_desc' },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    // console.log("ActiveTab----", tab);
    if (customActiveTab !== tab) {
      // console.log("customActiveTab----", tab);
      setcustomActiveTab(tab);
    }
  };

  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="OrderList row-fluid-scroll">
        <Col xl={2} md={2} xs={2}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'All',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn  ${classnames({
                      active: customActiveTab === 'All',
                    })}`}
                    onClick={() => {
                      toggleCustom('All');
                    }}
                  >
                    All
                    <span className="counter-value">
                      {/* {isreturnStatusLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                        />
                      ) : (
                        <h4>
                          {" "}
                          <CountUp start={0} end={allCnts} duration={1} />{" "}
                        </h4>
                      )} */}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={2} md={2} xs={2}>
          <Card
            className={`card-h-100 cntsHeight  ${classnames({
              active: customActiveTab === 'merchandising',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'merchandising',
                    })}`}
                    onClick={() => {
                      toggleCustom('merchandising');
                    }}
                  >
                    Merchandising
                    {/* <span className="counter-value">
                      {isreturnStatusLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                        />
                      ) : (
                        <h4>
                          {" "}
                          <CountUp
                            start={0}
                            end={pendingCnts}
                            duration={1}
                          />{" "}
                        </h4>
                      )}
                    </span> */}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={2} md={2} xs={2}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'photoshoot',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'photoshoot',
                    })}`}
                    onClick={() => {
                      toggleCustom('photoshoot');
                    }}
                  >
                    PhotoShoot/Editing
                    {/* <span className="counter-value">
                      {isreturnStatusLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                        />
                      ) : (
                        <h4>
                          <CountUp start={0} end={authorizedCnts} duration={1} />
                        </h4>
                      )}
                    </span> */}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={2} md={2} xs={2}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'content',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'content',
                    })}`}
                    onClick={() => {
                      toggleCustom('content');
                    }}
                  >
                    Content
                    {/* <span className="counter-value">
                      {isreturnStatusLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                        />
                      ) : (
                        <h4>
                          <CountUp start={0} end={receivedCnts} duration={1} />
                        </h4>
                      )}
                    </span> */}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={2} md={2} xs={2}>
          <Card
            className={`card-h-100 cntsHeight  ${classnames({
              active: customActiveTab === 'website',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'website',
                    })}`}
                    onClick={() => {
                      toggleCustom('website');
                    }}
                  >
                    Website
                    {/* <span className="counter-value">
                      {isreturnStatusLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                        />
                      ) : (
                        <h4>
                          <CountUp start={0} end={approvedCnts} duration={1} />
                        </h4>
                      )}
                    </span> */}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={2} md={2} xs={2}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'completed',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'completed',
                    })}`}
                    onClick={() => {
                      toggleCustom('completed');
                    }}
                  >
                    Completed
                    {/* <span className="counter-value">
                      {isreturnStatusLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                        />
                      ) : (
                        <h4>
                          <CountUp start={0} end={rejectedCnts} duration={1} />
                        </h4>
                      )}
                    </span> */}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="align-items-end">
        <Col xl={2} md={6} className="mb-3 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>To Ref#</th>
                  <th>To Date</th>
                  <th>Name</th>
                  <th>BudgetCategory </th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;
                const { entity_id: orderid } = order;
                let cdate = order.CreatedDate,
                  orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                  orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                let stsBtn = 'bg-soft-dark text-dark';
                if (order.Status === 'Pending') {
                  stsBtn = 'bg-soft-warning text-warning';
                  LsSts = 'Pending';
                } else {
                  LsSts = order.Status;
                }
                if (order.Status === 'Authorized') {
                  stsBtn = 'bg-primary';
                } else if (
                  order.Status === 'Cancel' ||
                  order.Status === 'cancel' ||
                  order.Status === 'pending_payment'
                ) {
                  stsBtn = 'bg-danger';
                } else if (order.Status === 'Approved') {
                  stsBtn = 'bg-success';
                }

                if (
                  order.Status === 'Rejected' ||
                  order.Status === 'Canceled' ||
                  order.Status === 'cancel'
                ) {
                  LsSts = 'Canceled';
                  stsBtn = 'bg-danger';
                }
                return (
                  <tbody key={order.PhotoshootId}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td>
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>{order.ToRef}</td>
                      <td>{order.ToDate}</td>
                      <td>{order.ProductName}</td>
                      <td>{order.BudgetCategory}</td>
                      <td>
                        {/* <Badge
                          style={{ paddingBottom: "3px" }}
                          className={` ${stsBtn} me-2`}
                        >
                          {LsSts}
                        </Badge> */}
                      </td>
                      <td>
                        <a
                          href={`photoshootdetail/${order.PhotoshootId}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {' '}
                          <i className="mdi mdi-eye-outline"></i>{' '}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-dark">
                    {limitValue.limit} Items{' '}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>To Ref#</th>
                  <th>To Date</th>
                  <th>Name</th>
                  <th>BudgetCategory </th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default ManageProductList;
