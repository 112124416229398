import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
//import OrderList from './components/orderList';
// import BulkOrderList from "./components/bulkorders/BulkOrderList";
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import Flatpickr from 'react-flatpickr';
import toast, { Toaster } from 'react-hot-toast';

import CustomerTrackingList from './components/CustomerTrackingList';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import {
  Col,
  Card,
  Container,
  Row,
  FormGroup,
  InputGroup,
  CardBody,
  CardHeader,
  Modal,
  CardTitle,
  Input,
  Spinner,
} from 'reactstrap';
import { fetchLeadFormData } from './action/action';

const CustomerTracking = () => {
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const dispatch = useDispatch();
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  const title = 'Orders';
  const description = 'Customer Tracking';
  let UserRoleID = '';

  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserRoleID = userObj?.role_id;
    }
  }
  const handleValidSubmit = (events, value) => {
    setUpdateDetailsLoad(true);
    const form = events.currentTarget;
    const formData = new FormData();

    formData.append('cname', events.target.cname.value);
    formData.append('mobile', events.target.mobile.value);
    formData.append('email', events.target.email.value);
    formData.append('region', events.target.region.value);
    formData.append('address', events.target.address.value);
    formData.append('scope', events.target.scope.value);
    formData.append('cstatus', events.target.cstatus.value);
    formData.append('customer_remarks', events.target.customer_remarks.value);
    formData.append('country', events.target.country.value);
    formData.append('updated_by', events.target.updated_by.value);

    fetchLeadFormData({
      formData,

      dispatch,
    });
  };
  const { formLeadUpdate = [], isFormLeadLoading } = useSelector(
    (state) => state.bccw
  );
  useEffect(() => {
    if (formLeadUpdate?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(formLeadUpdate?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (formLeadUpdate?.message) {
        toast.error(formLeadUpdate?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [formLeadUpdate]);
  return (
    <React.Fragment>
      <div className="page-content trackingOrder">
        <MetaTags>
          <title>Customer Tracking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={11}>
              <DashBreadcrumb
                title="Project"
                breadcrumbItem="Project Track - Leads"
              />
            </Col>
            <Col xl={1}>
              <button
                type="submit"
                className="btn btn-dark"
                onClick={toggleRightDrawer}
                disabled={open}
              >
                New Lead
              </button>
            </Col>
            {/* <OrderExportReport /> */}
          </Row>
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
            className="sideNavWidth"
          >
            <Card className="card-h-100 OrderDetailsBox">
              <CardTitle
                style={{
                  textAlign: 'center',
                  fontSize: '25px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                LEAD FORM{' '}
              </CardTitle>

              <CardBody>
                <>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <AvField
                      name="updated_by"
                      id="updated_by"
                      type="hidden"
                      value={UserRoleID}
                    />
                    <AvField
                      name="country"
                      id="country"
                      type="hidden"
                      value="AE"
                    />
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="cname"
                            label="Customer Name*"
                            // value={RefundData?.OrderNo}
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="mobile"
                            label="Mobile No*"
                            type="text"
                            // value={RefundData?.CreatedDate}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="email"
                            label="Email*"
                            // value={RefundData?.Amount}
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <AvField
                            type="select"
                            name="region"
                            label="Region*"
                            required
                          >
                            <option value="abudhabi">Abu Dhabi</option>
                            <option value="dubai">Dubai</option>
                            <option value="sharjah">Sharjah</option>
                            <option value="ajman">Ajman</option>
                            <option value="alquwain">Umm Al-Quwain</option>
                            <option value="fujairah">Fujairah</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <AvField
                            name="address"
                            label="Customer Address*"
                            value=""
                            className="form-control"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <AvField
                            type="select"
                            name="scope"
                            label="Scope Type*"
                            required
                          >
                            <option value="Full Villa">Full Villa</option>
                            <option value="Tv Unit">Tv Unit</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <AvField
                            type="select"
                            name="cstatus"
                            label="Status*"
                            required
                          >
                            <option value="1">New</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col lg="12">
                      <FormGroup className="mb-3">
                        <AvField
                          name="customer_remarks"
                          label="Remarks*"
                          value=""
                          className="form-control"
                          type="textarea"
                          rows="5"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <button
                      type="submit"
                      className="btn btn-dark"
                      data-toggle="modal"
                    >
                      Submit
                    </button>
                  </AvForm>
                </>
              </CardBody>
            </Card>
          </ReactDrawer>

          <CustomerTrackingList />
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default CustomerTracking;
