export const OMS_URL = {
  BASE_URL: 'https://omsapi.danubehome.com/',
};
export const HOME_URL = {
  BASE_URL: 'https://danubehome.com/',
};

export const LIVE_URL = {
  BASE_URL: 'https://betaoms.danubehome.com/',
};
export const SORTING_ORDER = {
  ASC: 1,
  DESC: -1,
};
export const SORTING_CUSTOMER = {
  ASC: 1,
  DESC: -1,
};
export const SORTING_CATEGORY_LIST = {
  ASC: 1,
  DESC: -1,
};
export const STORE_LIST = [
  {
    country: 'UAE',
    code: 'AE',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
  {
    country: 'BAHRAIN',
    code: 'BH',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
  {
    country: 'OMAN',
    code: 'OM',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
  {
    country: 'QATAR',
    code: 'QA',
    language: [
      { title: 'English', code: 'en' },
      { title: 'Arabic', code: 'ar' },
    ],
  },
];
export const GUEST_USER_CART_TOKEN_KEY = '_gct';
export const AUTH_TOKEN = '_ut';
export const CURRENT_COUNTRY = 'ae';
export const CURRENT_LANGUAGE = 'en';
export const DEFAULT_CURRENCY = 'AED';
export const DEFAULT_COUNTRY = 'ae';
export const DEFAULT_LANGUAGE = 'en';
export const ARABIC_LANGUAGE = 'ar';
export const HOME_DELIVERY_METHOD = 'bestway';

export const EXPORT_ALLOWED_USER_ROLES = ['1', '6', '7', '10', '20', '13'];
export const SELLER_DETAILS_ALLOWED_USER_ROLES = ['38', '1'];
export const RETURN_DETAILS_ALLOWED_USER_ROLES = ['2', '1', '38'];
export const WAREHOUSE_ALLOWED_USER_ROLES = ['20'];

export const DEFAULT_IS_FREE_COD = false;
export const DEFAULT_IS_FREE_SHIPPING = false;
export const DEFAULT_IS_REMOVE_PROMO_PRICES = false;

export const PAYMENT_METHODS = [
  {
    label: 'Payment Methods',
    options: [
      { label: 'All', value: 'All' },
      { label: 'Apple Pay', value: 'apple_pay' },
      { label: 'Benefit', value: 'benefit' },
      { label: 'Cashew', value: 'cashew' },
      { label: 'COD', value: 'cashondelivery' },
      { label: 'Checkout', value: 'checkout' },
      { label: 'IFIN', value: 'ifin' },
      { label: 'PayFort', value: 'payfort' },
      { label: 'Payfort Oman Net', value: 'payfort_omannet' },
      { label: 'Oman Net', value: 'oman_net' },
      { label: 'Payfort Installments', value: 'payfort_fort_installments' },
      { label: 'PostPay', value: 'postpay' },
      { label: 'Spotii', value: 'spotiipay' },
      { label: 'Synapse', value: 'synapse' },
      { label: 'Tamara', value: 'tamara' },
      { label: 'Tabby', value: 'tabby' },
      { label: 'Tap', value: 'tap' },
      { label: 'Tap Careem Pay', value: 'tap_careem_pay' },
      { label: 'Tap Apple Pay', value: 'tap_apple_pay' },
      { label: 'Tap Knet', value: 'tap_knet' },
    ],
  },
];

export const DEVICE_TYPES = [
  {
    label: 'Device',
    options: [
      { label: 'All', value: 'All' },
      { label: 'IOS App', value: 'app-ios' },
      { label: 'Android App', value: 'app-android' },
      { label: 'Mobile Web', value: 'Mobile' },
      { label: 'Desktop', value: 'Desktop' },
      { label: 'Store Kiosk', value: 'kiosk' },
    ],
  },
];

export const ORDER_TYPES = [
  {
    label: 'Order Type',
    options: [
      { label: 'All', value: 'All' },
      { label: 'Ware House', value: '4' },
      { label: 'Courier', value: '5' },
      { label: 'Sanitary', value: '7' },
      { label: 'Back to Back', value: '2' },
      { label: 'Click & Collect', value: '3' },
      { label: 'Gift Card', value: '6' },
      { label: 'Installation', value: '8' },
    ],
  },
];

export const FILTER_BY_CHARGES = [
  {
    label: 'Shipping Charge',
    options: [
      { label: 'All', value: 'All' },
      { label: 'COD Amt', value: 'cod_fee' },
      { label: 'Shipping Amt', value: 'shipping_amt' },
      { label: 'Discount Amt', value: 'discount_amt' },
      { label: 'Giftcard Amt', value: 'giftcard_discount' },
      { label: 'Assembly Amt', value: 'assembly_amt' },
      { label: 'Ahlan Amt', value: 'ahlan_discount' },
      { label: 'Wallet Amt', value: 'wallet_amount' },
      { label: 'Express Shipping Amt', value: 'express_shipping_amt' },
    ],
  },
];
