import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Card, Button, OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import CsLineIcons from '../../../cs-line-icons/CsLineIcons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { fetchProjectTrackList } from '../action/action';
import { SORTING_ORDER } from '../../../constants/index';
import moment from 'moment';

import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
} from 'reactstrap';
import CountUp from 'react-countup';
import { capitalize } from 'lodash';

const CustomerTrackingList = () => {
  const location = useLocation();

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const dispatch = useDispatch();
  const [sortingName, setSortingName] = useState({ id: 'sort.date_asc' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });

  const [paymentFilterVal, setPaymentFilterValue] = useUrlSearchParams({
    paymentFilter: '',
  });
  const [deviceFilterVal, setDeviceFilterValue] = useUrlSearchParams({
    deviceFilter: '',
  });

  const [customActiveTab, setcustomActiveTab] = useState('All');
  const [orderMode, setOrderMode] = useState('All');
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);

  const splitVal = location?.pathname.split('/');
  useEffect(() => {
    if (country === 'ae' && splitVal[1] === 'splitorders') {
      is_split = 1;
      setOrderMode('split');
    } else if (country === 'ae' && splitVal[1] === 'b2borders') {
      is_split = 2;
      setOrderMode('b2b');
    } else if (country === 'ae' && splitVal[1] === 'storeorders') {
      is_split = 3;
      setOrderMode('store');
    } else if (country !== 'ae' && splitVal[1] === 'storeorders') {
      is_split = 4;
      setOrderMode('store');
    } else {
      setOrderMode('All');
    }
  }, [splitVal]);

  useEffect(() => {
    setDataLoading(true);
    fetchProjectTrackList({
      params: {
        country: country,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        deviceFilter: deviceFilterVal.deviceFilter,
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
        status: customActiveTab,
        paymentFilter: paymentFilterVal.paymentFilter,
        is_split: is_split,
      },
      dispatch,
    });
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    country,
    orderMode,
  ]);

  const limitByValues = [5, 10, 20, 50];

  const { statusCounts = [], isStsCntsLoading } = useSelector(
    (state) => state.order
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { projectList = [], isProjectLoading } = useSelector(
    (state) => state.bccw
  );
  const { listOrders } = projectList;
  const orderData = listOrders?.orderList;

  let LsSts = 'New';
  const [allCnts, setallCnts] = useState(0);
  const [newCnts, setnewCnts] = useState(0);
  const [confirmedCnts, setconfirmedCnts] = useState(0);
  const [visitCnts, setvisitCnts] = useState(0);
  const [visitcompletedCnts, setvisitcompletedCnts] = useState(0);
  const [pendingdesignCnts, setpendingdesignCnts] = useState(0);
  const [receiveddesignCnts, setreceiveddesignCnts] = useState(0);
  const [designstartedCnts, setdesignstartedCnts] = useState(0);
  const [designCompleted, setdesignCompleted] = useState(0);
  const [designApproval, setdesignApproval] = useState(0);

  const stsCounts = statusCounts?.data;

  var statusArr = [];
  // const countsData = { ...statusArr };
  let getValue = (statusArr, StsKey) => {
    let returnSts = 0;
    statusArr.forEach((o) => {
      if (o[StsKey] !== undefined) {
        returnSts = o[StsKey];
      }
    });
    return returnSts;
  };

  useEffect(() => {
    statusArr = [];
    let allCounts = 0;

    if (stsCounts) {
      Object.keys(stsCounts).forEach(function (key) {
        let Sts = stsCounts[key]?.ls_status.replace(/\s+/g, '');
        let Counts = stsCounts[key].counts;
        allCounts += Number(stsCounts[key].counts);
        const countsArr = { [Sts]: Counts };
        statusArr.push(countsArr);
      });
      statusArr.push({ allCounts: allCounts });
    }
    setallCnts(getValue(statusArr, 'allCounts'));
    setnewCnts(getValue(statusArr, 'newCounts'));
    setconfirmedCnts(getValue(statusArr, 'Confirmed'));
    setvisitCnts(getValue(statusArr, 'siteVisit'));
    setvisitcompletedCnts(getValue(statusArr, 'siteVisitCompleted'));
    setpendingdesignCnts(getValue(statusArr, 'pendingDesign'));
    setreceiveddesignCnts(getValue(statusArr, 'receivedDesign'));
    setdesignstartedCnts(getValue(statusArr, 'designStarted'));
    setdesignCompleted(getValue(statusArr, 'designCompleted'));
    setdesignApproval(getValue(statusArr, 'designApproval'));
  }, [stsCounts]);

  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: { id: 'sort.date_asc' } },
    { key: 'date', order: SORTING_ORDER.DESC, label: { id: 'sort.date_desc' } },
    {
      key: 'status',
      order: SORTING_ORDER.ASC,
      label: { id: 'sort.status_asc' },
    },
    {
      key: 'status',
      order: SORTING_ORDER.DESC,
      label: { id: 'sort.status_desc' },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="OrderList row-fluid-scroll">
        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'All',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn  ${classnames({
                      active: customActiveTab === 'All',
                    })}`}
                    onClick={() => {
                      toggleCustom('All');
                    }}
                  >
                    All Projects
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {' '}
                          {/* <CountUp start={0} end={allCnts} duration={1} />{" "} */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'newCounts',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center ">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn  ${classnames({
                      active: customActiveTab === 'newCounts',
                    })}`}
                    onClick={() => {
                      toggleCustom('newCounts');
                    }}
                  >
                    New
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {' '}
                          {/* <CountUp start={0} end={allCnts} duration={1} />{" "} */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight  ${classnames({
              active: customActiveTab === 'Confirmed',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'Confirmed',
                    })}`}
                    onClick={() => {
                      toggleCustom('Confirmed');
                    }}
                  >
                    Confirmed
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {' '}
                          {/* <CountUp
                            start={0}
                            end={pendingCnts}
                            duration={1}
                          />{" "} */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'siteVisit',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'siteVisit',
                    })}`}
                    onClick={() => {
                      toggleCustom('siteVisit');
                    }}
                  >
                    Visit Scheduled
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={confirmedCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'siteVisitCompleted',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'siteVisitCompleted',
                    })}`}
                    onClick={() => {
                      toggleCustom('siteVisitCompleted');
                    }}
                  >
                    Site Visit Completed
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={postedCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight  ${classnames({
              active: customActiveTab === 'pendingDesign',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'pendingDesign',
                    })}`}
                    onClick={() => {
                      toggleCustom('pendingDesign');
                    }}
                  >
                    Pending Design Payment
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={shippedCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'receivedDesign',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'receivedDesign',
                    })}`}
                    onClick={() => {
                      toggleCustom('receivedDesign');
                    }}
                  >
                    Received Design Payment
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={deliveredCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'designStarted',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'designStarted',
                    })}`}
                    onClick={() => {
                      toggleCustom('designStarted');
                    }}
                  >
                    Design Started
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={cancelCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'designCompleted',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'designCompleted',
                    })}`}
                    onClick={() => {
                      toggleCustom('designCompleted');
                    }}
                  >
                    Design Completed
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={cancelCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={1} md={1} xs={1}>
          <Card
            className={`card-h-100 cntsHeight ${classnames({
              active: customActiveTab === 'designApproval',
            })}`}
          >
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12}>
                  <button
                    type="button"
                    className={`btn ${classnames({
                      active: customActiveTab === 'designApproval',
                    })}`}
                    onClick={() => {
                      toggleCustom('designApproval');
                    }}
                  >
                    Design Approval
                    <span className="counter-value">
                      {isStsCntsLoading ? (
                        <Spinner
                          className="ms-1 cntsloader"
                          size="sm"
                          color="secondary"
                          style={{ display: 'none' }}
                        />
                      ) : (
                        <h4>
                          {/* <CountUp start={0} end={cancelCnts} duration={1} /> */}
                        </h4>
                      )}
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="align-items-end">
        <Col xl={2} md={7} className="mb-2 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isProjectLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Scope</th>
                  <th>Country</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;

                //  console.log("order_id----------",order_id);
                let cdate = order.Date,
                  orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                  orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                let stsBtn = 'bg-soft-dark text-dark';
                if (order.Status === '1') {
                  stsBtn = 'bg-soft-dark text-dark';
                  LsSts = 'New';
                } else if (order.Status === '2') {
                  stsBtn = 'bg-success';
                  LsSts = 'Confirmed';
                } else if (order.Status === '3') {
                  stsBtn = 'bg-warning';
                  LsSts = 'Site Visit Scheduled';
                } else if (order.Status === '4') {
                  stsBtn = 'bg-dark';
                  LsSts = 'Pending Design Payment';
                } else if (order.Status === '5') {
                  stsBtn = 'bg-success';
                  LsSts = 'Received Design Payment';
                } else if (order.Status === '6') {
                  stsBtn = 'bg-primary';
                  LsSts = 'Design Started';
                } else if (order.Status === '7') {
                  stsBtn = 'bg-soft-dark';
                  LsSts = 'Design Completed';
                } else if (order.Status === '8') {
                  stsBtn = 'bg-success';
                  LsSts = 'Design Approval';
                }

                return (
                  <tbody key={order.TrackId}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td>
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>{order.FullName}</td>
                      <td>{order.MobileNumber}</td>
                      <td>{order.Scope}</td>
                      <td>{order.Country}</td>

                      <td>
                        <Badge className={` ${stsBtn}`}>{LsSts}</Badge>
                      </td>
                      <td>
                        <a
                          href={`TrackingDetails/${order.TrackId}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          <i className="mdi mdi-border-color"></i>{' '}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-dark">
                    {limitValue.limit} Items{' '}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Scope</th>
                  <th>Country</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerTrackingList;
