import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Col, Row, Progress, Button } from 'reactstrap';

function OrderProgressbar({ orderData }) {
  const progressStatus = orderData?.LsStatus?.toLowerCase();

  let progressArr = [];
  let isCancel = 0;
  let bar = 0;
  let stsBtn = 'bg-soft-dark text-dark';
  if (
    progressStatus === 'cancel' ||
    progressStatus === 'canceled' ||
    progressStatus === 'rejected'
  ) {
    progressArr = [0, 0, 0, 0, 0];
    isCancel = 1;
    bar = 100;
    stsBtn = 'border border-danger text-danger';
  } else if (progressStatus === 'open') {
    progressArr = [1, 0, 0, 0, 0];
    isCancel = 0;
    bar = 0;
  } else if (progressStatus === 'confirmed' || progressStatus === 'packed') {
    progressArr = [1, 1, 0, 0, 0];
    isCancel = 0;
    bar = 25;
  } else if (progressStatus === 'posted') {
    progressArr = [1, 1, 1, 0, 0];
    isCancel = 0;
    bar = 50;
  } else if (
    progressStatus === 'picked' ||
    progressStatus === 'delivery_scheduled' ||
    progressStatus === 'rescheduled' ||
    progressStatus === 'out_for_delivery' ||
    progressStatus === 'assigned' ||
    progressStatus === 'unassigned'
  ) {
    progressArr = [1, 1, 1, 1, 0];
    isCancel = 0;
    bar = 75;
  } else if (
    progressStatus === 'delivered' ||
    progressStatus === 'partially_delivered'
  ) {
    progressArr = [1, 1, 1, 1, 1];
    isCancel = 0;
    bar = 100;
  }
  return (
    <>
      <div className="ProgressStepBlock my-1">
        {isCancel ? (
          <>
            <Row className="ProgressbarStatus align-items-center justify-content-center">
              <Col xl={4}>
                <div className="position-relative">
                  <Progress
                    value={bar}
                    color="cancel"
                    style={{ height: '4px' }}
                  ></Progress>
                  <Button
                    color="danger"
                    className="position-absolute top-0 start-0 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3 shadow-none"
                  >
                    <i className="bx bx-check fs-22"></i>
                    Open
                  </Button>
                  <Button
                    color="danger"
                    className="position-absolute top-0 start-100 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3  shadow-none"
                  >
                    <i className="bx bx-check fs-22"></i>
                    Canceled
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="ProgressbarStatus align-items-center justify-content-center">
              <Col xl={8}>
                <div className="mt-5">
                  <div className="position-relative m-4">
                    <Progress
                      value={bar}
                      color="success"
                      style={{ height: '4px' }}
                    ></Progress>
                    <Button
                      color={`${progressArr[0] === 1 ? 'success' : 'light'}`}
                      className="position-absolute top-0 start-0 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3 shadow-none"
                    >
                      {progressArr[0] === 1 ? (
                        <i className="bx bx-check fs-22"></i>
                      ) : (
                        ''
                      )}{' '}
                      Pending
                    </Button>
                    <Button
                      color={`${progressArr[1] === 1 ? 'success' : 'light'}`}
                      className="position-absolute top-0 start-25 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3 shadow-none"
                    >
                      {progressArr[1] === 1 ? (
                        <i className="bx bx-check fs-22"></i>
                      ) : (
                        ''
                      )}{' '}
                      Confirmed
                    </Button>
                    <Button
                      color={`${progressArr[2] === 1 ? 'success' : 'light'}`}
                      className="position-absolute top-0 start-50 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3 shadow-none"
                    >
                      {progressArr[2] === 1 ? (
                        <i className="bx bx-check fs-22"></i>
                      ) : (
                        ''
                      )}{' '}
                      Posted
                    </Button>
                    <Button
                      color={`${progressArr[3] === 1 ? 'success' : 'light'}`}
                      className="position-absolute top-0 start-75 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3 shadow-none"
                    >
                      {progressArr[3] === 1 ? (
                        <i className="bx bx-check fs-22"></i>
                      ) : (
                        ''
                      )}{' '}
                      Delivery Sch
                    </Button>
                    <Button
                      color={`${progressArr[4] === 1 ? 'success' : 'light'}`}
                      className="position-absolute top-0 start-100 translate-middle rounded-pill d-flex justify-content-center align-items-center gap-1 px-3 shadow-none"
                    >
                      {progressArr[4] === 1 ? (
                        <i className="bx bx-check fs-22"></i>
                      ) : (
                        ''
                      )}{' '}
                      Delivered
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}
export default OrderProgressbar;
