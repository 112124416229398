import React, { useEffect, useState, useCallback } from 'react';
import './StoreSelection.scss';
import { useDispatch, useSelector } from 'react-redux';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { useUrlSearchParams } from 'use-url-search-params';
import { fetchQueueList, fetchHistoryList,getQueueOrderRemarks } from '../../action/action';
import moment from 'moment';

import {
  Card,
  Table,
  Row,
  Col,
  CardBody,
  CardHeader,
  Progress,
  Button,
  Spinner,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import StatusBar from '../StatusBar'; // Import StatusBar component
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import FilterSearchQueue from '../../../../components/FilterSearch/FilterSearchQueue';
import DHPagination from '../../../../components/Pagination';

const ListComponent = () => {
  const [open, setOpen] = useState(false);
  const [orderValue, setorderValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useUrlSearchParams({ search: '' });
  const [statusFilter, setStatusFilter] = useUrlSearchParams({ status: 'All' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const dispatch = useDispatch();
  const [detail, setDetail] = useState([]);
  const [advancedFilter, setAdvancedFilter] = useState({});

  const getQueueList = useCallback(async (data) => {
    try {
      setLoading(true);
      await fetchQueueList({
        params: data,
        dispatch,
      });
    } catch (error) {
      setLoading(false);
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getQueueList({
      start: Number(paginationValue.page * limitValue.limit),
      limit: Number(limitValue.limit),
      search_qry: searchFilter.search,
      status: statusFilter?.status,
      country: 'ae',
      type: 'cancel',
      advancedFilter,
      orderBy: 'id',
    });
  }, [
    advancedFilter,
    searchFilter,
    statusFilter,
    paginationValue.page,
    limitValue.limit,
  ]);
  const { queue1 = [], isLoading } = useSelector((state) => state.queueMaster);
  const webUrl = 'https://www.danubehome.com/';

  const { listOrders } = queue1;
  const orderData = listOrders?.orderList;
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  let pageIndex = Number(listOrders?.start);
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const [btnprimary1, setBtnprimary1] = useState(false);

  //console.log(queueDetails,"abcabcabc")
  const toggleRightDrawer = async (historyData) => {
    let result = await fetchHistoryList({
      params: {
        queue_id: historyData.queue_id,
      },
    });
    setOpen(true);
    setorderValue(historyData);
    setDetail(result);
    //  console.log("detail",queueDetails);
  };

  const onDrawerClose = () => {
    setOpen(!open);
  };
  const [reprocessStatus, setReprocessStatus] = useState({}); // Use an object to track status by reference_number

  const handleButtonClick = (reference_number) => {
    console.log(reference_number);
    setReprocessStatus((prevStatus) => ({
      ...prevStatus,
      [reference_number]: 'Approving',
    }));
  };
  const reprocessCancel = async (historyData) => {
    handleButtonClick(historyData.reference_no);
    getQueueOrderRemarks({
      params: {
        status: '30',
        erpOrderNum: historyData.erpOrderNum,
        isFullCancel: 'false',
        referenceNumber: historyData.reference_no,
        paymentMode: historyData.resolution_type,
        sku: historyData.sku,
        reprocess: 1,
        is_wms:historyData.is_wms,
        is_fareye: historyData.is_fareyes,
      },
      dispatch,
    });
    setReprocessStatus((prevStatus) => ({
      ...prevStatus,
      [historyData.reference_no]: 'Processing',
    }));

    //  console.log("detail",queueDetails);
  };
  const { orderupdateDetails = [] } = useSelector((state) => state.queueMaster);

  function getProgressColor(percentage) {
    if (percentage <= 10) {
      return 'danger'; // Red for 0-10%
    } else if (percentage <= 30) {
      return 'warning'; // Yellow for 11-30%
    } else if (percentage <= 50) {
      return 'info'; // Blue for 31-50%
    } else if (percentage <= 75) {
      return 'primary'; // Light blue for 51-75%
    } else if (percentage <= 90) {
      return 'success'; // Green for 76-90%
    } else {
      return 'success'; // Green for 91-100%
    }
  }
  useEffect(() => {
    if (orderupdateDetails?.code === 100) {
      setUpdateDetailsLoad(false);
      //setLoading(true);

      console.log('toster', orderupdateDetails?.message);

      toast.success(orderupdateDetails?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (orderupdateDetails?.message) {
        setUpdateDetailsLoad(false);
        toast.error(orderupdateDetails?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [orderupdateDetails]);

  return (
    <>
      <FilterSearchQueue
        setAdvancedFilter={setAdvancedFilter}
        setSearchFilter={setSearchFilter}
        setStatusFilter={setStatusFilter}
      />
      <div className=" dt-table">
        <div className="table-responsive dt-table">
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th nowrap="nowrap">Reference NO</th>
                <th nowrap="nowrap">SKU</th>
                <th>Reason</th>
                <th>Resolution Mode</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            {isLoading || loading ? (
              <>
                <Spinner className="ms-2 sploader" color="primary" />
                <tbody>
                  {[...Array(2)].map((_, index) => (
                    <tr>
                      <td>
                        <p className="placeholder-glow">
                          <span className="placeholder col-2"></span>
                        </p>
                      </td>
                      <td>
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                          <span className="placeholder col-6"></span>
                          <span
                            className="placeholder"
                            style={{ width: '25%' }}
                          ></span>
                          <span className="placeholder w-75"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow d-flex flex-column gap-1">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                          <span
                            className="placeholder"
                            style={{ width: '25%' }}
                          ></span>
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : orderData?.length > 0 ? (
              <>
                {(orderData || []).map((order, index) => {
                  const progressColor = getProgressColor(
                    order.status_percentage
                  );
                  pageIndex++;

                  return (
                    <tbody key={order?.id}>
                      <tr
                        className={
                          order?.error_message !== null ? 'my-class' : ''
                        }
                      >
                        <td scope="row" valign="middle">
                          {pageIndex}
                        </td>

                        <td nowrap="nowrap" valign="middle">
                          <p className="m-0 fw-bold">
                            <Link
                              className="text-info"
                              to={`/orders/${order?.entity_id}/?order_mode=${order?.mode}&orderCn=ae&showPopup=false`}
                              target="_blank"
                            >
                              {order?.reference_number}
                            </Link>
                          </p>
                          <p className="m-0 fs-xs">
                            Requested on :
                            {moment(order?.created_at).format(
                              'Do MMM YYYY h:mmA'
                            )}
                          </p>

                          <span className="highlightInfo2 ">
                            Payment Mode :
                            {order?.resolution_type === 'cashondelivery' ? (
                              <>
                                <b>Cash on delivery</b>
                              </>
                            ) : (
                              <>
                                <b>Credit Card</b>
                              </>
                            )}
                          </span>

                          <span className="highlightInfo2 ">
                            Created By: {order.created_by}
                          </span>
                        </td>
                        <td nowrap="nowrap" valign="middle">
                          <p className="d-flex  align-items-center gap-1">
                            {order.cancel_sku}
                            <a
                              href={`${webUrl}ae/en/p/from-oms-preview-${order.cancel_sku}`}
                              target="_blank"
                            >
                              <i className="bx bx-link-external fs-6"></i>
                            </a>
                          </p>
                        </td>

                        <td nowrap="nowrap" valign="middle">
                          <span style={{ paddingRight: '10px' }}>
                            {order.reason}
                          </span>
                        </td>

                        <td nowrap="nowrap" valign="middle">
                          {order?.resolution_type === 'cashondelivery' ? (
                            <>cashondelivery</>
                          ) : order?.resolution_type === 'refund' ? (
                            <>Refund to orginal mode</>
                          ) : (
                            <>{order.resolution_type}</>
                          )}
                        </td>
                        <td nowrap="nowrap" valign="middle">
                          {order?.status === '0' ? (
                            <>
                              <b>Pending</b>
                            </>
                          ) : order?.status === '1' ? (
                            <>Completed</>
                          ) : order?.status === '2' ? (
                            <>LS Canceled</>
                          ) : order?.status === '3' ? (
                            <>Waiting for LS cancel</>
                          ) : order?.status === '5' ? (
                            <>Refund Initiated</>
                          ) : order?.status === '7' ? (
                            <>Push to seller</>
                          ) : order?.status === '8' ? (
                            <>Seller Cancel</>
                          ) : order?.status === '9' ? (
                            <>Wallet Initated</>
                          ) : order?.status === '10' ? (
                            <>Wallet Approved</>
                          ) : order?.status === '21' ? (
                            <>LS Api triggered</>
                          ) : order?.status === '17' ? (
                            <>Seller canceled</>
                          ) : order?.status === '18' ? (
                            <>BB Order creation</>
                          ) : order?.status === '30' ? (
                            <>Seller Rejected</>
                          ) : (
                            <>Rejected</>
                          )}
                          <p>
                            <span className="fs-9">
                              {order.status_percentage}%
                            </span>
                            <Progress
                              color={progressColor}
                              className="progress-sm"
                              value={order.status_percentage}
                            ></Progress>
                          </p>
                        </td>
                        <td valign="middle">
                          <div className="d-flex justify-content-end">
                          {order?.status === '31' ? (
                        <>
                           <button
                        onClick={() => {
                          reprocessCancel({
                            reference_no: order.reference_number,
                            erpOrderNum: order?.ls_reference,
                            sku: order?.cancel_sku,
                            queue_id: order?.queue_id,
                            resolution_type: order?.resolution_type,
                            is_wms:order?.is_wms,
                            is_fareye:order?.is_fareye,
                          });
                        }}
                        type="button"
                        className="btn btn-sm btn-outline-warning waves-effect waves-light"
                      >
                        {' '}
                        {reprocessStatus[order.reference_number] || 'Reprocess'}
                      </button>
                        </>
                      ) :(
                        <Button
                              outline
                              color="warning"
                              onClick={() => {
                                toggleRightDrawer({
                                  reference_no: order.reference_number,
                                  date: order?.created_at,
                                  created_by: order?.created_by,
                                  queue_id: order?.queue_id,
                                  resolution_type: order?.resolution_type,
                                });
                              }}
                              type="button"
                              className="d-flex gap-2 fs-12 py-1"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                              Track history
                            </Button>)}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        {orderData?.length > 0 ? (
          <DHPagination
            totalPages={listOrders?.recordsTotal}
            currentPage={paginationValue.page}
            setPaginationValue={setPaginationValue}
            setLimitValue={setLimitValue}
          />
        ) : null}
      </div>

      <ReactDrawer
        open={open}
        position="right"
        onClose={onDrawerClose}
        className="sideNavWidth"
      >
        <Card
          className="card-h-100 OrderDetailsBox"
          style={{ overflowY: 'scroll' }}
        >
          <CardHeader>
            <Row>
              <Col className="textAlignRight">
                {' '}
                <button
                  type="button"
                  className="btn-close "
                  onClick={() => {
                    onDrawerClose();
                  }}
                  aria-label="Close"
                ></button>
              </Col>
              <div>
                Queue Details of <b>{orderValue?.reference_no}</b>
              </div>

              <div className="">
                <div>
                  <b>Created By:</b>
                  {orderValue?.created_by}
                </div>
                <div>
                  {' '}
                  <b>Date: </b> {orderValue?.date}
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <p>
              <b>Status History</b>
            </p>
            <StatusBar detail={detail} orderValue={orderValue} />{' '}
            {/* Add StatusBar component */}
          </CardBody>
        </Card>
      </ReactDrawer>

      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};
export default ListComponent;
