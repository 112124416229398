let deliveryStatusList = {
  material_received: {
    lsSts: 'Material Received',
    stsBtn: 'bg-soft-warning',
  },
  open: {
    lsSts: 'Pending',
    stsBtn: 'bg-soft-dark',
  },
  open: {
    lsSts: 'Pending',
    stsBtn: 'bg-soft-dark',
  },
  confirmed: {
    lsSts: 'Confirmed',
    stsBtn: 'bg-soft-dark',
  },
  posted: {
    lsSts: 'Posted',
    stsBtn: 'bg-soft-primary',
  },
  delivered: {
    lsSts: 'Delivered',
    stsBtn: 'bg-soft-success',
  },
  picked: {
    lsSts: 'Picked',
    stsBtn: 'bg-soft-warning',
  },
  delivery_scheduled: {
    lsSts: 'Delivery Scheduled',
    stsBtn: 'bg-soft-success',
  },
  rescheduled: {
    lsSts: 'Rescheduled',
    stsBtn: 'bg-soft-success',
  },
  partially_delivered: {
    lsSts: 'bg-warning',
    stsBtn: 'Partially Delivered',
  },
  out_for_delivery: {
    lsSts: 'Out For Delivery',
    stsBtn: 'bg-soft-success',
  },
  packed: {
    lsSts: 'Packed',
    stsBtn: 'bg-soft-dark',
  },
  new: {
    lsSts: 'New',
    stsBtn: 'bg-soft-dark',
  },
  'materialn/a': {
    lsSts: 'Material N/A',
    stsBtn: 'bg-soft-warning',
  },
  assigned: {
    lsSts: 'Ready for Delivery',
    stsBtn: 'bg-soft-warning',
  },
};
export default deliveryStatusList;
