import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Button,
  Spinner,
  CardHeader,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { fetchSettingData, SettingUpdateData } from './action/action';
import toast, { Toaster } from 'react-hot-toast';

const MailSettings = () => {
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);

  const title = 'Orders';
  const description = 'Manage Orders';
  // const countryAll = "All";
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    fetchSettingData({
      params: {
        country: 'All',
        type: 2,
      },
      dispatch,
    });
  }, []);

  const { settingList = [], isLoading } = useSelector((state) => state.setting);

  const settingData = settingList?.data;

  const { updateList = [], isupdateLoading } = useSelector(
    (state) => state.setting
  );

  useEffect(() => {
    if (updateList?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(updateList?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (updateList?.message) {
        toast.error(updateList?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [updateList]);

  const handleValidSubmit = (events, values) => {
    setUpdateDetailsLoad(true);

    const mailtype = values?.mailtype ? values?.mailtype : '';
    const host = values?.host ? values?.host : '';
    const username = values?.username ? values?.username : '';
    const password = values?.password ? values?.password : '';
    const port = values?.port ? values?.port : '';

    SettingUpdateData({
      params: {
        mail_type: 'smtp_mail',
        smtp_host: host,
        smtp_user_name: username,
        smtp_password: password,
        smtp_port: port,
        type: 2,
      },
      dispatch,
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mail settings </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Settings" breadcrumbItem="Mail Settings" />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-soft-light px-3 py-3 d-flex justify-content-between">
                  <CardTitle>Email Configuration</CardTitle>
                </CardHeader>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      //console.log(e, v);
                      handleValidSubmit(e, v);
                    }}
                  >
                    <div className="mb-3">
                      <AvCheckboxGroup inline name="mailtype">
                        <strong className="me-3">Mail Type* </strong>
                        <AvCheckbox label="General" value="general" />
                        <AvCheckbox label="SMTP" value="smtp" checked />
                      </AvCheckboxGroup>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="host"
                        label="Host "
                        placeholder="Type Something"
                        type="text"
                        value={settingData?.[1]?.value}
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="username"
                        label="User Name  "
                        placeholder="Type Something"
                        type="text"
                        value={settingData?.[2]?.value}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="password"
                        label="Password"
                        type="password"
                        value={settingData?.[3]?.value}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="port"
                        label="Port"
                        type="text"
                        value={settingData?.[4]?.value}
                        required
                      />
                    </div>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="secondary">
                        {updateStsLoad === true && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{' '}
                        Save
                      </Button>
                      <Button outline color="danger" type="reset">
                        Cancel
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default MailSettings;
