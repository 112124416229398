import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Card, Button, OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import CsLineIcons from '../../../cs-line-icons/CsLineIcons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';

import { SORTING_ORDER } from '../../../constants';
import { SettingdmsExpressDate } from '../action/action';

import moment from 'moment';

import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
} from 'reactstrap';

const DeliveryList = () => {
  const location = useLocation();

  const [btnprimary1, setBtnprimary1] = useState(false);

  const dispatch = useDispatch();

  const [sortingName, setSortingName] = useState({ id: 'sort.date_asc' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [customActiveTab, setcustomActiveTab] = useState('All');
  const [orderMode, setOrderMode] = useState('All');
  let is_split = 0;
  const { country = [] } = useSelector((state) => state.country);

  const splitVal = location?.pathname.split('/');

  useEffect(() => {
    setDataLoading(true);
    SettingdmsExpressDate({
      params: {
        country: country,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
        search_qry: searchValue.search,
      },
      dispatch,
    });
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    customActiveTab,
    country,
    orderMode,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { ExpressList = [], isExpressLoading } = useSelector(
    (state) => state.setting
  );

  const [isDataLoading, setDataLoading] = useState(false);

  const { listOrders } = ExpressList;
  const orderData = listOrders?.orderList;
  console.log(orderData, 'orderData');

  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: { id: 'sort.date_asc' } },
    { key: 'date', order: SORTING_ORDER.DESC, label: { id: 'sort.date_desc' } },
    {
      key: 'status',
      order: SORTING_ORDER.ASC,
      label: { id: 'sort.status_asc' },
    },
    {
      key: 'status',
      order: SORTING_ORDER.DESC,
      label: { id: 'sort.status_desc' },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const toggleCustom = (tab) => {
    // console.log("ActiveTab----", tab);
    if (customActiveTab !== tab) {
      // console.log("customActiveTab----", tab);
      setcustomActiveTab(tab);
    }
  };

  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="align-items-end">
        <Col xl={2} md={6} className="mb-3 textAlignRight">
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span className="search-magnifier-icon searchIcon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive dt-table">
        {isExpressLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Country</th>
                  <th>Amount</th>
                  <th>Express_Date</th>
                  <th>Edit</th>
                </tr>
              </thead>
              {(orderData || []).map((order, index) => {
                pageIndex++;
                let cdate = order.created_at,
                  orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                  orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                return (
                  <tbody key={order.id}>
                    <tr>
                      <th scope="row">{pageIndex}</th>
                      <td>
                        {orderDt} <br></br>
                        {orderTime}
                      </td>
                      <td>{order.country}</td>
                      <td>{order.amount}</td>
                      <td>{order.dms_express_date}</td>

                      <td>
                        <a
                          href={`DeliverySettings/${order.id}/${order.country}`}
                          type="button"
                          className="btn btn-sm btn-outline-warning waves-effect waves-light"
                        >
                          {' '}
                          <i className="mdi mdi-eye-outline"></i>{' '}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>

            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-dark">
                    {limitValue.limit} Items{' '}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Date</th>
                  <th>Country</th>
                  <th>Amount</th>
                  <th>Express_Date</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default DeliveryList;
