import { useState, useEffect, useSearchParams } from 'react';
import { MetaTags } from 'react-meta-tags';
import { DashBreadcrumb } from '../../components/Common/Breadcrumb';
import 'react-drawer/lib/react-drawer.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/themes/material_blue.css';
import { Col, Container, Row, Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import like from '../../assets/images/like.svg';
import unlike from '../../assets/images/unlik.svg';
import KBArticleList from './components/AddNewArticleList';
import { Form } from 'react-bootstrap';

const KnowledgeBaseArticles = () => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Articles</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              {' '}
              <DashBreadcrumb
                title="Knowledge Base"
                breadcrumbItem="Articles"
              />
            </Col>
            <Col md="auto" sm="auto" xs="auto" className="MobileView">
              <Link className="btn btn-dark" to="/AddNewArticle">
                Add Article
              </Link>
            </Col>
          </Row>
          <KBArticleList />
          {/* <div className=" dt-table mt-3">
        
            <div className="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Created at</th>
                    <th>Last Updated by</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
              <tr>
                <td>71878</td>
                <td>Sell with us</td>
                <td>7 minutes ago</td>
                <td>Rishal Mohamed</td>
                <td>2 articles</td>
                <td> <Badge className="bg-success me-1">
                Published   
                              </Badge></td>
                               <td>
                          
                             <div className="icons">
                                <div className="icon1">
                <img src={like} width={38} height={38} alt="" /> <label>8</label>
           
                </div>
              
     
       <div className="icon1">
       <img src={unlike} width={38} height={38} alt="" /> <label>2</label>
     </div>
     </div>
             
        
            </td> 
              </tr>
             </tbody>
              </Table>
            </div>
          
      </div> */}
        </Container>
      </div>
    </>
  );
};

export default KnowledgeBaseArticles;
