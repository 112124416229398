import './Comments.scss';
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
function CommentsComponent(args) {
  const { setComment, setEmailNotification, emailNotification, showComment } =
    args || {};
  return showComment ? (
    <>
      <div className="comments p-3">
        <div className="contain">
          <Row>
            <Col>
              <AvForm>
                <AvField
                  name="Comments"
                  label="Comments"
                  type="textarea"
                  onChange={(e) => setComment(e.target.value)}
                />
              </AvForm>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="commentsCheckBox pt-2 m-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="AppendComments"
                  value="false"
                />
                <label className="form-check-label" htmlFor="AppendComments">
                  Append Comments
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="commentsCheckBox m-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="EmailOrderConfirmation"
                  checked={emailNotification}
                  onChange={(e) => setEmailNotification(e.target.checked)}
                />
                <label
                  className="form-check-label"
                  htmlFor="EmailOrderConfirmation"
                >
                  Email Order Confirmation
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}

export default CommentsComponent;
