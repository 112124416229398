import { useState, useEffect, button } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Spinner,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker, Stack } from 'rsuite';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import {
  fetchAllOrders,
  getCanceledRemarks,
  updateOrderStatus,
} from '../actions/orderActions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast, { Toaster } from 'react-hot-toast';
import { WAREHOUSE_ALLOWED_USER_ROLES } from '../../../constants/index';

function OrderUpdateDetailsForm({
  orderData,
  reLoadOrderDetail,
  onDrawerClose,
  setOrderReload,
}) {
  const users = JSON.parse(localStorage?.getItem('authUser'));

  const predefinedBottomRanges = [
    {
      label: 'yesterday',
      value: addDays(new Date(), -1),
    },
    {
      label: 'today',
      value: new Date(),
    },
    {
      label: 'Prev Day',
      closeOverlay: false,
      value: (date) => {
        return subDays(date, 1);
      },
    },
  ];
  const predefinedRanges = [
    {
      label: 'yesterday',
      value: addDays(new Date(), -1),
      placement: 'left',
    },
    {
      label: 'today',
      value: new Date(),
      placement: 'left',
    },
    {
      label: 'Prev Day',
      closeOverlay: false,
      value: (date) => {
        return subDays(date, 1);
      },
    },
  ];

  const history = useHistory();
  const shipmentItemDetail = orderData?.ShipmentDetails?.[0]?.ShipmentItems;
  const shipmentItemAll = orderData?.ShipmentDetails;
  const LsReference = orderData?.LsReference;
  const DeliveryDate = orderData?.DeliveryDate;

  const [dateVal, setDateVal] = useState('');
  const [ordStatus, setOrdStatus] = useState('');
  const [isDateEmpty, setDateEmpty] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get('order_mode');
  const [resolution, setResolution] = useState('');
  const itemSku = localStorage.getItem('skuItem');

  useEffect(() => {
    if (
      DeliveryDate !== '' &&
      DeliveryDate !== undefined &&
      DeliveryDate !== null
    ) {
      const delDate = new Date(DeliveryDate);
      setDateVal(delDate);
    }
  }, [DeliveryDate]);
  var today = new Date();
  let orderStatusDate =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getCanceledRemarks({
      params: {},
      dispatch,
    });
  }, []);
  const { getRemarksList = [], isRemarksLoading } = useSelector(
    (state) => state.order
  );
  const RemarksList = getRemarksList?.data;
  const [placedNewOrder, setPlacedNewOrder] = useState();
  const [OrderStatus, setOrderStatus] = useState();

  useEffect(() => {
    setOrderStatus(orderData?.LsStatus?.toLowerCase());
  }, [orderData?.LsStatus]);

  let userID = '';
  let UserRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
      UserRoleID = userObj?.role_id;
    }
  }

  const [itemsArr, setitemsArr] = useState([]);
  useEffect(() => {
    if (itemSku) {
      const newItemsArr = [];
      shipmentItemAll?.forEach((shipments) => {
        shipments?.ShipmentItems?.forEach((items) => {
          if (itemSku === items?.Sku) {
            console.log('test1');
            console.log(itemSku);
            newItemsArr.push({
              sku: items?.Sku,
              quantity: items?.Qty,
              delivery_quantity: '',
              fixing_quantity: '',
              delivery_status_remark: '',
              installation_status: '',
              installation_status_remark: '',
            });
          }
        });
      });
      setitemsArr(newItemsArr);
    } else {
      shipmentItemAll?.forEach((shipments) => {
        shipments?.ShipmentItems?.forEach((items) => {
          setitemsArr((current) => [
            ...current,
            {
              sku: items?.Sku,
              quantity: items?.Qty,
              delivery_quantity: '',
              fixing_quantity: '',
              delivery_status_remark: '',
              installation_status: '',
              installation_status_remark: '',
            },
          ]);
        });
      });
    }
  }, [shipmentItemAll, itemSku]);

  console.log(itemsArr);

  const handleValidSubmit = (events, values, delveryDate) => {
    setUpdateDetailsLoad(true);
    const cancelled_reason = values?.cancelled_reason
      ? values?.cancelled_reason
      : '';
    const warehouse_cancelled_reason = values?.warehouse_cancelled_reason
      ? values?.warehouse_cancelled_reason
      : '';
    const resolution_mode = resolution ? resolution : '';

    /*  const remarks =
      orderData?.FinancialStatus === "0"
        ? values?.remarks
        : values?.remarksforother; */
    const remarks = values?.remarks;
    const so_number = values?.so_number ? values?.so_number : '';
    const delivery_dt = delveryDate !== '' ? delveryDate : '';
    const status = values?.status ? values?.status : OrderStatus;
    const orderCn = orderData?.ShippingDetails?.[0]?.Country;
    const orderSts = status?.toLowerCase();
    if (
      orderSts === 'delivered' ||
      orderSts === 'delivery scheduled' ||
      orderSts === 'delivery_scheduled' ||
      orderSts === 'rescheduled'
    ) {
      if (dateVal !== '' && dateVal !== null && dateVal !== undefined) {
        orderStatusDate = moment(dateVal).format('YYYY-MM-DD');
      } else {
        setUpdateDetailsLoad(false);
        setDateEmpty(true);
      }
    }
    if (isDateEmpty == false) {
      let stsData = status?.toLowerCase();
      if (status?.toLowerCase() === 'delivery scheduled') {
        stsData = 'delivery_scheduled';
      }
      setOrdStatus(stsData);
      updateOrderStatus({
        params: {
          order_reference: orderData?.OrderNo,
          country: orderCn,
          status: stsData,
          reason: cancelled_reason,
          warehouse_reason: warehouse_cancelled_reason,
          remarks: remarks,
          time_slot: '',
          shipment_type: '',
          deliver_by: '',
          ls_order_reference: so_number,
          delivery_dt: delivery_dt,
          order_mode: 'All',
          user_id: userID,
          type: 'omsUpdate',
          date: orderStatusDate,
          updateMode: order_mode,
          items: itemsArr,
          resolution_mode: resolution_mode,
        },
        dispatch,
      });
    }
  };
  const { orderupdateDetails = [], isUpdateLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (orderupdateDetails?.code === 100) {
      setOrderReload(true);
      setUpdateDetailsLoad(false);
      onDrawerClose();
      if (ordStatus === 'canceled') {
        setOrdStatus('');
        // window.location.reload();

        //history.push('/orders');
      } else {
        setOrdStatus('');
        // reLoadOrderDetail();
      }
      toast.success(orderupdateDetails?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (orderupdateDetails?.message) {
        setUpdateDetailsLoad(false);
        toast.error(orderupdateDetails?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        window.location.reload();
      }
    }
  }, [orderupdateDetails]);
  const updateDetail = (countryData) => {
    const country = countryData?.ShippingDetails?.[0]?.Country?.toLowerCase();
    const paymentStatus = countryData?.FinancialStatus;
    let LsStatus = countryData?.LsStatus?.toLowerCase();
    let IsFlowLs = countryData?.IsFlowLs;
    /*if (country == 'ae') {
      LsStatus =
        countryData?.ShipmentDetails?.[0]?.ShipmentItems?.[0]?.LsStatus?.toLowerCase();
    }*/
    let LsStsArr = [];
    if (paymentStatus === '0') {
      LsStsArr = ['payment_failed', 'canceled'];
    } else if (
      (LsStatus === '0' || LsStatus === '' || LsStatus === null) &&
      paymentStatus === '1'
    ) {
      LsStsArr = ['Pending', 'Canceled'];
    } else if (LsStatus === 'pending') {
      LsStsArr = ['Pending', 'Canceled'];
    } else if (LsStatus === 'open') {
      LsStsArr = ['Open', 'Confirmed', 'Canceled'];
    } else if (LsStatus === 'material n/a' && country === 'bh') {
      LsStsArr = ['Material n/a', 'Posted', 'Canceled'];
    } else if (LsStatus === 'material n/a' && country !== 'bh') {
      LsStsArr = ['Material n/a', 'Canceled'];
    } else if (LsStatus === 'new' && paymentStatus === '1') {
      LsStsArr = ['New', 'Canceled'];
    } else if (LsStatus === 'open' && paymentStatus === '1') {
      LsStsArr = ['Open'];
    } else if (LsStatus === 'retention') {
      LsStsArr = ['Retention', 'Canceled'];
    } else if (LsStatus === 'confirmed') {
      LsStsArr = ['Confirmed', 'Posted', 'Canceled'];
    } else if (LsStatus == 'posted') {
      LsStsArr = ['Posted', 'Delivery Scheduled', 'Canceled'];
    } else if (
      (LsStatus == 'delivery_scheduled' ||
        LsStatus == 'delivery scheduled' ||
        LsStatus == 'assigned') &&
      country === 'om'
    ) {
      LsStsArr = ['Delivery Scheduled', 'Delivered', 'Canceled', 'Rescheduled'];
    } else if (
      (LsStatus == 'delivery_scheduled' ||
        LsStatus == 'delivery scheduled' ||
        LsStatus == 'assigned' || LsStatus == 'partially_delivered'|| LsStatus == 'out_for_delivery') &&
      country !== 'om'
    ) {
      LsStsArr = ['Delivery Scheduled', 'Rescheduled', 'Delivered', 'Canceled'];
    } else if (LsStatus == 'rescheduled') {
      LsStsArr = ['Rescheduled', 'Delivered', 'Canceled'];
    } else if (LsStatus == 'material_received') {
      LsStsArr = ['Material Received', 'Canceled'];
    } else if (LsStatus == 'picked' || LsStatus == 'packed') {
      LsStsArr = ['Picked', 'Delivered', 'Canceled'];
    } else if (LsStatus == 'accepted') {
      LsStsArr = ['Accepted', 'Canceled'];
    } else if (LsStatus == 'undelivered') {
      LsStsArr = ['Undelivered', 'Canceled'];
    } else if (LsStatus == 'delivered') {
      LsStsArr = ['Delivered', 'installation_completed'];
    } else if (
      LsStatus === 'cancel' ||
      LsStatus === 'canceled' ||
      LsStatus === 'canceled'
    ) {
      LsStsArr = ['Canceled'];
    }

    return (
      <Card className="Order UpdateDetailsForm">
        <CardHeader>
          <CardTitle>UPDATE DETAILS</CardTitle>
        </CardHeader>
        <CardBody>
          <AvForm
            className="needs-validation row gx-3 gy-2 align-items-center"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v, dateVal);
            }}
          >
            <Col xl={12} md={12}>
              <Row>
                <Col xl={12} md={12}>
                  <AvField
                    type="select"
                    name="status"
                    label="Status"
                    className="form-select"
                    onChange={(e) => setOrderStatus(e.target.value)}
                  >
                    {LsStsArr?.map((e, inx) => (
                      <option key={inx}>{e}</option>
                    ))}
                  </AvField>
                </Col>
              </Row>
            </Col>
            {OrderStatus?.toLowerCase() === 'canceled' &&
            WAREHOUSE_ALLOWED_USER_ROLES.includes(users?.role_id) ? (
              <Col xl={12} md={12}>
                <AvField
                  type="select"
                  name="warehouse_cancelled_reason"
                  label="Initail Cancel Reason*"
                  className="form-select"
                  onChange={(e) => setPlacedNewOrder(e.target.value)}
                  required
                >
                  <option>Select a Reason</option>
                  <option>Customer Issue</option>
                  <option>Delivery Issue</option>
                  <option>Product Issue</option>
                  <option>Service Issue</option>
                </AvField>
              </Col>
            ) : (
              ''
            )}

            {OrderStatus?.toLowerCase() === 'canceled' ? (
              <Col xl={12} md={12}>
                <AvField
                  type="select"
                  name="cancelled_reason"
                  label="Canceled Reason*"
                  className="form-select"
                  onChange={(e) => setPlacedNewOrder(e.target.value)}
                  required
                >
                  <option>Select a Reason</option>
                  {RemarksList?.map((ele, im) => (
                    <option key={im}>{ele.reason}</option>
                  ))}
                </AvField>
              </Col>
            ) : (
              ''
            )}
            {OrderStatus?.toLowerCase() === 'canceled' ? (
              <>
                {(orderData?.PaymentMode === 'payfort' ||
                  orderData?.PaymentMode === 'tamara' ||
                  orderData?.PaymentMode === 'tabby' ||
                  orderData?.PaymentMode === 'apple_pay') &&
                orderData?.FinancialStatus === '1' &&
                orderData?.walletRedeemInfo === null ? (
                  <Col>
                    {' '}
                    <AvField
                      type="select"
                      name="resolution_mode"
                      label="resolution_mode*"
                      className="form-select"
                      onChange={(e) => setResolution(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option>refund</option>
                      <option>wallet</option>
                    </AvField>
                  </Col>
                ) : (
                  ''
                )}

                {placedNewOrder === 'placed new order' ? (
                  <>
                    {' '}
                    <Col xl={12} md={12}>
                      <AvField
                        name="referenceno"
                        label="Reference No"
                        value=""
                        className="form-control"
                        placeholder=""
                        type="text"
                        required
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {OrderStatus?.toLowerCase() === 'posted' ? (
              <>
                <Col xl={12} md={12}>
                  <AvField
                    name="so_number"
                    label="SO Number"
                    value={LsReference}
                    className="form-control"
                    placeholder="Please Enter So Number"
                    type="text"
                    required
                  />
                </Col>
              </>
            ) : (
              <></>
            )}

            {OrderStatus?.toLowerCase() === 'delivery scheduled' ||
            OrderStatus?.toLowerCase() === 'delivery_scheduled' ? (
              <>
                {' '}
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Deliverey Schedule Date
                  </Label>
                  <br />
                  <DatePicker
                    ranges={predefinedBottomRanges}
                    placeholder="YYYY-MM-DD"
                    style={{ width: '100%' }}
                    value={dateVal !== null && dateVal !== '' ? dateVal : null}
                    onChange={setDateVal}
                  />
                  {isDateEmpty && (
                    <div
                      style={{
                        color: '#f00',
                      }}
                    >
                      Please Select Date.
                    </div>
                  )}
                </Col>
              </>
            ) : (
              <></>
            )}

            {OrderStatus?.toLowerCase() === 'rescheduled' ? (
              <>
                {' '}
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Deliverey Reschedule Date
                  </Label>
                  <br />
                  <DatePicker
                    ranges={predefinedBottomRanges}
                    placeholder={DeliveryDate}
                    style={{ width: '100%' }}
                    value={dateVal !== null && dateVal !== '' ? dateVal : null}
                    onChange={setDateVal}
                  />
                </Col>
              </>
            ) : (
              <></>
            )}

            {OrderStatus?.toLowerCase() === 'delivered' ? (
              <>
                {' '}
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Delivered Date
                  </Label>
                  <br />
                  <DatePicker
                    ranges={predefinedBottomRanges}
                    placeholder={DeliveryDate}
                    style={{ width: '100%' }}
                    value={dateVal !== null && dateVal !== '' ? dateVal : null}
                    onChange={setDateVal}
                  />
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col xl={12} md={12}>
              <AvField
                name="remarks"
                label="Remarks*"
                value=""
                className="form-control"
                type="textarea"
                required
              />
            </Col>
            <Col xl={12} md={12}>
              <button type="submit" className="btn btn-dark">
                {updateStsLoad === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{' '}
                Submit
              </button>
            </Col>
          </AvForm>
        </CardBody>
      </Card>
    );
  };
  return (
    <>
      {updateDetail(orderData)}
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}
export default OrderUpdateDetailsForm;
