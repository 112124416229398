import React, { useEffect, useState } from 'react';
import './StoreSelection.scss';
import { useDispatch, useSelector } from 'react-redux';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { useUrlSearchParams } from 'use-url-search-params';
import {
  fetchQueueList,
  fetchHistoryList,
  getQueueOrderRemarks,
} from '../../action/action';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { Form } from 'react-bootstrap';

import {
  Card,
  Table,
  Row,
  Col,
  CardBody,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Progress,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import StatusBar from '../StatusBar'; // Import StatusBar component

const ListRejectedComponent = () => {
  const [open, setOpen] = useState(false);
  const [orderValue, setorderValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    fetchQueueList({
      params: {
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        search_qry: searchValue.search,
        country: 'ae',
        date_from: startDate,
        type: 'cancel',
        status: '31',
        date_to: endDate === undefined || endDate === '' ? startDate : endDate,

        orderBy: 'id',
      },
      dispatch,
    });
  }, [startDate, paginationValue.page, limitValue, loading]);
  const limitByValues = [5, 10, 20, 50];
  const { queue1 = [] } = useSelector((state) => state.queueMaster);

  const { listOrders } = queue1;
  const orderData = listOrders?.orderList;
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [buttonTexts, setButtonTexts] = useState(Array(6).fill('Reprocess'));
  const [reprocessStatus, setReprocessStatus] = useState({}); // Use an object to track status by reference_number

  const handleButtonClick = (reference_number) => {
    console.log(reference_number);
    setReprocessStatus((prevStatus) => ({
      ...prevStatus,
      [reference_number]: 'Approving',
    }));
  };
  //console.log("DeliveryReportList",queue1);
  let pageIndex = Number(listOrders?.start);
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const [btnprimary1, setBtnprimary1] = useState(false);
  //const abc  = useSelector((state) => state);
  const { queueDetails } = useSelector((state) => ({
    queueDetails: state?.queueMaster?.queueDetails,
  }));

  const getHistory = async (id) => {
    setDetail([]);
    let result = await fetchHistoryList({
      params: {
        queue_id: id,
      },
    });
    if (result) {
      setDetail(result);
    }
  };
  //console.log(queueDetails,"abcabcabc")
  const toggleRightDrawer = async (historyData) => {
    let result = await fetchHistoryList({
      params: {
        queue_id: historyData.queue_id,
      },
    });
    setOpen(true);
    setorderValue(historyData);
    setDetail(result);
    //  console.log("detail",queueDetails);
  };
  const reprocessCancel = async (historyData) => {
    handleButtonClick(historyData.reference_no);
    getQueueOrderRemarks({
      params: {
        status: '30',
        erpOrderNum: historyData.erpOrderNum,
        isFullCancel: 'false',
        referenceNumber: historyData.reference_no,
        paymentMode: historyData.resolution_type,
        sku: historyData.sku,
        reprocess: 1,
        is_wms:historyData.is_wms,
        is_fareye: historyData.is_fareyes,
      },
      dispatch,
    });
    setReprocessStatus((prevStatus) => ({
      ...prevStatus,
      [historyData.reference_no]: 'Processing',
    }));

    //  console.log("detail",queueDetails);
  };

  const onDrawerClose = () => {
    setOpen(!open);
  };

  const { orderupdateDetails = [] } = useSelector((state) => state.queueMaster);
  const customDivStyle = {
    borderBottom: '1px solid #ccc',
    marginBottom: '10px',
    // Add more styles as needed
  };
  function getProgressColor(percentage) {
    if (percentage <= 10) {
      return 'danger'; // Red for 0-10%
    } else if (percentage <= 30) {
      return 'warning'; // Yellow for 11-30%
    } else if (percentage <= 50) {
      return 'info'; // Blue for 31-50%
    } else if (percentage <= 75) {
      return 'primary'; // Light blue for 51-75%
    } else if (percentage <= 90) {
      return 'success'; // Green for 76-90%
    } else {
      return 'success'; // Green for 91-100%
    }
  }
  useEffect(() => {
    if (orderupdateDetails?.code === 100) {
      setUpdateDetailsLoad(false);
      setLoading(true);

      console.log('toster', orderupdateDetails?.message);

      toast.success(orderupdateDetails?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (orderupdateDetails?.message) {
        setUpdateDetailsLoad(false);
        toast.error(orderupdateDetails?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [orderupdateDetails]);

  return (
    <div className="table-responsive dt-table">
      <Row>
        <Col md={7}></Col>
      </Row>
      <Row className=" dh-TabRow textAlignRight ">
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>

      <ReactDrawer
        open={open}
        position="right"
        onClose={onDrawerClose}
        className="sideNavWidth"
      >
        <Card
          className="card-h-100 OrderDetailsBox"
          style={{ overflowY: 'scroll' }}
        >
          <CardHeader>
            <Row>
              <Col className="textAlignRight">
                {' '}
                <button
                  type="button"
                  className="btn-close "
                  onClick={() => {
                    onDrawerClose();
                  }}
                  aria-label="Close"
                ></button>
              </Col>
              <div>
                Queue Details of <b>{orderValue?.reference_no}</b>
              </div>

              <div className="">
                <div>
                  <b>Created By:</b>
                  {orderValue?.created_by}
                </div>
                <div>
                  {' '}
                  <b>Date: </b> {orderValue?.date}
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <p>
              <b>Status History</b>
            </p>
            <StatusBar detail={detail} orderValue={orderValue} />{' '}
            {/* Add StatusBar component */}
          </CardBody>
        </Card>
      </ReactDrawer>

      {orderData?.length > 0 ? (
        <>
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th nowrap="nowrap">Reference NO</th>
                <th nowrap="nowrap">SKU</th>
                <th>Cancel No</th>
                <th>Reason</th>
                <th>Request Type</th>
                <th>Resolution Mode</th>
                <th>Status</th>
                <th>Action </th>
              </tr>
            </thead>
            {(orderData || []).map((order, index) => {
              const progressColor = getProgressColor(order.status_percentage);
              pageIndex++;

              return (
                <tbody key={order.id}>
                  <tr
                    className={order?.error_message !== null ? 'my-class' : ''}
                  >
                    <th scope="row">{pageIndex}</th>

                    <td nowrap="nowrap">
                      {order.reference_number}
                      <span className="highlightInfo2 ">
                        Payment Mode:
                        {order?.resolution_type === 'cashondelivery' ? (
                          <>
                            <b>Cash on delivery</b>
                          </>
                        ) : (
                          <>
                            <b>Credit Card</b>
                          </>
                        )}
                      </span>
                      <span className="highlightInfo2 ">
                        Date:{order.created_at}
                      </span>
                      <span className="highlightInfo2 ">
                        Created By: {order.created_by}
                      </span>
                    </td>
                    <td nowrap="nowrap">{order.cancel_sku}</td>
                    <td nowrap="nowrap">{order.transaction_reference}</td>
                    <td nowrap="nowrap">
                      <span style={{ paddingRight: '10px' }}>
                        {order.reason}
                      </span>
                    </td>
                    <td nowrap="nowrap">{order.queue_type}</td>
                    <td nowrap="nowrap">
                      {order?.resolution_type === 'cashondelivery' ? (
                        <>cashondelivery</>
                      ) : order?.resolution_type === 'refund' ? (
                        <>Refund to orginal mode</>
                      ) : (
                        <>{order.resolution_type}</>
                      )}
                    </td>
                    <td nowrap="nowrap">
                      {order?.status === '0' ? (
                        <>
                          <b>Pending</b>
                        </>
                      ) : order?.status === '1' ? (
                        <>Completed</>
                      ) : order?.status === '2' ? (
                        <>LS Canceled</>
                      ) : order?.status === '3' ? (
                        <>Waiting for LS cancel</>
                      ) : order?.status === '5' ? (
                        <>Refund Initiated</>
                      ) : order?.status === '7' ? (
                        <>Push to seller</>
                      ) : order?.status === '8' ? (
                        <>Seller Cancel</>
                      ) : order?.status === '9' ? (
                        <>Wallet Initated</>
                      ) : order?.status === '10' ? (
                        <>Wallet Approved</>
                      ) : order?.status === '21' ? (
                        <>LS Api triggered</>
                      ) : order?.status === '17' ? (
                        <>Seller canceled</>
                      ) : order?.status === '18' ? (
                        <>BB Order creation</>
                      ) : order?.status === '31' ? (
                        <>Cancel Rejected</>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          reprocessCancel({
                            reference_no: order.reference_number,
                            erpOrderNum: order?.ls_reference,
                            sku: order?.cancel_sku,
                            queue_id: order?.queue_id,
                            resolution_type: order?.resolution_type,
                            is_wms:order?.is_wms,
                            is_fareye:order?.is_fareye,
                          });
                        }}
                        type="button"
                        className="btn btn-sm btn-outline-warning waves-effect waves-light"
                      >
                        {' '}
                        {reprocessStatus[order.reference_number] || 'Reprocess'}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" className="queueListProgress">
                      <Progress
                        color={progressColor}
                        className="progress-md"
                        value={order.status_percentage}
                        animated
                      >
                        {order.status_percentage}%
                      </Progress>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
          <div className="dataTblFooter">
            <div>
              <Dropdown
                isOpen={btnprimary1}
                toggle={() => setBtnprimary1(!btnprimary1)}
              >
                <DropdownToggle tag="button" className="btn btn-dark">
                  {limitValue.limit} Items{' '}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  {limitByValues.map((limit, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        href="#"
                        onClick={() => handleLimit(limit)}
                      >
                        {limit} Items
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className="d-flex justify-content-center">
              <Pagination
                id="listPage"
                value={paginationValue.page}
                totalPages={listOrders?.recordsTotal}
                onChange={(e) => handlePagination(e)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th nowrap="nowrap">Reference NO</th>
                <th>Transaction Ref</th>
                <th>Created date</th>
                <th>Status</th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={7}>No Records found</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};
export default ListRejectedComponent;
