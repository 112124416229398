import axios from "axios";
import { setMarketPlaceOrderList, setMarketPlaceSplitOrderList, getMarketPlaceOrderDetail, getMarketPlaceSplitOrderDetail, getCheckData,getStatusCount, getDashboardCount,getDashboardSummary,marketPlaceImportOrders } from "../../../Redux/marketplace/marketplaceSlice";
import UserAuthKey from "../../../components/Common/UserAuthKey";
import { setUserData } from "../../../Redux/user/userSlice";
const apiUrl = process.env.REACT_APP_OMS_API;

export const fetchMarketPlaceOrders = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/marketplace-orders/marketplaceorder-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setMarketPlaceOrderList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  
};
export const fetchMarketPlaceSplitOrders = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/marketplace-orders/marketplaceorder-split-list`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setMarketPlaceSplitOrderList(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
  
};
export const marketPlaceOrderDetail = async({ params, dispatch }) => {
  const bearerToken = UserAuthKey();
  const config = {
    method: 'POST',
    url: `${apiUrl}v1/marketplace-orders/marketplaceorder-details`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(getMarketPlaceOrderDetail(response?.data));
  } catch (error) {
    if(error?.response?.status === 401){
      dispatch(setUserData(null))
      window.location.replace('/logout');
   }
   else{

   }
  }
    
  };
  export const marketPlaceSplitOrderDetail = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/marketplace-orders/marketplacesplitorder-details`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getMarketPlaceSplitOrderDetail(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
      
    };
  export const fetchCheckData = async({ formData, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
       method: 'POST',
       url: `${apiUrl}v1/marketplace-orders/marketplaceorder-checkData`,
       headers: {
        Authorization: `Bearer ${bearerToken}`,
       'Content-Type': "multipart/form-data",
      
        },
       
        data: formData, 
      
      };
    try {
       const response = await axios(config);
       dispatch(getCheckData(response?.data));
       } catch (error) {
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
    
       }
      }
    // return axios
    // .post(`${apiUrl}v1/marketplace-orders/marketplaceorder-checkData`,formData,  {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // })
    // .then((response) => {
    //   dispatch(getCheckData(response?.data));
    // })
    // .catch((error) => {
    //   throw error;
    // });
      
  };
  export const fetchMarketplaceCount = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/marketplace-orders/marketplacestatus-counts`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getStatusCount(response?.data));
    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
 
  };

  export const fetchMarketDashboardCount = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/marketplace-orders/marketplaceorder-dashboardcounts`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getDashboardCount(response?.data));

    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
  
  };
  export const fetchMarketDashboardSummary = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/marketplace-orders/marketplaceorder-dashboardsummary`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(getDashboardSummary(response?.data));

    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
    
  };
  export const marketPlaceImportSummary = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/marketplace-orders/marketplaceorder-importData`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(marketPlaceImportOrders(response?.data));

    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
  
  };
  export const fetchInstalCheckData = async({ formData, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
       method: 'POST',
       url: `${apiUrl}v1/marketplace-orders/installation-checkData`,
       headers: {
        Authorization: `Bearer ${bearerToken}`,
       'Content-Type': "multipart/form-data",
      
        },
       
        data: formData, 
      
      };
    try {
       const response = await axios(config);
       dispatch(getCheckData(response?.data));
       } catch (error) {
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
    
       }
      }
    // return axios
    // .post(`${apiUrl}v1/marketplace-orders/marketplaceorder-checkData`,formData,  {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // })
    // .then((response) => {
    //   dispatch(getCheckData(response?.data));
    // })
    // .catch((error) => {
    //   throw error;
    // });
      
  };

  export const installationImportSummary = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/marketplace-orders/installation-importData`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(marketPlaceImportOrders(response?.data));

    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
  
  };
  export const fetchReturnCheckData = async({ formData, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
       method: 'POST',
       url: `${apiUrl}v1/orders/import-return`,
       headers: {
        Authorization: `Bearer ${bearerToken}`,
       'Content-Type': "multipart/form-data",
      
        },
       
        data: formData, 
      
      };
    try {
       const response = await axios(config);
       dispatch(getCheckData(response?.data));
       } catch (error) {
        if(error?.response?.status === 401){
          dispatch(setUserData(null))
          window.location.replace('/logout');
       }
       else{
    
       }
      }

      
  };


  export const returnImportSummary = async({ params, dispatch }) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/orders/insert-import-return`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...params,
      },
    };
    try {
      const response = await axios(config);
      dispatch(marketPlaceImportOrders(response?.data));

    } catch (error) {
      if(error?.response?.status === 401){
        dispatch(setUserData(null))
        window.location.replace('/logout');
     }
     else{
  
     }
    }
  
  };