import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { useUrlSearchParams } from 'use-url-search-params';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const FilterSearchQueue = (props) => {
  const { setSearchFilter, setStatusFilter, setAdvancedFilter, type } = props;
  //Advance Filter Data
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(null);
  //End Advance Filter Data
  const [stsSearchParams, setStsSearchParams] = useUrlSearchParams({
    status: 'All',
  });
  const [searchParams, setSearchParams] = useUrlSearchParams({ search: '' });
  const [searchValue, setSearchValue] = useState(searchParams.search || '');
  const [status, setStatus] = useState(stsSearchParams.status || 'All');
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const disableFutureDates = (date) => {
    // Disables dates after today
    return date.getTime() > new Date().getTime();
  };

  const handleDateChange = (dates) => {
    if (dates?.[0] && dates?.[1]) {
      const startDate = moment(dates[0]).format('YYYY-MM-DD');
      const endDate = moment(dates[1]).format('YYYY-MM-DD');
      setAdvanceFilterData({
        ...advanceFilterData,
        frmDt: startDate,
        toDt: endDate,
      });
      setDateRangeValue(dates);
    }
  };
  const handleDateClose = () => {
    setAdvanceFilterData({
      ...advanceFilterData,
      frmDt: '',
      toDt: '',
    });
    setDateRangeValue(null);
  };
  // Set filters only on search button click
  const handleSearchButtonClick = () => {
    setIsActiveSearch(true);
    setAdvancedFilter(advanceFilterData);
    setSearchParams({ search: searchValue });
    setStsSearchParams({ status });
    setSearchFilter({ search: searchValue });
    setStatusFilter({ status });
  };

  const handleResetSearch = () => {
    setIsActiveSearch(false);
    setSearchParams({ search: '' });
    setStsSearchParams({ status: 'All' });
    setSearchFilter({ search: '' });
    setStatusFilter({ status: 'All' });
    setSearchValue('');
    setStatus('All');
    setAdvancedFilter({});
    setAdvanceFilterData({});
    setDateRangeValue(null);
  };

  return (
    <React.Fragment>
      <div
        className="p-3 bg-soft-light border-1 border-light"
        style={{ borderStyle: 'dashed' }}
      >
        <Row>
          <Col md={7} className="d-flex flex-row align-items-center gap-2">
            <div className="position-relative" style={{ minWidth: 200 }}>
              <Input
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
                type="text"
                className="form-control form-control"
                onChange={handleSearch}
                value={searchValue}
              ></Input>
              <span className="p-2 text-light position-absolute top-50 end-0 translate-middle-y">
                <i className="bx bx-search-alt font-size-16 align-middle"></i>
              </span>
            </div>
            <div style={{ minWidth: 150 }}>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleStatus}
                value={status}
              >
                <option value="All">Select Status</option>
                <option value="All">All</option>
                {type === 'return' ? (
                  <>
                    <option value="Pending">Pending</option>
                    <option value="Authorized">Authorized</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Approved">Approved</option>
                    <option value="Received">Received</option>
                  </>
                ) : null}
              </select>
            </div>
            <div>
              <DateRangePicker
                size="lg"
                className="w-100"
                placement="auto"
                placeholder="Select a Date Range"
                showOneCalendar={false}
                disabledDate={disableFutureDates}
                onChange={handleDateChange}
                onClean={handleDateClose}
                value={dateRangeValue}
              />
            </div>

            <Button
              color="secondary"
              type="button"
              id="srchBtn"
              onClick={handleSearchButtonClick}
            >
              <i className="bx bx-search-alt font-size-16 align-middle"></i>
            </Button>
            <Button
              outline
              color="danger"
              type="button"
              onClick={handleResetSearch}
            >
              <i className="bx bx-revision font-size-16 align-middle"></i>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <p className="m-0 d-flex gap-2 align-items-center">
              {isActiveSearch &&
              (searchParams?.search ||
                (stsSearchParams?.status && stsSearchParams?.status != 'All') ||
                (advanceFilterData && advanceFilterData?.frmDt)) ? (
                <span className="fs-11">Showing Result for : </span>
              ) : null}

              {searchParams?.search ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Keyword : {searchParams?.search}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setSearchFilter({ search: '' });
                      setSearchParams({ search: '' });
                      setSearchValue('');
                    }}
                  />
                </span>
              ) : null}
              {stsSearchParams?.status && stsSearchParams?.status != 'All' ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Status : {stsSearchParams?.status}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setStatusFilter({ status: 'All' });
                      setStsSearchParams({ status: 'All' });
                      setStatus('All');
                    }}
                  />
                </span>
              ) : null}
              {advanceFilterData && advanceFilterData?.frmDt ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Date From : {advanceFilterData?.frmDt}
                  {' to '} {advanceFilterData?.toDt}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setAdvancedFilter((advanceFilterData) => ({
                        ...advanceFilterData,
                        frmDt: '',
                        toDt: '',
                      }));
                      setAdvanceFilterData((advanceFilterData) => ({
                        ...advanceFilterData,
                        frmDt: '',
                        toDt: '',
                      }));
                      handleDateClose();
                      // setFormattedStartDate([]);
                      // setFormattedEndDate([]);
                    }}
                  />
                </span>
              ) : null}
            </p>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default FilterSearchQueue;
