import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
//import OrderList from './components/orderList';
import BulkOrderList from './components/bulkorders/BulkOrderList';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import ManageNonServiceAreaList from './components/NonPlanningorders/ManageNonServiceAreaList';
import { NonServiceAreaInsert } from './actions/orderActions';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  InputGroup,
  Button,
  CardTitle,
  Label,
  Input,
  Form,
  Table,
  UncontrolledDropdown,
  Spinner,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const NonServiceArea = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showForm, setshowForm] = useState(false);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_password, setmodal_password] = useState(false);
  const [insertNonAreaLoad, setInsertNonAreaLoad] = useState(false);
  const [status, setSatus] = useState('1');
  const [selectContury, setSelectContury] = useState();

  const handleUserForm = () => {
    setshowForm(true);
  };
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  const onDrawerClose = () => {
    setOpen(false);
  };
  function tog_backdrop() {
    onDrawerClose();
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function tog_password() {
    setmodal_backdrop(false);
    onDrawerClose();
    setmodal_password(!modal_password);
    removeBodyCss();
  }

  const handleNonServiceAreaInsert = (events, values) => {
    setInsertNonAreaLoad(true);
    const area = values?.area ? values?.area : '';
    const emirate = values?.emirate ? values?.emirate : '';
    const country = values?.country ? values?.country : '';
    const is_active = values?.is_active ? values?.is_active : '';
    console.log(is_active, 'is_active--------------------------');
    NonServiceAreaInsert({
      params: {
        area_id: id,
        area: area,
        emirate: emirate,
        country: country,
        is_active: status,
      },
      dispatch,
    });
  };
  const { NonServiceInsert = [], isNonServiceInsertLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (NonServiceInsert?.code === 100) {
      setInsertNonAreaLoad(false);
      toast.success(NonServiceInsert?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (NonServiceInsert?.message) {
        toast.error(NonServiceInsert?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [NonServiceInsert]);

  return (
    <React.Fragment>
      <div className="page-content NonService">
        <MetaTags>
          <title>NonServiceArea</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={10}>
              <DashBreadcrumb
                title="NonPlanningOrders"
                breadcrumbItem="NonServiceArea"
              />
            </Col>
            <Col xl={2} className="textAlignRight">
              <button
                type="submit"
                className="btn btn-dark "
                onClick={toggleRightDrawer}
                disabled={open}
              >
                Add NonService Area +
              </button>
            </Col>
          </Row>
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
            className="sideNavWidth"
          >
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>NonService Area Form </CardTitle>
              </CardHeader>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleNonServiceAreaInsert(e, v);
                  }}
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom01">Sub Area</Label>
                        <AvField
                          name="area"
                          placeholder="Find a area"
                          type="text"
                          required
                          className="form-control"
                          value=""
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="">Country</Label>
                        <AvField
                          type="select"
                          name="country"
                          className="form-select"
                          required
                          onChange={(e) => setSelectContury(e.target.value)}
                        >
                          <option value="select">Select</option>
                          <option value="UAE">UAE</option>
                          <option value="Oman">Oman</option>
                          <option value="Baharain">Bahrian</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Kuwait">Kuwait</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                  {selectContury === 'UAE' ? (
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="">Region</Label>
                          <AvField
                            type="select"
                            name="emirate"
                            value=""
                            required
                            className="form-select"
                          >
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Dubai">Dubai</option>
                            <option value="Sharjah">Sharjah</option>
                            <option value="Ajman">Ajman</option>
                            <option value="Umm Al-Quwain">Umm Al-Quwain</option>
                            <option value="Fujairah">Fujairah</option>
                            <option value="Ras Al Khaimah">
                              Ras Al Khaimah
                            </option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Region</Label>
                          <AvField
                            name="emirate"
                            type="text"
                            className="form-control"
                            value=""
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col
                      lg="12
                                "
                    >
                      <FormGroup className="mb-3">
                        <label>Status</label>
                        <select
                          className="form-control form-select"
                          name="is_active"
                          onChange={(e) => setSatus(e.target.value)}
                          required
                        >
                          <option value="1">Active</option>
                          <option value="0">InActive</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button type="submit" color="success" className="">
                    {insertNonAreaLoad === true && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    Save
                  </Button>{' '}
                </AvForm>
              </CardBody>
            </Card>
          </ReactDrawer>

          <ManageNonServiceAreaList />
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default NonServiceArea;
