/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import './Dashboard.scss';

import { DashBreadcrumb } from '../../../components/Common/Breadcrumb';
import {
  fetchDashboardGraphdata,
  fetchDashboardSummerydata,
  fetchStatausCount,
} from '../../../common/data/action/dashboardaction';
import { allOrderStatusCount } from '../actions/orderActions';
import Spinearea from '../../AllCharts/apex/SplineArea';
import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import moment from 'moment';

import BarChart from '../../AllCharts/apex/barchart';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { DateRangePicker } from 'rsuite';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  CardHeader,
  Input,
  Table,
  FormGroup,
  InputGroup,
} from 'reactstrap';

import CountUp from 'react-countup';

const OrderDashboard = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    new moment(new Date()).format('YYYY-MM-DD')
  );
  const { afterToday, combine, allowedMaxDays } = DateRangePicker;
  const [endDate, setEndDate] = useState(
    new moment(new Date()).format('YYYY-MM-DD')
  );
  const [active, setActiveValue] = useState('all');

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(true);
  // const [isSummeryLoading, setIsSummeryLoading] = useState(true);

  const [startDateGraph, setStartDateGraph] = useState(
    new moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format(
      'YYYY-MM-DD'
    )
  );
  const [endDateGraph, setEndDateGraph] = useState(
    new moment(new Date()).format('YYYY-MM-DD')
  );
  const [summaryType, setSummaryType] = useState('lastyear');
  const { StatusCount = [], isStatusLoading } = useSelector(
    (state) => state.dashboard
  );
  const Total = StatusCount?.data;

  const { countStatus = [], iscountStatusLoading } = useSelector(
    (state) => state.order
  );
  const { dashboardGraph = [], isGraphLoading } = useSelector(
    (state) => state.dashboard
  );

  const { summreryData = [], isSummeryLoading } = useSelector(
    (state) => state.dashboard
  );

  const growthData = summreryData?.data?.growthList;
  const advData = summreryData?.data?.advDataList;

  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }

  let cntry = localStorage.getItem('WEBSITE_COUNTRY');

  if (cntry !== 'all' && cntry !== null) {
    cntry = cntry?.toUpperCase();
  }
  useEffect(() => {
    setIsLoading(false);
    fetchStatausCount({
      params: {
        date_from: startDate,
        date_to: endDate === undefined || endDate === '' ? startDate : endDate,
        country: cntry,
      },
      dispatch,
    });
  }, [startDate, endDate, cntry]);
  useEffect(() => {
    setIsLoadingLanguage(false);
    fetchDashboardSummerydata({
      params: {
        date_from: startDate,
        date_to: endDate === undefined || endDate === '' ? startDate : endDate,
        country: cntry,
        language: active,
      },
      dispatch,
    });
  }, [active, startDate, endDate, cntry]);
  useEffect(() => {
    setIsLoading(false);
    allOrderStatusCount({
      params: {
        type: summaryType,
        country: cntry,
      },
      dispatch,
    });
  }, [summaryType, cntry]);

  const TotalCount = countStatus?.data;

  let statsDate = '';
  let endsDate = '';
  useEffect(() => {
    localStorage.setItem('startsDate', startDate);
    localStorage.setItem('endsDate', endDate);
  }, []);
  statsDate = localStorage.getItem('startsDate');
  endsDate = localStorage.getItem('endsDate');
  useEffect(() => {
    setIsLoading(false);
    let eDate = new moment(endDateGraph).format('YYYY-MM-DD');
    let sDate = new moment(startDateGraph).format('YYYY-MM-DD');
    if (
      startDateGraph === '' ||
      startDateGraph === undefined ||
      endDateGraph === '' ||
      endDateGraph === undefined
    ) {
      sDate = new moment(new Date() - 7).format('YYYY-MM-DD');
      eDate = new moment(new Date()).format('YYYY-MM-DD');
    }

    fetchDashboardGraphdata({
      params: {
        date_from: sDate === '' ? statsDate : sDate,
        date_to: eDate === '' ? endsDate : eDate,
        country: cntry,
      },

      dispatch,
    });
  }, [endDateGraph, startDateGraph, cntry]);

  const splineGraph = dashboardGraph?.results;
  let contVal = cntry;
  let currency = '';
  if (cntry === 'AE') {
    currency = 'AED';
  } else if (cntry === 'KW') {
    currency = 'KWD';
  } else if (cntry === 'QA') {
    currency = 'QAR';
  } else if (cntry === 'BH') {
    currency = 'BHD';
  } else if (cntry === 'OM') {
    currency = 'OMR';
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | DH OMS - Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Dashboard" breadcrumbItem="Dashboard" />
            </Col>
            <Col xl={6} className="textAlignRight language">
              <div className="selectDashBoardOption">
                <select
                  className="form-control form-select"
                  name="is_active"
                  value={active}
                  onChange={(e) => {
                    setActiveValue(e?.target?.value);
                  }}
                >
                  <option value="all">All</option>
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
              </div>

              <div className="DashbordFliter">
                {isMobileView !== 0 ? (
                  <>
                    <DateRangePicker
                      placement="auto"
                      placeholder="Select Date Range"
                      onClean={() => {
                        setStartDate(
                          new moment(new Date()).format('YYYY-MM-DD')
                        );
                        setEndDate(new moment(new Date()).format('YYYY-MM-DD'));
                      }}
                      showOneCalendar
                      onChange={(selectedDates, instance) => {
                        const dateStr = selectedDates?.[0];
                        const first_Date = new Date(dateStr)?.toString();
                        const firstD = moment(new Date(first_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const firstDateReplace = firstD?.replaceAll('/', '-');
                        const dateStr1 = selectedDates?.[1];
                        const second_Date = new Date(dateStr1)?.toString();
                        const secondD = moment(new Date(second_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const secondDateReplace = secondD?.replaceAll('/', '-');
                        setStartDate(firstDateReplace);
                        setEndDate(secondDateReplace);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DateRangePicker
                      placement="leftStart"
                      placeholder="Select Date Range"
                      onClean={() => {
                        setStartDate(
                          new moment(new Date()).format('YYYY-MM-DD')
                        );
                        setEndDate(new moment(new Date()).format('YYYY-MM-DD'));
                      }}
                      // disabledDate={combine(allowedMaxDays(30), afterToday())}
                      onChange={(selectedDates, instance) => {
                        const dateStr = selectedDates?.[0];
                        const first_Date = new Date(dateStr)?.toString();
                        const firstD = moment(new Date(first_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const firstDateReplace = firstD?.replaceAll('/', '-');
                        const dateStr1 = selectedDates?.[1];
                        const second_Date = new Date(dateStr1)?.toString();
                        const secondD = moment(new Date(second_Date))?.format(
                          'YYYY/MM/DD'
                        );
                        const secondDateReplace = secondD?.replaceAll('/', '-');
                        setStartDate(firstDateReplace);
                        setEndDate(secondDateReplace);
                      }}
                    />{' '}
                  </>
                )}
              </div>
            </Col>
          </Row>
          {cntry === 'all' || isLoading || isLoadingLanguage ? (
            <>
              <Row
                className="row-fluid-scroll flex-nowrap mb-4"
                style={{ overflowX: 'scroll' }}
              >
                <Col xl={3} md={5}>
                  <Card className="TotalOrderCard">
                    <CardHeader>
                      <CardTitle>Today UAE Orders</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>AED {Total?.AE?.total_open}</td>
                            </tr>
                            <tr>
                              <td>Posted</td>
                              <td>AED {Total?.AE?.total_posted}</td>
                            </tr>
                            <tr className="notLs">
                              <td>Not in LS</td>
                              <td>AED {Total?.AE?.total_not_in_ls}</td>
                            </tr>
                            <tr className="notLs">
                              <td>(B2B + Normal)</td>
                              <td>
                                AED {Total?.AE?.total_not_in_ls_b2b} +{' '}
                                {Total?.AE?.total_not_in_ls_normal}
                              </td>
                            </tr>
                            <tr>
                              <td>Pending Payment</td>
                              <td>AED {Total?.AE?.total_pending_payment}</td>
                            </tr>
                            <tr>
                              <td>Cancelled</td>
                              <td>AED {Total?.AE?.total_canceled}</td>
                            </tr>
                            <tr className="bordertop netTotal">
                              <th>Net Total </th>
                              <th>
                                AED{' '}
                                {Number(
                                  Total?.AE?.total_open +
                                    Total?.AE?.total_posted +
                                    Total?.AE?.total_not_in_ls
                                ).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col xl={3} md={5}>
                  <Card className="TotalOrderCard">
                    <CardHeader>
                      <CardTitle>Today Kuwait Orders</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>KWD {Total?.KW?.total_open}</td>
                            </tr>
                            <tr>
                              <td>Posted</td>
                              <td>KWD {Total?.KW?.total_posted}</td>
                            </tr>
                            <tr className="notLs">
                              <td>Not in LS</td>
                              <td>KWD {Total?.KW?.total_not_in_ls}</td>
                            </tr>
                            <tr>
                              <td>Pending Payment</td>
                              <td>KWD {Total?.KW?.total_pending_payment}</td>
                            </tr>
                            <tr>
                              <td>Cancelled</td>
                              <td>KWD {Total?.KW?.total_canceled}</td>
                            </tr>
                            <tr className="bordertop netTotal">
                              <th>Net Total </th>
                              <th>
                                KWD{' '}
                                {Number(
                                  Total?.KW?.total_open +
                                    Total?.KW?.total_posted +
                                    Total?.KW?.total_not_in_ls
                                ).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col xl={3} md={5}>
                  <Card className="TotalOrderCard">
                    <CardHeader>
                      <CardTitle>Today Oman Orders</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>OMR {Total?.OM?.total_open}</td>
                            </tr>
                            <tr>
                              <td>Posted</td>
                              <td>OMR {Total?.OM?.total_posted}</td>
                            </tr>
                            <tr className="notLs">
                              <td>Not in LS</td>
                              <td>OMR {Total?.OM?.total_not_in_ls}</td>
                            </tr>
                            <tr>
                              <td>Pending Payment</td>
                              <td>OMR {Total?.OM?.total_pending_payment}</td>
                            </tr>
                            <tr>
                              <td>Cancelled</td>
                              <td>OMR {Total?.OM?.total_canceled}</td>
                            </tr>
                            <tr className="bordertop netTotal">
                              <th>Net Total </th>
                              <th>
                                OMR{' '}
                                {Number(
                                  Total?.OM?.total_open +
                                    Total?.OM?.total_posted +
                                    Total?.OM?.total_not_in_ls
                                ).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={5}>
                  <Card className="TotalOrderCard">
                    <CardHeader>
                      <CardTitle>Today Bahrain Orders</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>BHD {Total?.BH?.total_open}</td>
                            </tr>
                            <tr>
                              <td>Posted</td>
                              <td>BHD {Total?.BH?.total_posted}</td>
                            </tr>
                            <tr className="notLs">
                              <td>Not in LS</td>
                              <td>BHD {Total?.BH?.total_not_in_ls}</td>
                            </tr>
                            <tr>
                              <td>Pending Payment</td>
                              <td>BHD {Total?.BH?.total_pending_payment}</td>
                            </tr>
                            <tr>
                              <td>Cancelled</td>
                              <td>BHD {Total?.BH?.total_canceled}</td>
                            </tr>
                            <tr className="bordertop netTotal">
                              <th>Net Total </th>
                              <th>
                                BHD{' '}
                                {Number(
                                  Total?.BH?.total_posted +
                                    Total?.BH?.total_not_in_ls
                                ).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={5}>
                  <Card className="TotalOrderCard">
                    <CardHeader>
                      <CardTitle>Today Qatar Orders</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Open</td>
                              <td>QAR {Total?.QA?.total_open}</td>
                            </tr>
                            <tr>
                              <td>Posted</td>
                              <td>QAR {Total?.QA?.total_posted}</td>
                            </tr>
                            <tr className="notLs">
                              <td>Not in LS</td>
                              <td>QAR {Total?.QA?.total_not_in_ls}</td>
                            </tr>
                            <tr>
                              <td>Pending Payment</td>
                              <td>QAR {Total?.QA?.total_pending_payment}</td>
                            </tr>
                            <tr>
                              <td>Cancelled</td>
                              <td>QAR {Total?.QA?.total_canceled}</td>
                            </tr>
                            <tr className="bordertop netTotal">
                              <th>Net Total </th>
                              <th>
                                QAR{' '}
                                {Number(
                                  Total?.QA?.total_posted +
                                    Total?.QA?.total_not_in_ls
                                ).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                {isSummeryLoading ? (
                  <>
                    <CardBody>
                      <Skeleton count={5} />
                    </CardBody>
                  </>
                ) : null}

                {growthData?.map((items) => {
                  return (
                    <Col md={12} lg={12} xl={6}>
                      <Card>
                        <CardHeader className="d-sm-flex align-items-center justify-content-between bg-soft-light">
                          <CardTitle>
                            <Row className="align-items-center">
                              <Col>
                                {items?.type === 'app'
                                  ? 'Mobile App'
                                  : 'Website'}
                              </Col>
                              <Col className="d-md-none">
                                <span className="display-5 text-success d-flex justify-content-end">
                                  <i class="bx bx-trending-up"></i>
                                </span>
                              </Col>
                            </Row>
                          </CardTitle>
                        </CardHeader>
                        <CardBody className="trendingBody">
                          <div className="trendingContain">
                            <Row className="align-items-center px-0 mt-3">
                              <Col sm={2} className="d-none d-md-block">
                                <span
                                  className={`display-5 ${
                                    items?.growthRate > 0
                                      ? 'text-success'
                                      : 'text-danger'
                                  } d-flex justify-content-center`}
                                >
                                  <i
                                    class={`bx ${
                                      items?.growthRate > 0
                                        ? 'bx-trending-up'
                                        : 'bx-trending-down'
                                    }`}
                                  ></i>
                                </span>
                              </Col>
                              <Col>
                                <p className="d-flex align-items-center justify-content-start gap-2 mb-0 trendingTitle">
                                  <span className="title">Revenue</span>
                                  <strong className="fw-medium">
                                    {items?.totalCYSale || 0}
                                  </strong>
                                </p>
                                <p className="d-flex align-items-center justify-content-start gap-2 trending m-0">
                                  <span
                                    className={`${
                                      items?.growthRate > 0
                                        ? 'bg-soft-success text-success'
                                        : 'bg-soft-danger text-danger'
                                    } p-1 fw-medium text-center`}
                                  >
                                    <i
                                      class={`mdi ${
                                        items?.growthRate > 0
                                          ? 'mdi-arrow-up-bold'
                                          : 'mdi-arrow-down-bold'
                                      }`}
                                    ></i>{' '}
                                    {items?.growthRate || 0}%
                                    <span>
                                      {' '}
                                      {items?.growthRate > 0
                                        ? 'Increase'
                                        : 'Decrease'}
                                    </span>
                                  </span>
                                  <small>
                                    compare last year ({items?.totalPYSale || 0}
                                    )
                                  </small>
                                </p>
                              </Col>
                              <Col>
                                <p className="d-flex align-items-center justify-content-start gap-2 mb-0 trendingTitle">
                                  <span className="title">No.of Orders</span>
                                  <strong className="fw-medium ">
                                    {items?.totalCYOrders || 0}
                                  </strong>
                                </p>

                                {/* <p className="d-flex align-items-center justify-content-start gap-2 trending m-0">
                                  <span className="bg-success p-1 fw-medium text-center">
                                    <i class="mdi mdi-arrow-up-bold"></i> 20%
                                    <span>Increase</span>
                                  </span>
                                  <small>From last month (250)</small>
                                </p> */}
                              </Col>
                            </Row>
                            <hr className="horizontal" />
                            <Row className="align-items-center px-0 m-0">
                              {items?.deviceData?.map((itemData, index) => {
                                const device = itemData?.type;
                                console.log(itemData?.type, 'itemData');
                                let deviceIcon = '';

                                return (
                                  <>
                                    <Col>
                                      <Row className="align-items-center">
                                        <Col sm={12}>
                                          <Row className="align-items-center">
                                            <Col sm={2}>
                                              <span
                                                className={`d-flex ${
                                                  itemData?.type === 'ios'
                                                    ? 'appleIcon'
                                                    : itemData?.type ===
                                                      'android'
                                                    ? 'androidIcon'
                                                    : itemData?.type ===
                                                      'mobile'
                                                    ? 'cellphoneIcon'
                                                    : 'desktopIcon'
                                                }`}
                                              >
                                                <i
                                                  class={`mdi ${
                                                    itemData?.type === 'ios'
                                                      ? 'mdi-apple'
                                                      : itemData?.type ===
                                                        'android'
                                                      ? 'mdi-android'
                                                      : itemData?.type ===
                                                        'mobile'
                                                      ? 'mdi-cellphone'
                                                      : 'mdi-desktop-mac'
                                                  }`}
                                                ></i>
                                                <span className="d-md-none deviceType">
                                                  {itemData?.type.toUpperCase()}
                                                </span>
                                              </span>
                                            </Col>
                                            <Col>
                                              <p className="mb-1 trendingDeviceTitleLabel">
                                                <span className="title">
                                                  Revenue
                                                </span>
                                              </p>
                                              <h6 className="d-flex align-items-center justify-content-start gap-1  mb-0 trendingDeviceTitle">
                                                <strong className="fw-medium">
                                                  {itemData?.totalCYSale || 0}
                                                </strong>
                                                <small className="text-success">
                                                  No.of Orders :{' '}
                                                  {itemData?.totalCYOrders || 0}
                                                </small>
                                              </h6>
                                            </Col>
                                            <Col
                                              sm={2}
                                              className="d-none d-md-block "
                                            >
                                              <span className="text-success d-flex justify-content-center trendingIcon">
                                                <i class="bx bx-trending-up"></i>
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <p className="d-flex align-items-center justify-content-start gap-2 trendingDevice my-1">
                                                <span
                                                  className={`bg-${
                                                    itemData?.growthRate > 0
                                                      ? 'soft-success text-success'
                                                      : 'soft-danger text-danger'
                                                  } p-1 fw-medium text-center`}
                                                >
                                                  <i
                                                    class={`mdi mdi-arrow-${
                                                      itemData?.growthRate > 0
                                                        ? 'up'
                                                        : 'down'
                                                    }-bold`}
                                                  ></i>{' '}
                                                  {itemData?.growthRate || 0}%
                                                  <span> Increase</span>
                                                </span>
                                                <small>
                                                  compare last year (
                                                  {itemData?.totalPYSale || 0})
                                                </small>
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {index === 0 ? (
                                      <hr className="vertical" />
                                    ) : null}
                                  </>
                                );
                              })}
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>

              <Row>
                <Col>
                  <Card>
                    <CardHeader className="d-sm-flex align-items-center justify-content-between bg-soft-light">
                      <CardTitle>
                        <Row className="align-items-center">
                          <Col>
                            Source Analytics -
                            <span>
                              {startDate} - {endDate}
                            </span>
                          </Col>
                          <Col className="d-md-none">
                            <span className="display-5 text-success d-flex justify-content-end">
                              <i class="bx bx-trending-up"></i>
                            </span>
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                    {isSummeryLoading ? (
                      <>
                        <CardBody>
                          <Skeleton count={5} />
                        </CardBody>
                      </>
                    ) : null}
                    <CardBody className="trendingBody">
                      <div className="trendingContain">
                        {/* <Row>
                          <Col md={12} lg={12} xl={6}>
                            <Row className="align-items-center px-0 mt-3">
                              <Col sm={2} className="d-none d-md-block">
                                <span className="display-5 text-success d-flex justify-content-center">
                                  <i class="bx bx-trending-up"></i>
                                </span>
                              </Col>
                              <Col>
                                <p className="d-flex align-items-center justify-content-start gap-2 mb-0 trendingTitle">
                                  <span className="title">Revenue</span>
                                  <strong className="fw-medium">24K</strong>
                                </p>
                                <p className="d-flex align-items-center justify-content-start gap-2 trending m-0">
                                  <span className="bg-success p-1 fw-medium text-center">
                                    <i class="mdi mdi-arrow-up-bold"></i> 20%
                                    <span>Increase</span>
                                  </span>
                                  <small>From last month (250K)</small>
                                </p>
                              </Col>
                              <Col>
                                <p className="d-flex align-items-center justify-content-start gap-2 mb-0 trendingTitle">
                                  <span className="title">No.of Orders</span>
                                  <strong className="fw-medium ">240</strong>
                                </p>

                                <p className="d-flex align-items-center justify-content-start gap-2 trending m-0">
                                  <span className="bg-success p-1 fw-medium text-center">
                                    <i class="mdi mdi-arrow-up-bold"></i> 20%
                                    <span>Increase</span>
                                  </span>
                                  <small>From last month (250)</small>
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row> */}

                        {/* <hr className="horizontal" /> */}
                        <Row className="row-cols-2 row-cols-md-6 row-cols-xl-6 m-0">
                          {advData?.map((advItem) => {
                            return (
                              <Col>
                                <Row className="align-items-center">
                                  <Col sm={12} className="adVsOrganic">
                                    <Row className="align-items-center">
                                      <Col>
                                        <p className="mb-1 trendingDeviceTitleLabel">
                                          <span className="title">
                                            {advItem?.type}
                                          </span>
                                        </p>
                                        <h6 className="d-flex align-items-center justify-content-start gap-1  mb-0 trendingDeviceTitle">
                                          <strong className="fw-medium">
                                            {advItem?.total}
                                          </strong>
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Row>
            {cntry === 'all' ? (
              <>
                {' '}
                <Col xl={12}>
                  <Card>
                    <CardHeader className="d-sm-flex align-items-center justify-content-between">
                      <CardTitle>Orders Summary</CardTitle>
                      <Col xl={3}>
                        <div className="InputBg">
                          <i className="bx bx-calendar"></i>
                          <FormGroup>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                defaultValue={`${startDateGraph} to ${endDateGraph}`}
                                options={{
                                  mode: 'range',
                                  dateFormat: 'Y-m-d',
                                }}
                                onChange={(
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) => {
                                  const date_split = dateStr.split('to');
                                  const firstDate = date_split[0];
                                  setStartDateGraph(firstDate);
                                  const secondDate = date_split[1];
                                  setEndDateGraph(secondDate);
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </Col>
                    </CardHeader>
                    {dashboardGraph?.code === '100' ? (
                      <>
                        <CardBody>
                          <div dir="ltr">
                            <Spinearea splineGraph={splineGraph} />
                          </div>
                        </CardBody>
                      </>
                    ) : (
                      <>
                        <CardBody>
                          <Skeleton count={5} />
                        </CardBody>
                      </>
                    )}
                  </Card>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <Card className="testChart">
                    <CardHeader className="d-sm-flex align-items-center justify-content-between">
                      <CardTitle>{contVal} Sales Analysis</CardTitle>
                      <Col xl={2}>
                        <div className="InputBg">
                          <FormGroup
                            onChange={(e) => setSummaryType(e.target.value)}
                          >
                            <Input type="select" name="type">
                              <option value="lastyear">
                                Select Summary Type
                              </option>
                              <option value="lastyear">Last Year</option>
                              <option value="lastmonth">Last Month</option>
                              <option value="lastweek">Last Week</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </Col>
                    </CardHeader>

                    {countStatus?.code === 100 ? (
                      <>
                        {' '}
                        <CardBody>
                          <BarChart
                            TotalCount={TotalCount}
                            summaryType={summaryType}
                          />
                        </CardBody>
                      </>
                    ) : (
                      <>
                        {' '}
                        <CardBody>
                          <Skeleton count={5} />
                        </CardBody>
                      </>
                    )}
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderDashboard;
