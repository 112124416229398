import { useState, useEffect, button } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Spinner,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
//import { getProjecttrackUpdate } from "../../../Redux/bccworder/bccwSlice";
import { fetchProjectTrackUpdate } from '../action/action';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast, { Toaster } from 'react-hot-toast';

function ProjectTrackerDetailForm({ orderData }) {
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [placedNewOrder, setPlacedNewOrder] = useState();
  const [OrderStatus, setOrderStatus] = useState();
  const handleValidSubmit = (events, values) => {
    setUpdateDetailsLoad(true);
    const remarks = values?.remarks ? values?.remarks : '';
    const dateandtime = values?.called ? values?.called : '';
    const status = values?.status ? values?.status : '';
    let Sts = 0;
    if (status === 'New') {
      Sts = 1;
    } else if (status === 'Confirmed') {
      Sts = 2;
    } else if (status === 'Site Visit Scheduled') {
      Sts = 3;
    } else if (status === 'Site Visit Completed') {
      Sts = 4;
    } else if (status === 'Pending Design Payment') {
      Sts = 5;
    } else if (status === 'Received Design Payment') {
      Sts = 6;
    } else if (status === 'Design Started') {
      Sts = 7;
    }
    fetchProjectTrackUpdate({
      params: {
        track_id: id,
        mobile: '',
        updated_by: 'Raja',
        remarks: remarks,
        status: Sts,
        update_data: [
          {
            called_date_time: dateandtime,
          },
        ],
      },
      dispatch,
    });
  };
  const {
    projectUpdate = [],
    isProjectUpdateLoading,
    apiTriggerCount,
  } = useSelector((state) => state.bccw);
  useEffect(() => {
    if (projectUpdate?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(projectUpdate?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (projectUpdate?.message) {
        setUpdateDetailsLoad(false);
        toast.error(projectUpdate?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [projectUpdate]);
  const updateDetail = (countryData) => {
    const LsStatus = countryData?.[0].status;
    let LsStsArr = [];
    if (LsStatus === '1') {
      LsStsArr = ['New', 'Confirmed'];
    } else if (LsStatus === '2') {
      LsStsArr = ['Confirmed', 'Site Visit Scheduled'];
    } else if (LsStatus === '3') {
      LsStsArr = ['Site Visit Scheduled', 'Site Visit Completed'];
    } else if (LsStatus === '4') {
      LsStsArr = ['Site Visit Completed', 'Pending Design Payment'];
    } else if (LsStatus === '5') {
      LsStsArr = ['Pending Design Payment', 'Received Design Payment'];
    } else if (LsStatus === '6') {
      LsStsArr = ['Recived Design Payment', 'Design Started'];
    } else if (LsStatus == '7') {
      LsStsArr = ['Design Started', 'Design Completed'];
    } else if (LsStatus == '8') {
      LsStsArr = ['Design Completed', 'Design Approval'];
    } else if (LsStatus == '9') {
      LsStsArr = ['Design Approval'];
    }

    return (
      <Card className="Order UpdateDetailsForm">
        <CardHeader>
          <CardTitle>UPDATE DETAILS</CardTitle>
        </CardHeader>
        <CardBody>
          <AvForm
            className="needs-validation row gx-3 gy-2 align-items-center"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v);
            }}
          >
            <Col xl={12} md={12}>
              <Row>
                <Col xl={12} md={12}>
                  <AvField
                    type="select"
                    name="status"
                    label="Status"
                    className="form-select"
                    onChange={(e) => setOrderStatus(e.target.value)}
                    required
                  >
                    {LsStsArr?.map((e, inx) => (
                      <option key={inx}>{e}</option>
                    ))}
                  </AvField>
                </Col>
              </Row>
            </Col>
            {OrderStatus === 'Confirmed' ? (
              <>
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Called Date & Time
                  </Label>
                  <Input
                    className="form-control"
                    type="datetime-local"
                    name="called"
                    id="example-date-input"
                  />
                </Col>
              </>
            ) : (
              <></>
            )}
            {OrderStatus === 'Site Visit Scheduled' ? (
              <>
                {' '}
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Site Vist Date & Time
                  </Label>
                  <Input
                    className="form-control"
                    type="datetime-local"
                    name="called"
                    id="example-date-input"
                  />
                </Col>
              </>
            ) : (
              <></>
            )}
            {OrderStatus === 'Site Visit Completed' ? (
              <>
                {' '}
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Site Vist Completed Date & Time
                  </Label>
                  <Input
                    className="form-control"
                    type="datetime-local"
                    name="called"
                    id="example-date-input"
                  />
                </Col>
              </>
            ) : (
              <></>
            )}
            {OrderStatus === 'Pending Design Payment' ? (
              <>
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Amount
                  </Label>
                  <Input
                    className="form-control"
                    type="number"
                    name="amount"
                    id="example-date-input"
                  />
                </Col>
              </>
            ) : (
              <></>
            )}
            {OrderStatus === 'Received Design Payment' ? (
              <>
                <Col xl={12} md={12}>
                  <Label htmlFor="example-date-input" className="form-Label">
                    Received Amount
                  </Label>
                  <Input
                    className="form-control"
                    type="number"
                    name="amount"
                    id="example-date-input"
                  />
                </Col>
                <Col xl={12} md={12}>
                  <AvField
                    type="select"
                    name="status"
                    label="Status"
                    className="form-select"
                    required
                  >
                    <option>Apple Pay </option>
                    <option>Payfort </option>
                    <option>Tamara </option>
                    <option>COD </option>
                    <option>Tap</option>
                    <option>Spotii</option>
                    <option>heckoutcom </option>
                  </AvField>
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col xl={12} md={12}>
              <AvField
                name="remarks"
                label="Remarks"
                value=""
                className="form-control"
                type="textarea"
                required
              />
            </Col>

            <Col xl={12} md={12}>
              <button type="submit" className="btn btn-dark">
                {updateStsLoad === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{' '}
                Submit
              </button>
            </Col>
          </AvForm>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      {updateDetail(orderData)}
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}
export default ProjectTrackerDetailForm;
