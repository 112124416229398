import { useState, useEffect, button, useSearchParams } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { marketPlaceSplitOrderDetail } from './action/action';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker, DatePicker } from 'rsuite';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import MarketplaceDetails from './components/MarketplaceDetail';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import WalletStatus from '../Orders/components/WalletStatus';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { updateOrderStatus } from './../Orders/actions/orderActions';
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';

const MarketSplitDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get('order_mode');
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [apiCounts, setApiCounts] = useState(0);
  const [buttonDetail, setButtonDetail] = useState('');

  const predefinedBottomRanges = [
    {
      label: 'yesterday',
      value: addDays(new Date(), -1),
    },
    {
      label: 'today',
      value: new Date(),
    },
    {
      label: 'Prev Day',
      closeOverlay: false,
      value: (date) => {
        return subDays(date, 1);
      },
    },
  ];

  useEffect(() => {
    marketPlaceSplitOrderDetail({
      params: {
        country: 'AE',
        order_id: id,
        order_mode: order_mode,
      },
      dispatch,
    });
  }, []);
  const {
    marketPlaceDetail = [],
    isDetailLoading,
    apiTriggerCount,
  } = useSelector((state) => state.marketPlace);

  useEffect(() => {
    setApiCounts(apiTriggerCount);
  }, [apiTriggerCount]);

  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  const orderData = marketPlaceDetail.OrderDetails?.[0];
  const itemData = marketPlaceDetail.OrderDetails?.[0]?.orderitems;
  const splitDeviceTrack = orderData?.DeviceTracking?.split('||');
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [dateVal, setDateVal] = useState('');
  const [isDateEmpty, setDateEmpty] = useState(false);
  const [orderValue, setorderValue] = useState('');
  const [OrderStatus, setOrderStatus] = useState();
  const UpdateButton = {
    float: 'right',
  };
  var today = new Date();
  let orderStatusDate =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const [itemsArr, setItemsArr] = useState([]);

  useEffect(() => {
    if (itemData) {
      setItemsArr((current) => [
        ...current,
        ...itemData.map((items) => ({
          sku: items?.Sku,
          quantity: items?.Qty,
          delivery_quantity: '',
          fixing_quantity: '',
          delivery_status_remark: '',
          installation_status: '',
          installation_status_remark: '',
        })),
      ]);
    }
  }, [itemData]);

  const handleValidSubmit = (events, values, delveryDate) => {
    setUpdateDetailsLoad(true);

    const delivery_dt = delveryDate !== '' ? delveryDate : '';
    const status = values?.status ? values?.status : OrderStatus;

    const orderCn = orderData?.ShippingDetails?.[0]?.Country;
    const orderSts = status?.toLowerCase();

    if (dateVal !== '' && dateVal !== null && dateVal !== undefined) {
      orderStatusDate = moment(dateVal).format('YYYY-MM-DD');
    } else {
      setUpdateDetailsLoad(false);
      setDateEmpty(true);
    }
    if (isDateEmpty == false) {
      let stsData = status?.toLowerCase();
      if (status?.toLowerCase() === 'delivery scheduled') {
        stsData = 'delivery_scheduled';
      } else if (status?.toLowerCase() === 'installation completed') {
        stsData = 'delivered';
      } else {
        stsData = '';
      }

      updateOrderStatus({
        params: {
          order_reference: orderData?.OrderNo,
          country: orderCn,
          status: stsData,
          reason: '',
          remarks: '',
          time_slot: '',
          shipment_type: '',
          deliver_by: '',

          delivery_dt: delivery_dt,
          order_mode: 'All',
          user_id: '1',
          type: 'omsUpdate',
          date: orderStatusDate,
          updateMode: order_mode,
          items: itemsArr,
        },
        dispatch,
      });
    }
  };
  const { orderupdateDetails = [], isUpdateLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    if (orderupdateDetails?.code === 100) {
      setUpdateDetailsLoad(false);
      onDrawerClose();

      toast.success(orderupdateDetails?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      window.location.reload();
    } else {
      if (orderupdateDetails?.message) {
        setUpdateDetailsLoad(false);
        toast.error(orderupdateDetails?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [orderupdateDetails]);
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Market Place Split Order View</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="market Place Split Orders"
                breadcrumbItem="Order View"
              />
            </Col>
            <Col xl={6}>
              {orderData?.Status !== 'delivered' ? (
                <Button
                  color="success"
                  className=""
                  onClick={() => {
                    toggleRightDrawer();
                    setButtonDetail('1');
                  }}
                  disabled={open}
                  style={UpdateButton}
                >
                  Update Detail
                </Button>
              ) : (
                ''
              )}
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                  <div className="DashbordFliter ">
                    {splitDeviceTrack !== undefined ? (
                      <Button
                        color="dark"
                        className=""
                        onClick={toggleRightDrawer}
                        disabled={open}
                      >
                        Device Tracking
                      </Button>
                    ) : (
                      ''
                    )}
                    {/* <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <button type="button" className="btn SecondaryBtn2">
                        Send Sms
                      </button>
                      <span>
                        <WalletStatus />
                      </span>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>Update Delivery date</CardTitle>
              </CardHeader>
              <CardBody>
                <AvForm
                  className="needs-validation row gx-3 gy-2 align-items-center"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, dateVal);
                  }}
                >
                  <div className="searchBox">
                    <Row>
                      <Col xl={12} md={12} style={{ paddingBottom: '10px' }}>
                        <AvField
                          name="reference_no"
                          label="Reference No"
                          value={orderData?.OrderNo}
                          className="form-control"
                          type="hidden"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} md={12} style={{ paddingBottom: '10px' }}>
                        {orderData?.Status === 'delivery scheduled' ? (
                          <AvField
                            type="select"
                            name="status"
                            label="Status"
                            className="form-select"
                            onChange={(e) => setOrderStatus(e.target.value)}
                          >
                            <option key={1}>Delivery scheduled</option>
                            <option key={0}>Installation Completed</option>
                          </AvField>
                        ) : (
                          <AvField
                            type="select"
                            name="status"
                            label="Status"
                            className="form-select"
                            onChange={(e) => setOrderStatus(e.target.value)}
                            required
                          >
                            <option>Select</option>
                            <option key={1}>Delivery scheduled</option>
                            <option key={0}>Installation Completed</option>
                          </AvField>
                        )}
                      </Col>
                    </Row>
                    <div
                      className="searchBox"
                      style={{ paddingBottom: '10px' }}
                    >
                      <Row>
                        <Col xl={12} md={12}>
                          Delivery Date
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={12} md={12}>
                          <DatePicker
                            ranges={predefinedBottomRanges}
                            placeholder="YYYY-MM-DD"
                            style={{ width: '100%' }}
                            value={
                              dateVal !== null && dateVal !== ''
                                ? dateVal
                                : null
                            }
                            onChange={setDateVal}
                          />{' '}
                          {isDateEmpty && (
                            <div
                              style={{
                                color: '#f00',
                              }}
                            >
                              Please Select Date.
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <Col xl={12} md={12}>
                      <button type="submit" className="btn btn-dark">
                        {updateStsLoad === true && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{' '}
                        Submit
                      </button>
                    </Col>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </ReactDrawer>
          <MarketplaceDetails orderData={orderData} itemData={itemData} />
        </Container>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default MarketSplitDetail;
