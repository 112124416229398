import React, { useEffect, useState, useRef } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { fetchReturnCheckData, returnImportSummary } from './action/action';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Button,
  Spinner,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { CardHeader } from 'semantic-ui-react';

const ImportReturnOrders = () => {
  const dispatch = useDispatch();
  const title = 'Return';
  const description = 'Marketplace Return Orders';
  // const countryAll = "All";
  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null); // Use ref to clear file input
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [items, setItems] = useState([]);

  let UserID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserID = userObj?.user_id;
    }
  }
  const handleValidSubmit = (events, values) => {
    const form = events.currentTarget;
    const formData = new FormData();
    if (selectedFile === undefined || selectedFile === null) {
      setErrorMsg('Please select the file');
    } else {
      setUpdateDetailsLoad(true);
      formData.append('return_file', selectedFile);

      fetchReturnCheckData({
        formData,
        dispatch,
      });
    }
  };
  const { marketPlaceCheckdata = [], isCkeckdataLoading } = useSelector(
    (state) => state.marketPlace
  );

  useEffect(() => {
    if (marketPlaceCheckdata?.code === 100) {
      setUpdateDetailsLoad(false);

      returnImportSummary({
        params: {
          file_name: marketPlaceCheckdata?.file_name,
          user_id: UserID,
        },
        dispatch,
      });
    } else {
      if (marketPlaceCheckdata?.message) {
        setUpdateDetailsLoad(false);
        toast.error(marketPlaceCheckdata?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [marketPlaceCheckdata]);
  const { importSummary = [], isImportSummaryLoading } = useSelector(
    (state) => state.marketPlace
  );
  useEffect(() => {
    if (importSummary?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(importSummary?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (importSummary?.message) {
        setUpdateDetailsLoad(false);

        toast.error(importSummary?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [importSummary]);
  // Function to clear the selected file, file input, and error message
  const clearFileSelection = () => {
    setSelectedFile(null); // Clear the selected file (content of `file`)
    setErrorMsg(''); // Clear the error message
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input value
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Import Return Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Return" breadcrumbItem="Import Return Orders" />
            </Col>
          </Row>
          <Row className="rowCenter">
            <Col md={12}>
              <Card>
                <CardHeader className="bg-soft-light px-3 py-3 d-flex justify-content-between">
                  <CardTitle>Order Return</CardTitle>
                </CardHeader>

                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  method="post"
                  >
                    <Row>
                      <Col md={12}>
                        <div
                          className="border-2 border-light text-center p-5 rounded position-relative"
                          style={{ borderStyle: 'dashed' }}
                        >
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bx-cloud-upload"></i>
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                          <input
                            type="file"
                            name="file"
                            ref={fileInputRef} // Attach the ref to file input
                            required
                            onChange={(e) => {
                              const file = e.target.files[0];
                              setSelectedFile(file); // Set the selected file
                              if (!file) {
                                setErrorMsg('No file selected');
                              } else {
                                setErrorMsg('');
                              }
                            }}
                            className="opacity-0 w-100 h-100 position-absolute top-0 start-0"
                          />
                        </div>
                        {/* Display the selected file name */}
                        {selectedFile && (
                          <div className="mt-3 text-start d-flex align-items-center justify-content-between gap-2 border border-success rounded bg-soft-success px-3 py-2">
                            <p className="m-0 text-success d-flex align-items-center justify-content-center gap-2">
                              <i className="mdi mdi-microsoft-excel text-success fs-24"></i>
                              <strong>Selected file:</strong>{' '}
                              {selectedFile.name}
                            </p>
                            <i
                              className="mdi mdi-close-circle text-danger fs-24"
                              onClick={clearFileSelection} // Clear file on click
                              style={{ cursor: 'pointer' }}
                            ></i>
                          </div>
                        )}
                        {/* Error message if any */}
                        {errorMsg && (
                          <p className="text-danger mt-4 p-1 border border-danger rounded text-center">
                            {errorMsg}
                          </p>
                        )}
                      </Col>
                      <Col md={12}>
                        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                          <button className="btn btn-dark">
                            {updateStsLoad === true && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ImportReturnOrders;
