import React from 'react';
import axios from 'axios';
import ReturnProgressbar from './Returnprogressbar';
import ReturnMarketDetailsBlock from './ReturnMarketDetailsBlock';
import ReturnNotebyAdmin from './ReturnNoteByAdmin';
import OrderItemDetails from './ReturnMarketItemDetails';
import { useDispatch, useSelector } from 'react-redux';
import ReturnMarketOrderUpdateDetailsForm from './ReturnMarketOrderUpdateDetailsForm';
import { useState, useEffect, button } from 'react';
import { fetchHistoryList } from '../../../Queuess/action/action';
import ReactDrawer from 'react-drawer';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import StatusBarReturnCustomer from '../../../Queuess/components/StatusBarReturnCustomer';

function ReturnMarketOrderDetails({ orderData, itemData }) {
  let stsBtn = 'bg-soft-dark text-dark';
  let LsSts = '';
  if (
    orderData?.Status === 'Pending' ||
    orderData?.Status === 'pending' ||
    orderData?.Status === 'Cancel Pending'
  ) {
    stsBtn = 'bg-soft-warning text-warning';
    LsSts = 'Pending';
  } else {
    LsSts = orderData?.Status;
  }
  if (orderData?.Status === 'Authorized') {
    stsBtn = 'bg-primary';
  } else if (
    orderData?.Status === 'Cancel' ||
    orderData?.Status === 'cancel' ||
    orderData?.Status === 'pending_payment'
  ) {
    stsBtn = 'bg-danger';
  } else if (orderData?.Status === 'Approved') {
    stsBtn = 'bg-success';
  }

  if (
    orderData?.Status === 'Rejected' ||
    orderData?.Status === 'rejected' ||
    orderData?.Status === 'Canceled' ||
    orderData?.Status === 'cancel'
  ) {
    LsSts = 'Canceled';
    stsBtn = 'bg-danger';
  }

  const [skudetail, setSkudetail] = useState('');
  const [orderValue, setorderValue] = useState('');
  const [detail, setDetail] = useState([]);
  const [buttonDetail, setButtonDetail] = useState('');
  const [open, setOpen] = useState(false);

  const toggleRightDrawer3 = async (historyData) => {
    let result = await fetchHistoryList({
      params: {
        queue_id: historyData.queue_id,
      },
    });

    //setOpen(true);
    if (historyData.queue_id !== '') {
      setorderValue(historyData);
      setDetail(result);
      //setCheck(result);
      setButtonDetail('3');
      setOpen(!open);
    }
  };

  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
          <Row>
            <Col className="ReturnInfo">Return Order Details :</Col>
            <Col>
              Ref# <strong>{orderData?.ReturnNo}</strong>
              <span className="highlightInfo1">
                Return Date : {orderData?.ReturnDate}
              </span>
            </Col>
            <Col>
              Order Ref:{' '}
              <Link
                to={`/orders/${
                  orderData?.EntityId
                }/?order_mode=All&orderCn=${orderData?.ShippingDetails?.[0]?.Country.toLowerCase()}`}
                type="button"
                className="refColor"
              >
                {' '}
                <b> {orderData?.OrderId}</b>
              </Link>
              <span className="highlightInfo1">
                Order Date : {orderData?.OrderDate}
              </span>
            </Col>
            <Col>
              Shipment Ref:{' '}
              <Link
                to={`/orders/${
                  orderData?.EntityId
                }/?order_mode=All&orderCn=${orderData?.ShippingDetails?.[0]?.Country.toLowerCase()}`}
                type="button"
                className="refColor"
              >
                {' '}
                <b>{orderData?.ShipmentInfo}</b>
              </Link>
         
              
            </Col>
           
           
          </Row>

          <Row className="mt-3">
            <Col xl={9} md={7}>
              <ReturnMarketDetailsBlock orderData={orderData} />
            </Col>
            <Col xl={3} md={5}>
              {/*  <OrderInvoice orderData={orderData} /> */}
              {/*  <OrderUpdateDetailsForm orderData={orderData} /> */}
              <ReturnMarketOrderUpdateDetailsForm orderData={orderData} />
            </Col>
            <Col>
              {' '}
              <OrderItemDetails itemData={itemData} orderData={orderData} />
            </Col>
          </Row>

          <Row>
            <Col>
              <ReturnNotebyAdmin orderData={orderData} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ReactDrawer open={open}>
        <Card className="card-h-100 OrderDetailsBox">
          <CardHeader>
            <Row>
              <Col className="textAlignRight">
                {' '}
                <button
                  type="button"
                  className="btn-close "
                  aria-label="Close"
                ></button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {buttonDetail === '3' && detail.length > 0 ? (
              <>
                <p>
                  <b>Return status Tracking</b>
                </p>
                <StatusBarReturnCustomer
                  detail={detail}
                  orderValue={orderValue}
                />{' '}
                {/* Add StatusBar component */}
              </>
            ) : (
              ''
            )}
          </CardBody>
        </Card>
      </ReactDrawer>
    </>
  );
}

export default ReturnMarketOrderDetails;
