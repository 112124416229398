import React, { useState, useRef, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  Input,
  Form,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { useMutation, gql } from '@apollo/client';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { UPDATE_ADDRESS_ADMIN } from './query/updateAddress';
import { useDispatch, useSelector } from 'react-redux';
import Geocode from 'react-geocode';
import Select from 'react-select';
import CsLineIcons from '../../cs-line-icons/CsLineIcons';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';
import { GET_ADDRESS_ADMIN } from './query/GetAddress';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import classnames from 'classnames';
const AddCustomerEditForm = ({
  editValue,
  selectedAddressForEdit,
  reLoadAddressList,
}) => {
  let myObjectId = selectedAddressForEdit?._id.toString();

  const containerStyle = {
    width: '100%',
    height: '600px',
  };

  const center = {
    lat: 25.2048,
    lng: 55.2708,
  };
  const [map, setMap] = React.useState(null);
  const [selectedLatLng, setSelectedLatLng] = useState(center);
  const [activeTab, setactiveTab] = useState('1');
  const [activeTab1, setactiveTab1] = useState('5');
  const [selectedPlusCode, setSelectedPlusCode] = useState({
    global_code: selectedAddressForEdit?.plusCode,
  });
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const [selectedGroup, setselectedGroup] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selected, setSelected] = useState(selectedAddressForEdit?.isDefault);
  const [value, setValue] = useState();

  const [selectedAddress, setSelectedAddress] = useState(
    selectedAddressForEdit?.mapData
  );

  const [showMap, setShowMap] = useState(false);

  const [rating, setRating] = useState(0);
  const searchBox = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDbpGEtOjH0Udl-dMEHPYuIl7gXItfWuKU',
    libraries: ['places'],
  });
  Geocode.setApiKey('AIzaSyDbpGEtOjH0Udl-dMEHPYuIl7gXItfWuKU');

  const { id } = useParams();

  const [openMap, setOpenMap] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    selectedAddressForEdit?.country
  );
  const [selectedCity, setSelectedCity] = useState(
    selectedAddressForEdit?.city
  );
  const [selectedRegion, setSelectedRegion] = useState(
    selectedAddressForEdit?.region
  );
  const dispatch = useDispatch();
  const onMapOpen = () => {
    setOpenMap(true);
  };
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
    map.setZoom(14);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onClick = (...args) => {
    setSelectedLatLng({
      lat: args[0].latLng.lat(),
      lng: args[0].latLng.lng(),
    });
    getFormattedAddressAndCode(args[0].latLng.lat(), args[0].latLng.lng());
  };

  const getFormattedAddressAndCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        setSelectedPlusCode(response?.plus_code);
        setSelectedAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
    getCountryCityRegionFromGeoCode(lat, lng);
  };

  const onPlacesChanged = () => {
    searchBox?.current?.state?.searchBox?.getPlaces()?.map((item) => {
      if (item?.plus_code) {
        setSelectedPlusCode(item?.plus_code);
        setSelectedAddress(item?.formatted_address);
      } else {
        getFormattedAddressAndCode(
          item?.geometry?.location?.lat(),
          item?.geometry?.location?.lng()
        );
      }

      setSelectedLatLng({
        lat: item?.geometry?.location.lat(),
        lng: item?.geometry?.location.lng(),
      });
    });
  };

  const getCountryCityRegionFromGeoCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        response?.results?.map((result) => {
          if (result?.types[0] === 'country') {
            setSelectedCountry(result?.address_components[0]?.long_name);
            // return result?.address_components[0].long_name;
          }
          if (result?.types[0] === 'locality') {
            setSelectedRegion(result?.address_components[0]?.long_name);
            // return result?.address_components[0].long_name;
          }
          if (result?.types[0] === 'administrative_area_level_1') {
            setSelectedCity(result?.address_components[0]?.long_name);
            // return result?.address_components[0].long_name;
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const testHandle = (e) => {
    let isValid = false;

    let phLength = e.target.value.length;
    if (phLength >= 8) {
      if (editValue?.country?.[0]?.code === 'ae') {
        var pattern = new RegExp(/^(?:50|51|52|54|55|56|2|3|4|6|7|9)\d{7}$/);
        if (!pattern.test(e.target.value)) {
          isValid = false;

          setErrorMessage('invalid Phone Number');
        }
      } else if (editValue?.country?.[0]?.code === 'bh') {
        pattern = new RegExp(
          /^(?:\+973|00973|0)?(?:30|31|32|33|34|35|36|37|38|39|60|61|62|63|64|65|66|67|68|69)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else if (editValue?.country?.[0]?.code === 'om') {
        pattern = new RegExp(
          /^(?:\+968|00968|0)?(?:71|72|73|74|75|76|77|78|79|91|92|93|94|95|96|97|98|99)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else if (editValue?.country?.[0]?.code === 'kw') {
        pattern = new RegExp(
          /^(?:\+965|00965|0)?(?:21|22|23|24|25|26|27|28|29|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|90|91|92|93|94|95|96|97|98|99)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      } else {
        pattern = new RegExp(
          /^(?:\+974|00974|0)?(?:30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          isValid = false;
          setErrorMessage('invalid Phone Number');
        }
      }
    } else {
      isValid = true;
      setErrorMessage('');
    }
  };
  const [getAddressFunction, { client, Error, data }] =
    useMutation(GET_ADDRESS_ADMIN);

  useEffect(() => {
    try {
      getAddressFunction({
        variables: {
          uuid: id,
          _id: myObjectId,
        },
        dispatch,
      });
    } catch (error) {}
  }, []);

  const addressData = data?.getAddressAdmin?.address?.[0];

  const handleChange = (event) => {
    if (event.target.value === true) {
      setSelected(event.target.value);
    } else {
      setSelected(event.target.value);
    }
  };

  const [updateAddressFunction] = useMutation(UPDATE_ADDRESS_ADMIN);

  const handleUpdateAddress = async (events, values) => {
    try {
      let result = await updateAddressFunction({
        variables: {
          input: {
            uuid: id.toString(),
            _id: myObjectId,
            addressData: {
              countryCode: editValue?.country?.[0]?.code,
              firstName: values.firstname,
              lastName: values.lastname,
              telephone: values.mobileNumber,
              alternateTelephone: values.mobileNumber,
              address1: values.fulladdress,
              address2: values.fulladdress,
              flatNo: values?.flatno,
              city: selectedCity,
              region: selectedRegion,
              country: selectedCountry,
              mapData: selectedAddress,
              latitude: selectedLatLng?.lat.toString(),
              longitude: selectedLatLng?.lng.toString(),
              addressType: values.addressType,
              isDefault: Boolean(selected),
              plusCode: selectedPlusCode?.global_code,
            },
          },
        },
      });
      if (result?.data?.updateAddressAdmin?._id) {
        toast.success('Address Updated Successfully', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#4CAF50',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#4CAF50',
          },
        });
        reLoadAddressList();
      }
    } catch (error) {
      if (error?.message) {
        toast.error(error?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  };

  return (
    <>
      {openMap ? (
        <>
          <div className="position-relative">
            <Card>
              <CardHeader>
                <CardTitle className="mb-0">Edit Shipping Address</CardTitle>
              </CardHeader>
              <CardBody>
                <StandaloneSearchBox
                  onPlacesChanged={onPlacesChanged}
                  ref={searchBox}
                >
                  <>
                    <div className="d-inline-block float-md-start me-1 mb-2 search-input-container w-100 bg-foreground">
                      <input
                        type="text"
                        placeholder="Customized your placeholder"
                        className="form-control"
                        value={selectedAddress}
                        onChange={(event) => {
                          setSelectedAddress(event?.target?.value);
                        }}
                      />
                      <span className="search-magnifier-icon searchIcon">
                        <CsLineIcons icon="search" />
                      </span>
                      <span className="search-delete-icon d-none">
                        <CsLineIcons icon="close" />
                      </span>
                    </div>
                  </>
                </StandaloneSearchBox>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={selectedLatLng}
                  // zoom={12}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  onClick={onClick}
                >
                  <>
                    <Marker
                      icon={
                        'https://assets.danubehome.com/media/email/dhMapMarker.svg'
                      }
                      position={selectedLatLng}
                    />
                  </>
                </GoogleMap>
                <div className="mt-4 d-flex gap-2 justify-content-end">
                  <button
                    className="btn btn-outline-dark waves-effect waves-light mb-3"
                    onClick={() => {
                      setOpenMap(false);
                    }}
                  >
                    Back
                  </button>

                  <button
                    className="btn btn-secondary mb-3"
                    onClick={() => {
                      setOpenMap(false);
                    }}
                  >
                    Confirm & Save Location
                  </button>
                </div>
              </CardBody>
            </Card>
          </div>
        </>
      ) : (
        <>
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              if (!errorMessage) {
                handleUpdateAddress(e, v);
              }
            }}
          >
            <Card>
              <div className="d-flex">
                <div className="w-50">
                  <CardHeader className="bg-soft-light pb-3 mb-3">
                    <CardTitle className="mb-0">
                      Edit Shipping Address
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="d-flex gap-2 pb-3">
                      <input
                        type="text"
                        placeholder="Customized your placeholder"
                        className="form-control"
                        value={selectedAddress}
                      />
                      <button
                        type="button"
                        onClick={onMapOpen}
                        className="btn btn-secondary"
                      >
                        Change
                      </button>
                    </div>
                    <div class="mapSize">
                      <GoogleMap
                        mapContainerStyle={{
                          minHeight: 350,
                          width: '100%',
                          height: '100%',
                        }}
                        center={selectedLatLng}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        <>
                          <Marker
                            position={selectedLatLng}
                            icon={
                              'https://assets.danubehome.com/media/email/dhMapMarker.svg'
                            }
                          />
                        </>
                      </GoogleMap>
                    </div>
                  </CardBody>
                </div>
                <div className="bg-soft-light" style={{ width: 3 }}></div>
                <div className="w-50">
                  <CardHeader className="bg-soft-light pb-3 mb-3">
                    <CardTitle className="mb-0">Your Contact Details</CardTitle>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">First Name</Label>
                          <AvField
                            name="firstname"
                            type="text"
                            value={addressData?.firstName}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">Last Name</Label>
                          <AvField
                            name="lastname"
                            type="text"
                            value={addressData?.lastName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Col className="mb-3">
                      <FormGroup className="mb-12">
                        <Label htmlFor="validationCustom04">Full Address</Label>
                        <AvField
                          name="fulladdress"
                          type="text"
                          placeholder="e.g Apartment 4,Building Name, Street 3"
                          value={addressData?.address1}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col className="mb-3">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">
              Email
            </Label>
            <AvField
              name="email"
              type="email"
              value={addressData?.email}
              required
            />
          </FormGroup>
        </Col> */}
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Flat/Apartment No
                          </Label>
                          <AvField
                            name="flatno"
                            type="text"
                            value={addressData?.flatNo}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="mb-3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Mobile Number
                          </Label>
                          <AvField
                            name="mobileNumber"
                            type="text"
                            value={addressData?.telephone}
                            onBlur={testHandle}
                            onFocus={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="text-danger">{errorMessage}</span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col className="mb-3">
                      <Label>Address Label(Optional)</Label>
                      <AvRadioGroup
                        inline
                        name="addressType"
                        value={addressData?.addressType}
                      >
                        <AvRadio label="Home" value="Home" />
                        <AvRadio label="Work" value="Work" />
                      </AvRadioGroup>
                    </Col>
                    <Col className="mb-3">
                      <div className="form-check form-switch mb-3" dir="ltr">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked={selected}
                          id="customSwitch1"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch1"
                        >
                          Set as default address
                        </label>
                      </div>
                    </Col>

                    <button className="btn btn-secondary" type="submit">
                      Save Address
                    </button>
                  </CardBody>
                </div>
              </div>
            </Card>
          </AvForm>
        </>
      )}

      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};
export default AddCustomerEditForm;
