import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../../../components/Common/Breadcrumb';

import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Button,
  Spinner,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { bulkOrderData, bulkOrderStatus } from '../../actions/orderActions';
import toast, { Toaster } from 'react-hot-toast';

const BulkOrderDetail = () => {
  const dispatch = useDispatch();
  const [updatebulkLoad, setUpdateDetailbulkLoad] = useState(false);
  const { bulkOrder = [], isBulkOrderLoading } = useSelector(
    (state) => state.order
  );
  const bulksData = bulkOrder;
  const bulkDatas = bulksData?.OrderDetails?.[0];
  const ReturnStatus = bulkDatas?.[0]?.status;

  let ReturnStsArr = [];
  if (ReturnStatus === '0') {
    ReturnStsArr = ['Pending', 'Completed'];
  } else {
    ReturnStsArr = ['Completed'];
  }
  const { id } = useParams();

  useEffect(() => {
    bulkOrderData({
      params: {
        bulk_id: id,
      },
      dispatch,
    });
  }, []);
  const handleBulkStatus = (events, values) => {
    setUpdateDetailbulkLoad(true);
    const bulkOrderRemark = values?.remarks ? values?.remarks : '';
    const status = values?.status ? values?.status : '';

    bulkOrderStatus({
      params: {
        bulk_id: id,
        remarks: bulkOrderRemark,
        status: status,
      },
      dispatch,
    });
  };
  const { bulkStatus = [], isBulkStatusLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (bulkStatus?.code === 100) {
      setUpdateDetailbulkLoad(false);
      toast.success(bulkStatus?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (bulkStatus?.message) {
        toast.error(bulkStatus?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [bulkStatus]);

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="flex-row justify-content-center">
          <Col lg={6}>
            <Card>
              <CardBody>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleBulkStatus(e, v);
                  }}
                >
                  <div className="mb-3">
                    <AvField
                      name="username"
                      label="User Name  "
                      placeholder="Type Something"
                      type="text"
                      value={bulkDatas?.[0]?.full_name}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="number"
                      label="Mobile Number  "
                      placeholder="Enter Only number"
                      type="number"
                      value={bulkDatas?.[0]?.mobile_number}
                      disabled
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="E-Mail"
                      placeholder="Enter Valid Email"
                      type="email"
                      value={bulkDatas?.[0]?.email}
                      disabled
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="city"
                      label="City "
                      placeholder="Enter the city"
                      type="text"
                      value={bulkDatas?.[0]?.city}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="customercomments"
                      label="Customer Comments"
                      value={bulkDatas?.[0]?.comments}
                      disabled
                      className="form-control"
                      type="textarea"
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="remarks"
                      label="Remarks"
                      value={bulkDatas?.[0]?.remarks}
                      className="form-control"
                      type="textarea"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      type="select"
                      name="status"
                      label="Status*"
                      className="form-select"
                    >
                      {ReturnStsArr?.map((item, inx) => {
                        return (
                          <option
                            key={inx}
                            value={item === 'Pending' ? '0' : '1'}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <Button type="reset" outline color="danger" className="">
                      Cancel
                    </Button>
                    <Button type="submit" color="success" className="">
                      {updatebulkLoad === true && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default BulkOrderDetail;
