import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  isLoading: true,
  isDetailLoading: true,
  isLatlngLoading: true,
  isRemarksLoading: true,
  isBulkLoading:true,
  isBulkOrderLoading:true,
  isDmsLoading: true,
  isUpdateLoading: true,
  isExportLoading: true,
  isStsCntsLoading : true,
  isBulkStatusLoading:true,
  isreturnStatusLoading:true,
  ischeckPaymentLoading:true,
  isLastCountStatusLoading:true,
  isNonServiceLoading:true,
  isNonServiceViewLoading:true,
  isReturnDetailLoading:true,
  isOrderQaLoading:true,
  apiTriggerCount : 0,
  apiReturnTriggerCount:0,
  returnList:[],
  returnDetails:[],
  shipmentDetail:[],
  createReturn:[],
  isShipmentDetailLoading:true,
  isOrderTriggerLoading:true,
  isOrderReturnDetailLoading:true
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderList(state, actions) {
      state.list = actions.payload;
      state.isLoading = false;
    },
    setInstallationList(state, actions) {
      state.installationlist = actions.payload;
      state.isLoading = false;
    },
    getOrderDetail(state, actions) {
      state.orderDetail = actions.payload;
      state.isDetailLoading = false;
   
    },
    getUpdateCoordinate(state, actions) {
      state.updateLatLng = actions.payload;
      state.isLatlngLoading = false;
      state.apiTriggerCount++
    },
    getRemarks(state, actions) {
      state.getRemarksList = actions.payload;
      state.isRemarksLoading = false;
    },

    getTriggerDmsDate(state, actions) {
      state.dms = actions.payload;
      state.isDmsLoading = false;
      state.apiTriggerCount++
    },
    updateOrderDetails(state, actions) {
      state.orderupdateDetails = actions.payload;
      state.isUpdateLoading = false;
      state.apiTriggerCount++
    },

    excelGenerateFile(state, actions) {
      state.generateFile = actions.payload;
      state.isExportLoading = false;

    },
   
    generateCheckpayment(state, actions) {
      state.checkpayment = actions.payload;
      state.ischeckPaymentLoading = false;
      state.apiTriggerCount++
    },
    createpaymentInvoice(state, actions) {
      state.paymentInvoice = actions.payload;
      state.ischeckpaymentInvoiceLoading = false;
      state.apiTriggerCount++
    },

   
    getStatusCounts(state, actions) {
      state.statusCounts = actions.payload;
      state.isStsCntsLoading = false;
      state.apiTriggerCount++
    },
   
    setReturnOrderList(state, actions) {
      state.returnList = actions.payload;
      state.isLoading = false;
    },
    ReturnOrderDetails(state, actions) {
      state.returnDetails = actions.payload;
      state.isLoading = false;
    },
    ReturnOrderRemarks(state, actions) {
      state.returnRemarks = actions.payload;
      state.isRemarksLoading = false;
      state.apiReturnTriggerCount++
    },
    setBulkOrderList(state, actions) {
      state.bulkOrders = actions.payload;
      state.isBulkLoading = false;
    },
    setBulkDetail(state, actions) {
      state.bulkOrder = actions.payload;
      state.isBulkOrderLoading = false;
    },
    setBulkStatus(state, actions) {
      state.bulkStatus = actions.payload;
      state.isBulkStatusLoading = false;
    },
    setReturnStatusCount(state, actions) {
      state.returnStatus = actions.payload;
      state.isreturnStatusLoading = false;
    },
    setAllOrderCount(state, actions) {
      state.countStatus = actions.payload;
      state.iscountStatusLoading = false;
    },
    setLastOrderCount(state, actions) {
      state.lastCountStatus= actions.payload;
      state.isLastCountStatusLoading = false;
    },
    setsplitorderCount(state, actions) {
      state.splitStatus= actions.payload;
      state.issplitLoading = false;
    },
    setNonServiceArealist(state, actions) {
      state.NonService= actions.payload;
      state.isNonServiceLoading = false;
    },
    setNonServiceAreaView(state, actions) {
      state.NonServiceView= actions.payload;
      state.isNonServiceViewLoading = false;
    },
    
    setNonServiceAreaUpdate(state, actions) {
      state.NonServiceUpdate= actions.payload;
      state.isNonServiceUpdateLoading = false;
    },
    setNonServiceAreaInsert(state, actions) {
      state.NonServiceInsert= actions.payload;
      state.isNonServiceInsertLoading = false;
    },    
    getShipmentDetail(state, actions) {
      state.shipmentDetail = actions.payload;
      state.isShipmentDetailLoading = false;   
    },
    setCreateReturn(state, actions) {
      state.createReturn = actions.payload;
      state.isReturnDetailLoading = false;   
    },
    getOrderDetailReturn(state, actions) {
      state.orderDetailReturn = actions.payload;
      state.isOrderReturnDetailLoading = false;   
    },
    getOrderLogDetail(state, actions) {
      state.orderLogDetail = actions.payload;
      state.isOrderLogLoading = false;   
    },
    getTriggerOrders(state, actions) {
      state.triggerOrder = actions.payload;
      state.isOrderTriggerLoading = false;   
    },
    getChangePaymentmethod(state, actions) {
      state.changepayment = actions.payload;
      state.isPaymentLoading = false;   
    },
    orderNotificationEmail(state, actions) {
      state.emailNotify = actions.payload;
      state.isNotifyLoading = false;   
    },
    getAvilableDays(state, actions) {
      state.avilableDays = actions.payload;
      state.isavilableDaysLoading = false;   
    },
    getAvilableTime(state, actions) {
      state.avilableTime = actions.payload;
      state.isavilableTimeLoading = false;   
    },
    getAvilableSchedule(state, actions) {
      state.avilableSchedule = actions.payload;
      state.isavilableScheduleLoading = false;   
    },
    sendSmSDetail(state, actions) {
      state.sendSms= actions.payload;
      state.isSendSMSLoading = false;   
    },
    OrderDeleteSlice(state, actions) {
      state.orderDelete= actions.payload;
      state.isOrderDeleteLoading = false;   
    },
    OrderDeleteList(state, actions) {
      state.orderDeleteList= actions.payload;
      state.isOrderDeleteListLoading = false;   
    },
    OrderDeleteDetail(state, actions) {
      state.orderDeleteDetail= actions.payload;
      state.isOrderDeleteDetailLoading = false;   
    },
    setQaList(state, actions) {
      state.QaList = actions.payload;
      state.isOrderQaLoading = false;
    },
    setSellerData(state, actions) {
      state.SellerList = actions.payload;
     // state.isOrderQaLoading = false;
    },
  },
});

export const { setOrderList,setInstallationList,getOrderLogDetail,getTriggerOrders,getChangePaymentmethod, getOrderDetail, getUpdateCoordinate,OrderDeleteDetail,OrderDeleteList,setNonServiceAreaView,sendSmSDetail,setNonServiceAreaInsert,setNonServiceAreaUpdate, getRemarks,getTriggerDmsDate, updateOrderDetails, excelGenerateFile, getStatusCounts ,setReturnOrderList,ReturnOrderDetails, ReturnOrderRemarks,setBulkOrderList,setBulkDetail,setBulkStatus, setReturnStatusCount, generateCheckpayment, createpaymentInvoice,setAllOrderCount,setLastOrderCount,setsplitorderCount,setNonServiceArealist,getShipmentDetail,setCreateReturn,getOrderDetailReturn,orderNotificationEmail,getAvilableDays,getAvilableTime,getAvilableSchedule, OrderDeleteSlice, setQaList, setSellerData}= orderSlice.actions;

const orderReducer = orderSlice.reducer;

export default orderReducer;
