import React from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
//import OrderList from './components/orderList';
import OrderList from './components/OrderList';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import OrderExportReport from './components/OrderExportReport';
import { EXPORT_ALLOWED_USER_ROLES } from '../../constants/index';
import { Col, Container, Row, FormGroup, InputGroup } from 'reactstrap';

const b2bOrders = () => {
  const users = JSON.parse(localStorage?.getItem('authUser'));
  const title = 'Orders';
  const description = 'Manage B2B Orders';

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={8}>
              <DashBreadcrumb
                title="Orders"
                breadcrumbItem="Manage B2B Orders"
              />
            </Col>
            {EXPORT_ALLOWED_USER_ROLES.includes(users?.role_id) ? (
              <OrderExportReport />
            ) : (
              ''
            )}
          </Row>

          <OrderList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default b2bOrders;
