import { Button } from 'reactstrap';
import './CustomerDetails.scss';
import React, { useState, useEffect } from 'react';

function CustomerDetailsComponent(props) {
  const {
    address = {},
    setShowmapandAddress,
    showCustomerDetails,
  } = props || {};

  //console.log('cus',address)
  const [addressData, setAddressData] = useState({});
  useEffect(() => {
    setAddressData({
      ...address,
    });
  }, [address]);
  // const EditAddressFinal = () => {
  //   setShowmapandAddress(false);
  // };
  return showCustomerDetails ? (
    <>
      <div className="p-3">
        <h6>Customer Details</h6>
        <div className="lh-base">
          <p className="m-0">
            <strong className="font-size-14">
              {addressData.firstname} {addressData.lastname}
            </strong>
          </p>
          <p className="m-0 font-size-13">{addressData?.mapData}</p>
          <p className="m-0 font-size-13">
            <b>Apartment/Flat No :</b>
            {addressData.fulladdress}
          </p>
          <p className="m-0 font-size-13">
            <b>Email : </b>
            {addressData.email}
          </p>
          <p className="m-0 font-size-13">
            <b>Phone :</b> {addressData.mobile}
            {addressData.alternateTelephone ? (
              <span className="ms-5">
                &nbsp;<b>Alternate TelPhone :</b>{' '}
                {addressData?.alternateTelephone}
              </span>
            ) : (
              ''
            )}
          </p>
          {addressData.companyName ? (
            <>
              <p className="m-0 font-size-13">
                <b>Company Name : </b>
                {addressData?.companyName}
              </p>
              <p className="m-0 font-size-13">
                <b>TRN : </b>
                {addressData?.trn}
              </p>
            </>
          ) : (
            ''
          )}
        </div>
        <Button
          color="light"
          outline
          className=" px-2 py-1 mt-2 fs-11"
          onClick={() => {
            setShowmapandAddress(true);
          }}
        >
          Edit
        </Button>
      </div>
    </>
  ) : (
    ''
  );
}

export default CustomerDetailsComponent;
