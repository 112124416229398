import './PaymentMethods.scss';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setPaymentMethodCart } from '../../actions/action';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import { setPageLoader } from '../../../../../Redux/order/createOrderSlice';
import toast, { Toaster } from 'react-hot-toast';
function PaymentMethodsComponent(props) {
  const dispatch = useDispatch();
  const {
    cart = {},
    showPaymentMethod,
    totalShipingfee,
    showShippingControll,
  } = props || {};
  const { availablePaymentMethods = [] } = cart || {};
  const setPaymentMethod = async (code) => {
    dispatch(setPageLoader(true));
    const response = await setPaymentMethodCart(
      { paymentMethodCode: code },
      dispatch
    );
    if (response) {
      dispatch(setPageLoader(false));
      SuccessToast('Payment method updated');
    } else {
      dispatch(setPageLoader(false));
      ErrorToast('Failed to select payment method');
    }
  };
  const updateCodFee = (event) => {
    localStorage.setItem('is_cod_free', event.target.checked);
    setPaymentMethod(cart.paymentMethod?.code);
  };

  const updateShippingFee = (event) => {
    localStorage.setItem('is_shipping_free', event.target.checked);
    setPaymentMethod(cart.paymentMethod?.code);
  };

  const check_cod_free = localStorage.getItem('is_cod_free');
  const check_shipping_free = localStorage.getItem('is_shipping_free');
  return showPaymentMethod ? (
    <>
      <div className="paymentMethods pt-0 p-3">
        <h6 className="pb-2">Payment Methods</h6>
        <Row>
          {availablePaymentMethods.map((item, index) => {
            return item.code !== 'apple_pay' ? (
              <Col className="col-12" key={index}>
                <div className="paymentCheckBox mb-3">
                  {item.title.code}
                  <input
                    type="radio"
                    name={item.title.en}
                    className="form-check-input me-2"
                    id={item?.code}
                    checked={item?.code === cart.paymentMethod.code}
                    onChange={() => {
                      setPaymentMethod(item.code);
                    }}
                  />
                  <label
                    className="form-check-label font-size-13"
                    htmlFor={item?.code}
                  >
                    {item.title.en}
                    <img
                      className="ms-3"
                      alt={item.logo.src}
                      src={item.logo.src}
                    />
                  </label>
                </div>
                {cart.paymentMethod.code == 'cashondelivery' &&
                item.code == 'cashondelivery' ? (
                  <div className="RemoveCod mb-3" onChange={updateCodFee}>
                    <input
                      type="checkbox"
                      name="removeCod"
                      checked={check_cod_free != 'true' ? false : true}
                      id="removeCod"
                    />
                    <label htmlFor="removeCod">Remove COD Fee </label>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            ) : (
              ''
            );
          })}
          <div>
            {totalShipingfee > 0 && showShippingControll ? (
              <div className="RemoveCod" onChange={updateShippingFee}>
                <input
                  type="checkbox"
                  name="RemoveShipping"
                  checked={check_shipping_free != 'true' ? false : true}
                />
                <label>Remove Shipping Fee </label>
              </div>
            ) : (
              ''
            )}
          </div>
          <span className="font-size-12 alert bg-soft-warning fade show p-2 mb-0">
            The payment link is automatically generated and sent to the customer
          </span>
        </Row>
      </div>

      <Toaster position="top-right" reverseOrder={false} />
    </>
  ) : (
    ''
  );
}

export default PaymentMethodsComponent;
