/* eslint-disable react/jsx-no-target-blank */
// import { iteratorSymbol } from "immer/dist/internal";
import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  CardTitle,
  Button,
  Input,
  FormGroup,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Badge } from 'reactstrap';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import moment from 'moment';
import ShipmentProgressbar from './OrderShipmentProgress';
import { SELLER_DETAILS_ALLOWED_USER_ROLES } from '../../../constants/index';
import OrderUpdateDetailsForm from '../../Orders/components/OrderUpdateDetailsForm';
import { fetchHistoryList } from '../../Queuess/action/action';
import StatusBarCustomer from '../../Queuess/components/StatusBarCustomer';
import {
  fetchShipmentDetail,
  OrderEmailNotification,
  getSellerInfo,
  setUpdateOrderManual,
} from '../actions/orderActions';
import { setUserData } from '../../../Redux/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import './OrderShipmentProgress.scss';
import Iframe from 'react-iframe';
import Reschedule from './Reschedule';
import GiftCardInfo from './GiftCardInfo';
import Tick from '../../../assets/images/tick.svg';
import Cross from '../../../assets/images/cross.svg';
import { uniqBy } from 'lodash';
import { useUrlSearchParams } from 'use-url-search-params';
import 'react-image-lightbox/style.css';
import UserAuthKey from '../../../components/Common/UserAuthKey';
import AskDeliveryHelpfunc from './AskDeliveryHelp/AskDeliveryHelpComponent';
import ViewQuestionDetail from './ViewQuestionDetail/ViewQuestionDetail';
import ExpressDelivery from './Shipping/ExpressDelivery';
import './OrderComponent.scss';
import Select from 'react-select';
const apiUrl = process.env.REACT_APP_OMS_API;
const apiUrl2 = process.env.REACT_APP_MARKETPLACE_ENDPOINT;

function OrderItemDetails({ itemData, orderData, setOrderReload }) {
  const users = JSON.parse(localStorage?.getItem('authUser'));
  const [buttonDetail, setButtonDetail] = useState('');

  const webUrl = process.env.REACT_APP_WEB_URL;
  const [isBbB, setBBorderMode] = useUrlSearchParams({ order_mode: '' });
  const [generateEmailLoad, setgenerateEmailLoad] = useState(false);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [shipmentReference, setShipmentReference] = useState();
  const [isActive, setActive] = useState(false);
  const [isSupplierInfoOpen, setSupplierInfoOpen] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const apiUrl = process.env.REACT_APP_OMS_API;

  const TrackUrl = apiUrl.replace('/api', '');
  const [isDrawer1Open, setDrawer1Open] = useState(false);
  const [skudetail, setSkudetail] = useState('');
  const [orderValue, setorderValue] = useState('');
  const [detail, setDetail] = useState([]);
  const [check, setCheck] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const statusGroup = [
    {
      label: 'Status',
      options: [
        { label: 'Delivered', value: 'delivered' },
        { label: 'Rescheduled', value: 'rescheduled' },
      ],
    },
  ];

  const disabledStatusGroup = [
    'Cancel Pending',
    'Cancel Rejected',
    'cancel_pending',
    'cancel_rejected',
    'canceled',
    'Canceled',
    'delivered',
    'Delivered',
  ];
  const enableStatusGroup = [
    'delivery scheduled',
    'Delivery scheduled',
    'rescheduled',
    'Rescheduled',
    'out_for_delivery',
    'partially delivered',
    'undelivered',
    'ready for delivery',
  ];

  let userID = '';
  let userName = '';
  let userEmail = '';
  let userRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
      userName = userObj?.user_name;
      userEmail = userObj?.user_email;
      userRoleID = userObj?.role_id;
    }
  }
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get('order_mode');

  //fetching all suppliers in the order
  function getAllSuppliersId() {
    const suppliers = uniqBy(
      itemData?.flatMap((items) => items?.ShipmentItems ?? []),
      'SupplierCodeV2'
    ).map((item) => item.SupplierCodeV2);

    //fetching all suppliers in the order from Seller API
    getSellerInfo({
      params: {
        sellerId: suppliers.join(','),
      },
      dispatch,
    });
  }

  function onSupplierInfoOpen(className) {
    setSupplierInfoOpen(className);
  }
  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  const toggleRightDrawer = (ShipmentReference) => {
    setShipmentReference(ShipmentReference);
    setPosition('right');
    setOpen(!open);
    setButtonDetail('5');
  };
  const toggleRightDrawer1 = () => {
    setPosition('right');
    setOpen(!open);
  };

  const toggleRightDrawer3 = async (historyData) => {
    let result = await fetchHistoryList({
      params: {
        queue_id: historyData.queue_id,
      },
    });

    //setOpen(true);
    if (historyData.queue_id !== '') {
      setorderValue(historyData);
      setDetail(result);
      //setCheck(result);
      setButtonDetail('3');

      setOpen(!open);
    }
  };
  useEffect(() => {}, [detail]);
  const onDrawerClose = () => {
    setOpen(false);
  };

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  const dispatch = useDispatch();
  useEffect(() => {
    fetchShipmentDetail({
      params: {
        order_id: shipmentReference,
      },
      dispatch,
    });
  }, [shipmentReference]);

  const { shipmentDetail = [], isShipmentDetailLoading } = useSelector(
    (state) => state.order
  );
  const suplierInfo = orderData?.SupplierShipmentInfo
    ? JSON.parse(orderData.SupplierShipmentInfo)
    : null;
  useEffect(() => {
    const filteredItems = suplierInfo?.filter((item) => {
      return (
        item?.supplierWebId ===
        Number(itemData?.[0]?.ShipmentItems?.[0]?.SupplierId)
      );
    });
    setSupplierId(filteredItems?.[0]?.sellerId);
  }, [itemData]);

  const handleOrderInvoice = async (ref, country) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/orders/invoice?country=${country}&reference_no=${ref}`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios(config);
      if (response?.data?.code === 100) {
        window.location.href = response?.data?.data;
      } else {
        if (response?.data?.message) {
          toast.error(response?.data?.message, {
            style: {
              border: '1px solid #fff',
              padding: '20px',
              color: '#ffffff',
              background: '#f44336',
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#f44336',
            },
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setUserData(null));
        window.location.replace('/logout');
      } else {
      }
    }
  };

  const handlePackingSlipInvoice = async (ref, country) => {
    const bearerToken = UserAuthKey();
    const config = {
      method: 'POST',
      url: `${apiUrl}v1/orders/packingslip?country=${country}&reference_no=${ref}`,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios(config);
      if (response?.data?.code === 100) {
        window.location.href = response?.data?.data;
      } else {
        if (response?.data?.message) {
          toast.error(response?.data?.message, {
            style: {
              border: '1px solid #fff',
              padding: '20px',
              color: '#ffffff',
              background: '#f44336',
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#f44336',
            },
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setUserData(null));
        window.location.replace('/logout');
      } else {
      }
    }
  };
  const handleSubmit = (event) => {
    setgenerateEmailLoad(true);
    OrderEmailNotification({
      params: { reference_no: orderData?.OrderNo },
      dispatch,
    });
    setActive(true);
  };
  const { emailNotify = [], isNotifyLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (emailNotify?.code === 100) {
      setgenerateEmailLoad(false);
      toast.success(emailNotify?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (emailNotify?.message) {
        setgenerateEmailLoad(false);
        toast.error(emailNotify?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [emailNotify]);

  useEffect(() => {
    if (itemData?.length > 0) {
      getAllSuppliersId();
    }
  }, [itemData]);

  const SellerListData = [];
  const { SellerList } = useSelector((state) => state?.order);
  if (SellerList?.length > 0) {
    SellerList?.map((item) => {
      SellerListData[item?.id] = item;
    });
  }

  //  find return data and modify .
  const modifiedObj = {};
  orderData?.returnDetails?.forEach((item) => {
    if (!modifiedObj[item.sku]) {
      modifiedObj[item.sku] = [];
    }
    modifiedObj[item.sku].push({ ...item });
  });

  const handleCheckboxChange = (sku) => {
    setCheckedItems(
      (prev) =>
        prev.includes(sku)
          ? prev.filter((item) => item !== sku) // Uncheck
          : [...prev, sku] // Check
    );
  };

  const updateDeliveryStatusManual = async () => {
    if (!selectedDate) {
      toast.error('Please Select Date', {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#f44336',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#f44336',
        },
      });
      return;
    } else if (!selectedStatus) {
      toast.error('Please Select Status', {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#f44336',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#f44336',
        },
      });
      return;
    }
    const deliveryDate =
      selectedDate && new Date(selectedDate).toISOString().split('T')[0];
    const payload = {
      selectedItems: checkedItems,
      deliveryDate,
      referenceNo: orderData?.OrderNo,
      orderId: orderData?.OrderId,
      id: orderData?.Id,
      lsRef: orderData?.LsReference,
      status: selectedStatus.label,
    };
    try {
      const result = await setUpdateOrderManual(payload);
      if (result.status === 'success') {
        toast.success('Order status updated', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#4CAF50',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#4CAF50',
          },
        });
        setOrderReload(true);
        setCheckedItems([]);
      } else {
        toast.error('we cannot update this time', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    } catch (error) {
      toast.error('we cannot update this time', {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#f44336',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#f44336',
        },
      });
    }
  };

  const handleSelectStatus = (selectedOption) => {
    setSelectedStatus(selectedOption); // Step 3: Update state with the selected option
  };

  const handleMasterCheckboxChange = () => {
    const allEnabledSkus =
      itemData[0]?.ShipmentItems?.filter(
        (item) => !disabledStatusGroup.includes(item.WebStatus)
      ) // Exclude disabled items
        .map((item) => item.Sku) || [];

    if (allChecked) {
      // Uncheck all checkboxes, including disabled ones
      setCheckedItems([]); // Reset checkedItems to an empty array
    } else {
      // Check all enabled checkboxes
      setCheckedItems((prevCheckedItems) => [
        ...prevCheckedItems, // Keep previously checked items (including disabled items)
        ...allEnabledSkus.filter((sku) => !prevCheckedItems.includes(sku)), // Add enabled SKUs that aren't already checked
      ]);
    }

    setAllChecked(!allChecked); // Toggle allChecked state
  };

  // Ensure master checkbox reflects the state of individual checkboxes
  useEffect(() => {
    if (itemData?.length > 0) {
      const allEnabledSkus =
        itemData[0]?.ShipmentItems?.filter(
          (item) => !disabledStatusGroup.includes(item.WebStatus)
        ) // Exclude disabled items
          .map((item) => item.Sku) || [];

      setAllChecked(
        checkedItems.length === allEnabledSkus.length &&
          allEnabledSkus.length > 0
      );
    }
  }, [checkedItems, itemData, disabledStatusGroup]);

  return (
    <>
      {itemData?.[0]?.ItemMod === '2' ? (
        itemData?.[0]?.IsSupplierAccept === '0' ? (
          <div
            className=""
            style={{
              margin: '5px 0px',
              padding: '5px',
              width: '100%',
              color: '#000',
              backgroundColor: '#f3d8d8',
              textAlign: 'center',
            }}
          >
            This order not flown to LS due to supplier approval
          </div>
        ) : null
      ) : null}

      {itemData?.map((item, itemInx) => {
        const filteredItems = itemData.filter(
          (item) => item?.ShipmentReference?.startsWith('BB') === true
        );

        return (
          <div key={item?.ShipmentItems?.[0]?.Id}>
            <ReactDrawer
              open={open}
              position={position}
              onClose={onDrawerClose}
            >
              <Card>
                <div className="scrollShipNav">
                  <CardHeader>
                    <Row>
                      <Col className="textAlignRight1">
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => {
                            onDrawerClose();
                          }}
                          aria-label="Close"
                        ></button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Shipment Details of <b>{shipmentReference}</b>{' '}
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="trackStatus">
                    {shipmentDetail?.ShipmentDetails !== undefined ? (
                      <ShipmentProgressbar
                        ShipmentData={shipmentDetail?.ShipmentDetails}
                      />
                    ) : (
                      shipmentDetail.message
                    )}
                  </div>
                </div>
              </Card>
            </ReactDrawer>

            {item.ShipmentItems !== undefined && item.ShipmentItems !== '' ? (
              <>
                <CardBody className="p-0">
                  <div
                    className="mt-4 p-3 bg-soft-light border-1 border-light"
                    style={{ borderStyle: 'dashed' }}
                  >
                    <Row className="align-items-center">
                      <Col className="col-4">
                        <h5 className="m-0">
                          Shipment {itemInx + 1} of {itemData.length} -{' '}
                          {item.ShipmentReference}
                          {orderData?.ExpressShippingCharge > '0.00' ? (
                            <>
                              <ExpressDelivery
                                shippingAmt={orderData?.ExpressShippingCharge}
                                width={'40px'}
                              />
                            </>
                          ) : null}
                        </h5>

                        {orderData?.devliveryRating.length > 0 ? (
                          <>
                            <Link to="/deliveryreviews">
                              {' '}
                              <span class="highlight deliveryreviewSpan">
                                {' '}
                                Delivery Rating :{' '}
                                {orderData?.devliveryRating?.[0]?.rating}{' '}
                                <span
                                  className="star"
                                  star1
                                  style={{ color: '#FBAB32' }}
                                >
                                  &#9733;
                                </span>
                              </span>
                            </Link>
                          </>
                        ) : null}
                      </Col>

                      {checkedItems.length > 0 ? (
                        <Col>
                          <div className="d-flex justify-content-end gap-2">
                            <Input
                              className="form-control w-25"
                              name="deliverydate"
                              onChange={(event) =>
                                setSelectedDate(event.target.value)
                              }
                              type="date"
                              id="example-datetime-local-input"
                            />
                            <Select
                              value={selectedStatus} // Bind the selected value to the component
                              onChange={handleSelectStatus} // Handle change
                              options={statusGroup} // Options for the select dropdown
                            />
                            <Button
                              onClick={updateDeliveryStatusManual}
                              className="btn btn-primary w-auto px-3 py-2 text-nowrap"
                            >
                              Update Delivery Status
                            </Button>
                          </div>
                        </Col>
                      ) : (
                        <Col className="d-flex justify-content-end gap-2">
                          {orderData?.FinancialStatus === '1' &&
                          orderData?.isEmailSent === '0' &&
                          (orderData?.LsStatus === 'Open' ||
                            orderData?.LsStatus === 'New' ||
                            orderData?.LsStatus !== '' ||
                            orderData?.LsStatus !== '0') ? (
                            <>
                              <Button
                                outline
                                color="success"
                                className="d-flex align-items-center gap-2"
                                onClick={() => handleSubmit()}
                              >
                                {generateEmailLoad === true && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                                {emailNotify?.code === 100 ? (
                                  <>
                                    Email Sent <i className="bx bx-check"></i>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    Email Not Send
                                    <i className="bx bx-mail-send fs-22"></i>
                                  </>
                                )}
                              </Button>
                            </>
                          ) : (
                            <>
                              {' '}
                              {orderData?.isEmailSent === '1' ? (
                                <Button
                                  color="success"
                                  outline
                                  className="d-flex align-items-center gap-2"
                                >
                                  Email Sent
                                  <i className="bx bx-mail-send fs-22"></i>
                                </Button>
                              ) : null}
                            </>
                          )}

                          {isBbB?.order_mode === 'store' ? (
                            <>
                              {orderData?.LsStatus.toLowerCase() !==
                                'canceled' &&
                              orderData?.LsStatus !== 'pending' &&
                              orderData?.LsStatus !== 'New' &&
                              orderData?.LsStatus !== '' &&
                              orderData?.LsStatus !== '0' &&
                              orderData?.LsStatus !== 'Open' ? (
                                <>
                                  <Button
                                    color="success"
                                    outline
                                    className="d-flex align-items-center gap-2"
                                    onClick={() =>
                                      handlePackingSlipInvoice(
                                        item.ShipmentReference,
                                        orderData?.Country
                                      )
                                    }
                                  >
                                    Print Packing Slip{' '}
                                    <i class="mdi mdi-printer  fs-22"></i>
                                  </Button>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {(orderData?.LsStatus === 'Delivered' &&
                            orderData?.PaymentMode === 'cashondelivery') ||
                          (orderData?.PaymentMode !== 'cashondelivery' &&
                            orderData?.LsStatus.toLowerCase() !== 'canceled' &&
                            orderData?.LsStatus !== 'pending' &&
                            orderData?.LsStatus !== 'New' &&
                            orderData?.LsStatus !== '' &&
                            orderData?.LsStatus !== '0' &&
                            orderData?.LsStatus !== 'Open' &&
                            orderData?.LsStatus !== 'Confirmed') ? (
                            <>
                              <Button
                                outline
                                color="success"
                                className="d-flex align-items-center gap-2"
                                onClick={() =>
                                  handleOrderInvoice(
                                    item.ShipmentReference,
                                    orderData?.Country
                                  )
                                }
                              >
                                Download Invoice{' '}
                                <i class="bx bxs-file-pdf fs-22"></i>
                              </Button>
                            </>
                          ) : null}
                          {orderData?.DeliverBy === '3PL' ? (
                            <div className="btnTracking mb-2 mr-2">
                              <a
                                href={`https://www.emiratespost.ae/all-services/track-a-package/step-two?q=${
                                  orderData?.Awb_number ||
                                  filteredItems?.[0]?.ShipmentItems?.[0]
                                    ?.AwbNumber
                                }`}
                                target="_blank"
                              >
                                Track Shipment{' '}
                              </a>
                            </div>
                          ) : orderData?.DeliverBy === 'IMILE' ||
                            orderData?.DeliverBy === 'TFM' ||
                            orderData?.DeliverBy === 'TFM - Bulky' ? (
                            <div className="btnTracking mb-2 mr-2">
                              <a
                                href={`${TrackUrl}getImileOrders?awb=${
                                  orderData?.Awb_number ||
                                  filteredItems?.[0]?.ShipmentItems?.[0]
                                    ?.AwbNumber
                                }`}
                                target="_blank"
                              >
                                Track Shipment{' '}
                              </a>
                            </div>
                          ) : (
                            <Button
                              outline
                              color="success"
                              className="d-flex align-items-center gap-2"
                              onClick={() =>
                                toggleRightDrawer(item?.ShipmentReference)
                              }
                            >
                              Track Shipment{' '}
                              <i class="bx bxs-file-pdf fs-22"></i>
                            </Button>
                          )}
                          {/* <AskDeliveryHelpfunc shipment={item?.ShipmentReference} /> */}

                          <Reschedule orderData={orderData} />
                          <GiftCardInfo orderData={orderData} />
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className="table-responsive  dt-table">
                    <Table className=" table  mb-0 ">
                      <thead>
                        <tr>
                          <th>
                            {(userRoleID === '1' || userRoleID === '39') &&
                            order_mode === 'split' &&
                            enableStatusGroup.includes(orderData?.Status) ? (
                              <input
                                type="checkbox"
                                className="form-check-input border mr-2"
                                id="masterCheckbox"
                                checked={allChecked}
                                onChange={handleMasterCheckboxChange}
                              />
                            ) : (
                              ''
                            )}
                            Details
                          </th>
                          <th>Qty</th>
                          <th>Original Price</th>
                          <th>Now Price</th>
                          <th>Discount</th>
                          <th>Total</th>
                        </tr>
                      </thead>

                      {item.ShipmentItems?.map((item, itemInx1) => {
                        const returnData = modifiedObj[item?.Sku];
                        let stsBtn = 'bg-soft-dark text-dark';
                        let stsSuppBtn = 'bg-soft-dark text-dark';
                        let lsStatus = '';
                        let LsStatusOption = item?.LsStatus?.toLowerCase();

                        if (item?.LsStatus === '0' || item?.LsStatus === '') {
                          if (item?.LsStatus === 'Canceled') {
                            stsBtn = 'bg-danger';
                            stsBtn = 'bg-soft-danger text-danger';
                          } else if (LsStatusOption === 'pending_payment') {
                            lsStatus = 'Pending Payment';
                            stsBtn = 'bg-soft-danger text-danger';
                          } else if (LsStatusOption === 'pending') {
                            lsStatus = 'Pending';
                            stsBtn = 'bg-soft-warning text-warning';
                          } else {
                            lsStatus = 'New';
                            stsBtn = 'bg-soft-info text-info';
                          }
                        } else {
                          lsStatus = item?.LsStatus;
                        }

                        if (
                          item?.LsStatus === 'open' ||
                          item?.LsStatus === ''
                        ) {
                          stsBtn = 'bg-soft-dark';
                          lsStatus = 'Open';
                        } else if (
                          item?.LsStatus === 'canceled' ||
                          item?.LsStatus === 'Canceled' ||
                          item?.LsStatus === 'cancel' ||
                          item?.LsStatus === 'Cancel' ||
                          LsStatusOption === 'pending_payment'
                        ) {
                          lsStatus = 'Canceled';
                          stsBtn = 'bg-soft-danger text-danger';
                        } else if (LsStatusOption === 'posted') {
                          stsBtn = 'bg-soft-info text-dark';
                          lsStatus = 'Posted';
                        } else if (LsStatusOption === 'confirmed') {
                          stsBtn = 'bg-soft-dark text-dark';
                          lsStatus = 'Confirmed';
                        } else if (
                          LsStatusOption === 'delivered' ||
                          LsStatusOption === 'completed'
                        ) {
                          stsBtn = 'bg-soft-success text-dark';
                          lsStatus = 'Delivered';
                        } else if (LsStatusOption === 'delivery_scheduled') {
                          stsBtn = 'bg-soft-success text-dark';
                          lsStatus = 'Delivery Scheduled';
                        } else if (LsStatusOption === 'picked') {
                          stsBtn = 'bg-soft-success text-dark';
                          lsStatus = 'Picked';
                        } else if (
                          LsStatusOption === 'Material N/A' ||
                          LsStatusOption === 'material n/a'
                        ) {
                          stsBtn = 'bg-soft-warning text-dark';
                          lsStatus = 'Material N/A';
                        } else if (LsStatusOption === 'out_for_delivery') {
                          stsBtn = 'bg-soft-success text-dark';
                          lsStatus = 'Out For Delivery';
                        } else if (LsStatusOption === 'rescheduled') {
                          stsBtn = 'bg-soft-success text-dark';
                          lsStatus = 'Rescheduled';
                        } else {
                          lsStatus = item?.LsStatus;
                        }

                        const SupplierStatus = item?.IsSupplierAccept;
                        let supplierState = '';
                        if (SupplierStatus === '0') {
                          supplierState = 'Pending';
                          stsSuppBtn = 'bg-soft-dark text-dark';
                        } else if (SupplierStatus === '1') {
                          supplierState = 'Accepted';
                          stsSuppBtn = 'bg-success';
                        } else {
                          supplierState = 'Rejected';
                          stsSuppBtn = 'bg-soft-danger text-danger';
                        }
                        let cdate = item?.PostedDate,
                          orderDt = moment(new Date(cdate)).format(
                            'Do MMM, YYYY'
                          ),
                          orderTime = moment(new Date(cdate)).format(
                            'h:mm:ss a'
                          );

                        const uomValue = item?.customizeInfo?.uom || '';
                        const [firstUnit, secondUnit] =
                          uomValue.split('-') || [];

                        return (
                          <>
                            <tbody key={item?.Sku}>
                              <tr>
                                <td className="table-bordered">
                                  <div className="d-flex align-items-center gap-2">
                                    {(userRoleID === '1' ||
                                      userRoleID === '39') &&
                                    order_mode === 'split' &&
                                    enableStatusGroup.includes(
                                      orderData?.Status
                                    ) ? (
                                      <input
                                        type="checkbox"
                                        className="form-check-input border"
                                        id={`itemsTodeliveredSku-${item.Sku}`}
                                        value={item.Sku}
                                        checked={checkedItems.includes(
                                          item.Sku
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(item.Sku)
                                        }
                                        disabled={disabledStatusGroup.includes(
                                          item.WebStatus
                                        )} // Disable checkbox based on status
                                      />
                                    ) : (
                                      ''
                                    )}

                                    <div className="productImage">
                                      <Link
                                        to={{
                                          pathname: `${webUrl}${orderData?.Country}/en/p/from-oms-preview-${item?.Sku}`,
                                        }}
                                        target="_blank"
                                      >
                                        <img
                                          alt=""
                                          width="100"
                                          height="100"
                                          className="img-thumbnail"
                                          src={item?.Image}
                                        />
                                      </Link>
                                    </div>

                                    <div className="d-flex flex-column align-items-start w-75">
                                      <p className="productName m-0">
                                        <strong>{item?.Name}</strong>
                                      </p>
                                      {item?.customizeInfo ? (
                                        <p className="productSkuLink m-0">
                                          Customization Info :
                                          {item?.customizeInfo?.area > 0 ? (
                                            <b>
                                              Area :
                                              {Number(
                                                item?.customizeInfo?.area
                                              ).toFixed(2)}{' '}
                                              {secondUnit}
                                            </b>
                                          ) : null}
                                          {item?.customizeInfo?.length ? (
                                            <b>
                                              , Length :
                                              {item?.customizeInfo?.length}
                                              {firstUnit}{' '}
                                            </b>
                                          ) : null}
                                          {item?.customizeInfo?.width ? (
                                            <b>
                                              , Width :
                                              {item?.customizeInfo?.width}{' '}
                                              {firstUnit}
                                            </b>
                                          ) : null}
                                          <b>
                                            , UOM : {item?.customizeInfo?.uom}
                                          </b>
                                        </p>
                                      ) : null}

                                      <p className="productSkuLink m-0">
                                        Sku : {item?.Sku}
                                        <Link
                                          to={{
                                            pathname: `${webUrl}${orderData?.Country}/en/p/from-oms-preview-${item?.Sku}`,
                                          }}
                                          target="_blank"
                                          relative="path"
                                          className="productSkuWebLink"
                                        >
                                          <i className="bx bx-link-external"></i>
                                        </Link>
                                      </p>
                                      {item?.DeliveryDate !== null ||
                                      item?.DeliveryDate !== '' ? (
                                        <p className="m-0">
                                          <span>
                                            {' '}
                                            {item?.Sku == '990700100004'
                                              ? 'Installation Date'
                                              : 'Delivery Date'}
                                            : <b>{item?.DeliveryDate}</b>
                                          </span>
                                          {orderData?.DeliveryTimeSlot &&
                                          item?.Sku !== '990700100004' ? (
                                            <span className="slotDetailBx">
                                              {' '}
                                              Slot :{' '}
                                              <b>
                                                {orderData?.DeliveryTimeSlot}
                                              </b>
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </p>
                                      ) : null}

                                      {item?.DeliveryDate === null &&
                                      item?.Sku !== '990700100004' ? (
                                        <p className="m-0">
                                          <span>
                                            {' '}
                                            Expected Delivery:{' '}
                                            <b>{item?.DeliverySla}</b>{' '}
                                          </span>
                                          {orderData?.DeliveryTimeSlot ? (
                                            <span className="slotDetailBx">
                                              {' '}
                                              Slot :{' '}
                                              <b>
                                                {orderData?.DeliveryTimeSlot}
                                              </b>
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </p>
                                      ) : null}

                                      <p className="m-0">
                                        Status :{' '}
                                        <Badge className={` ${stsBtn} me-3`}>
                                          {' '}
                                          {lsStatus}
                                        </Badge>
                                      </p>
                                      {lsStatus === 'Posted' &&
                                      item?.PostedDate !== '' ? (
                                        <p className="postInfo">
                                          on {orderDt} {orderTime}
                                        </p>
                                      ) : (
                                        ''
                                      )}

                                      <p className="m-0">
                                        LS Ref : <b>{item?.LsOrderRef}</b>
                                        {item?.QueueId !== '' ? (
                                          <i
                                            title="Track Cancel Request"
                                            class="mdi mdi-eight-track btnTracking"
                                            onClick={() => {
                                              toggleRightDrawer3({
                                                reference_no:
                                                  orderData?.OrderNo,
                                                date: '2024-06-24',
                                                created_by: 'customer',
                                                queue_id: item?.QueueId,
                                                resolution_type:
                                                  item?.QueueResolution,
                                              });
                                            }}
                                            style={{
                                              marginLeft: '15px',
                                            }}
                                          ></i>
                                        ) : (
                                          ''
                                        )}
                                      </p>

                                      {item?.ItemMod === '2' ? (
                                        <>
                                          <p className="m-0">
                                            Supplier Status :
                                            <Badge
                                              className={` ${stsBtn} me-3`}
                                            >
                                              {' '}
                                              {supplierState}
                                            </Badge>
                                          </p>
                                          <p className="m-0">
                                            Supplier Name :{' '}
                                            <strong>
                                              {item?.SupplierName}
                                            </strong>{' '}
                                            <span
                                              className="infoIcon"
                                              key={itemInx}
                                              onClick={() => {
                                                onSupplierInfoOpen(
                                                  `${itemInx + 1}${itemInx1}`
                                                );
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 20.302 20.306"
                                              >
                                                <defs>
                                                  <clipPath id="clipPath">
                                                    <rect
                                                      id="Rectangle_1663"
                                                      data-name="Rectangle 1663"
                                                      width="20.302"
                                                      height="20.306"
                                                      fill="none"
                                                    />
                                                  </clipPath>
                                                </defs>
                                                <g
                                                  id="Group_173138"
                                                  data-name="Group 173138"
                                                  clipPath="url(#clipPath)"
                                                >
                                                  <path
                                                    id="Path_85319"
                                                    data-name="Path 85319"
                                                    d="M10.86,20.307H9.431c-.123-.017-.246-.035-.369-.049a9.781,9.781,0,0,1-4.856-1.872A9.966,9.966,0,0,1,.047,9.068,9.252,9.252,0,0,1,1.9,4.227,9.971,9.971,0,0,1,11.222.047,9.44,9.44,0,0,1,16,1.845a10.01,10.01,0,0,1,4.147,6.534c.064.349.1.7.156,1.055v1.429c-.031.234-.058.469-.092.7a10.177,10.177,0,0,1-8.258,8.58c-.362.067-.728.108-1.092.161m7.856-10.162a8.57,8.57,0,1,0-8.578,8.575,8.581,8.581,0,0,0,8.578-8.575"
                                                    transform="translate(0 -0.001)"
                                                    fill="#1a1818"
                                                  />
                                                  <path
                                                    id="Path_85320"
                                                    data-name="Path 85320"
                                                    d="M178.045,163.49q0,1.249,0,2.5a.794.794,0,1,1-1.585.007q0-2.509,0-5.017a.794.794,0,1,1,1.585-.007q0,1.259,0,2.519"
                                                    transform="translate(-167.107 -151.641)"
                                                    fill="#1a1818"
                                                  />
                                                  <path
                                                    id="Path_85321"
                                                    data-name="Path 85321"
                                                    d="M173.368,102.6a1.07,1.07,0,1,1-2.141,0,1.07,1.07,0,0,1,2.141,0"
                                                    transform="translate(-162.152 -96.156)"
                                                    fill="#1a1818"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </p>
                                          {SELLER_DETAILS_ALLOWED_USER_ROLES.includes(
                                            users?.role_id
                                          ) ? (
                                            <div
                                              className={
                                                isSupplierInfoOpen ===
                                                `${itemInx + 1}${itemInx1}`
                                                  ? 'supplier-info active'
                                                  : 'supplier-info'
                                              }
                                            >
                                              <span>
                                                Contact No :{' '}
                                                {
                                                  SellerListData[
                                                    item?.SupplierCodeV2
                                                  ]?.phone
                                                }
                                              </span>
                                              <span>
                                                Email Id :{' '}
                                                {
                                                  SellerListData[
                                                    item?.SupplierCodeV2
                                                  ]?.email
                                                }
                                              </span>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                          <p className="m-0">
                                            LPO Number :{' '}
                                            <a
                                              href={`https://seller.danubehome.com/uploads/lslpo/${item?.PoNumber}.pdf`}
                                              target="_blank"
                                            >
                                              {item?.PoNumber}
                                            </a>
                                          </p>
                                          {item?.AwbNumber ? (
                                            <>
                                              <p className="m-0">
                                                AWB :
                                                <a
                                                  href={`${TrackUrl}getImileOrders?awb=${item?.AwbNumber}`}
                                                  target="_blank"
                                                >
                                                  {item?.AwbNumber}
                                                </a>
                                              </p>
                                            </>
                                          ) : null}
                                        </>
                                      ) : null}

                                      {item?.isCancelEligible === true ? (
                                        <Button
                                          color="danger"
                                          outline
                                          type="button"
                                          onClick={() => {
                                            toggleRightDrawer1();
                                            setSkudetail(item?.ProductId);
                                            localStorage.setItem(
                                              'skuItem',
                                              item?.ProductId
                                            );
                                            setButtonDetail('4');
                                          }}
                                          className=" mb-2 mt-2 py-1 border-1 fs-12"
                                        >
                                          Cancel
                                        </Button>
                                      ) : (
                                        item?.isCancelEligible
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="table-bordered"
                                  style={{ textAlign: 'center' }}
                                  nowrap="nowrap"
                                >
                                  <p>
                                    {item?.Qty}
                                    {item?.customizeInfo ? (
                                      <>
                                        (
                                        {Number(
                                          item?.customizeInfo?.area
                                        ).toFixed(2)}{' '}
                                        {secondUnit})
                                      </>
                                    ) : null}
                                  </p>
                                </td>
                                <td
                                  className="table-bordered"
                                  style={{ textAlign: 'center' }}
                                  nowrap="nowrap"
                                >
                                  {item?.Currency}{' '}
                                  {Number(item?.WasPrice).toFixed(2)}
                                </td>
                                <td
                                  className="table-bordered"
                                  style={{ textAlign: 'center' }}
                                  nowrap="nowrap"
                                >
                                  {item?.Currency} {item?.NowPrice}
                                </td>
                                <td
                                  className="table-bordered"
                                  style={{ textAlign: 'center' }}
                                  nowrap="nowrap"
                                >
                                  {item?.Currency}{' '}
                                  {item?.Discount === ''
                                    ? '0.00'
                                    : item?.Discount}
                                </td>
                                <td
                                  className="table-bordered"
                                  style={{ textAlign: 'center' }}
                                  nowrap="nowrap"
                                >
                                  {item?.Currency}{' '}
                                  {Number(item?.TotalPrice).toFixed(2)}
                                </td>
                              </tr>
                              {returnData?.length &&
                                returnData?.map((returnItem) => {
                                  const imgUrl = JSON.parse(returnItem?.imgUrl);
                                  return (
                                    <tr className="returnRow">
                                      <td>
                                        <div className="productInfo">
                                          <div>
                                            <p>
                                              <b>Return</b>
                                            </p>
                                            <p>
                                              Ref # :{' '}
                                              <a
                                                target="_blank"
                                                href={`/returnorders/${returnItem?.return_id}/${orderData?.OrderNo}`}
                                              >
                                                {returnItem?.return_no}
                                              </a>
                                            </p>
                                            {imgUrl[0] ? (
                                              <img
                                                src={imgUrl[0]}
                                                alt={item?.Name}
                                              />
                                            ) : null}
                                          </div>
                                          <div>
                                            <p>
                                              Return Date:{' '}
                                              <b>{returnItem?.created_at}</b>
                                            </p>
                                            <p>
                                              Status:{' '}
                                              <b>{returnItem?.return_status}</b>
                                            </p>
                                            <p>
                                              Reason:{' '}
                                              <b>{returnItem?.return_reason}</b>
                                            </p>
                                            <p>
                                              Item Condition:{' '}
                                              <b>
                                                {returnItem?.item_condition}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{returnItem?.returnQty}</td>
                                      <td>
                                        {item.Currency} {item?.WasPrice}
                                      </td>
                                      <td>
                                        -{item.Currency} {item?.NowPrice}
                                      </td>
                                      <td>
                                        {item.Currency} {item?.Discount}
                                      </td>
                                      <td>
                                        -{item.Currency}{' '}
                                        {(item?.TotalPrice / item?.Qty) *
                                          returnItem?.returnQty}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </>
                        );
                      })}
                    </Table>
                    <ReactDrawer
                      open={open}
                      position={position}
                      onClose={onDrawerClose}
                    >
                      <Card className="card-h-100 OrderDetailsBox">
                        <CardHeader>
                          <Row>
                            <Col>
                              <CardTitle>
                                {buttonDetail === '4' ? (
                                  <>Update Detail</>
                                ) : (
                                  <></>
                                )}
                              </CardTitle>
                            </Col>
                            <Col className="textAlignRight">
                              {' '}
                              <button
                                type="button"
                                className="btn-close "
                                onClick={() => {
                                  onDrawerClose();
                                }}
                                aria-label="Close"
                              ></button>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {buttonDetail === '4' ? (
                            <>
                              <OrderUpdateDetailsForm
                                orderData={orderData}
                                onDrawerClose={onDrawerClose}
                                setOrderReload={setOrderReload}
                              />
                            </>
                          ) : (
                            ''
                          )}
                          {buttonDetail === '3' && detail.length > 0 ? (
                            <>
                              <p>
                                <b>Status History</b>
                              </p>
                              <StatusBarCustomer
                                detail={detail}
                                orderValue={orderValue}
                              />{' '}
                              {/* Add StatusBar component */}
                            </>
                          ) : (
                            ''
                          )}
                          {buttonDetail === '5' ? (
                            <>
                              <Row>
                                <Col>
                                  Shipment Details of <b>{shipmentReference}</b>{' '}
                                </Col>
                              </Row>
                              <div className="trackStatus">
                                {shipmentDetail?.ShipmentDetails !==
                                undefined ? (
                                  <ShipmentProgressbar
                                    ShipmentData={
                                      shipmentDetail?.ShipmentDetails
                                    }
                                  />
                                ) : (
                                  shipmentDetail.message
                                )}
                              </div>{' '}
                            </>
                          ) : (
                            ''
                          )}
                        </CardBody>
                      </Card>
                    </ReactDrawer>
                  </div>
                </CardBody>

                {isBbB?.order_mode === 'b2b' && supplierId !== undefined ? (
                  <div className="comentsIframe">
                    <h4>Seller Hub Support </h4>
                    <iframe
                      src={`${apiUrl2}/support-ticket-widget/${item?.ShipmentReference}/Order/standalone/${userName}/${userEmail}/${supplierId}`}
                    ></iframe>
                  </div>
                ) : null}
                <Toaster position="top-right" reverseOrder={false} />
              </>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={6} />
                </CardBody>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}
export default OrderItemDetails;
