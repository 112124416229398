import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MetaTags } from 'react-meta-tags';
import { DashBreadcrumb } from '../../../components/Common/Breadcrumb';
//../../../components/Common/Breadcrumb
import { Col, Container, Row, Button, CardBody, Card } from 'reactstrap';
import { setPageLoader } from '../../../Redux/order/createOrderSlice';
import { setCartDetails } from '../../../Redux/order/cartSlice';
import AddToCart from './components/AddToCart/AddToCart.component';
import AddPromotions from './components/AddPromotions/AddPromotions.component';
import Assembly from './components/Assembly/Assembly.component';
import CustomerOrderingMode from './components/CustomerOrderingMode/CustomerOrderingMode.component';
import Comments from './components/Comments/Comments.component';
import CustomerDetails from './components/CustomerDetails/CustomerDetails.component';
import PaymentMethods from './components/PaymentMethods/PaymentMethods.component';
import InvoiceBreakup from './components/InvoiceBreakup/InvoiceBreakup.component';
import AddAddress from './components/AddAddress/AddAddress.component';
import DeliverySlotSelection from './components/DeliverySlotSelection/DeliverySlotSelection.component';
import LoadingScreen from './components/Loader/loadingScreen';
import { viewCart, removeCartToken } from './actions/action';
import { PlaceOrder } from './actions/orderaction';
import { lowerCase } from 'lodash';
import { SuccessToast, ErrorToast } from '../../../utils/Toaster';
import { useHistory } from 'react-router-dom';
import { osName } from 'react-device-detect';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
const { OMS_PUBLIC_APP_VERSION, OMS_PUBLIC_APP_BRANCH } = process.env || {};

const CreateOrder = () => {
  const dispatch = useDispatch();
  const title = 'Create Order';
  const [address, setAddress] = useState(null);
  const [showmapandAddress, setShowmapandAddress] = useState(false);
  const [showcart, setShowcart] = useState(false);
  const [showpromotion, setShowpromotion] = useState(false);
  const [showOrderingMode, setShowOrderingMode] = useState(false);
  const [showAssembly, setShowAssembly] = useState(false);
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [showDeliverySlotSelection, setShowDeliverySlotSelection] =
    useState(false);
  const [showInvoiceBreakup, setShowInvoiceBreakup] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [totalAssemblyCharge, setTotalAssemblyCharge] = useState(null);
  const [deviceTracking, setDeviceTracking] = useState('');
  const [orderType, setOrderType] = useState(localStorage.getItem('orderType'));
  const [comment, setComment] = useState('');
  const [emailNotification, setEmailNotification] = useState(false);
  const [totalShipingfee, setTotalShipingfee] = useState(0);
  const [showDeliverySlots, setShowDeliverySlots] = useState(false);
  const [showShippingControll, setShowShippingControll] = useState(true);
  const history = useHistory();
  const { cartDetails: cart = {} } = useSelector((state) => state.createorder);
  useEffect(() => {
    const viewCartDetails = async () => {
      await viewCart({}, dispatch);
    };
    viewCartDetails();
  }, [cart?.grandTotal]);

  useEffect(() => {
    if (Object.keys(cart).length) {
      if (cart.country !== lowerCase(localStorage.getItem('currentCountry'))) {
        removeCartToken({}, dispatch);
      }
    }

    const addressData = localStorage.getItem('guest_address');
    if (!addressData) {
      setShowcart(true);
    } else {
      setShowcart(true);
      setShowOrderingMode(true);
      setShowpromotion(true);
      setShowAssembly(true);
      setShowCustomerDetails(true);
      setShowPaymentMethod(true);
      setShowDeliverySlotSelection(true);
      setShowInvoiceBreakup(true);
      setShowComment(true);
    }
  }, []);

  useEffect(() => {
    const deviceTrackingInfo = `paymentMethod:${
      cart?.paymentMethod?.code
    }||country:${cart?.countryInfo?.code}||language:${
      cart?.language
    }||shippingMethod:${
      cart?.deliveryMethod?.code
    }||OS:${osName}||platformVersion:||appVersion:${OMS_PUBLIC_APP_VERSION}||appBranch:${OMS_PUBLIC_APP_BRANCH}||device:Desktop||comment:${comment}||isEmailnotification:${emailNotification.toString()}`;

    setDeviceTracking(deviceTrackingInfo);
  }, [cart]);

  const submitOrder = async () => {
    try {
      dispatch(setPageLoader(true));
      const checkOut = await PlaceOrder(
        { additionalInfo: { deviceTracking } },
        dispatch
      );
      if (checkOut) {
        clearDataOnPlaceOrder();
        dispatch(setPageLoader(false));
        SuccessToast('Order placed');
        history.push('/orders');
      } else {
        dispatch(setPageLoader(false));
        ErrorToast('Order error');
      }
    } catch (error) {
      console.error('Error placing order:', error);
      // Handle the error gracefully
    }
  };

  const changeOrderType = (value) => {
    localStorage.setItem('orderType', value);
    setOrderType(localStorage.getItem('orderType'));
  };
  const clearDataOnPlaceOrder = () => {
    localStorage.removeItem('guest_address');
    localStorage.removeItem('orderType');
    localStorage.removeItem('is_cod_free');
    localStorage.removeItem('is_shipping_free');
    localStorage.removeItem('is_remove_promo_prices');
    dispatch(setCartDetails({}));
    removeCartToken({}, dispatch);
  };
  const clearCart = () => {
    localStorage.removeItem('guest_address');
    localStorage.removeItem('orderType');
    localStorage.removeItem('is_cod_free');
    localStorage.removeItem('is_shipping_free');
    localStorage.removeItem('is_remove_promo_prices');
    removeCartToken({}, dispatch);
    setAddress(localStorage.getItem('guest_address'));
    dispatch(setCartDetails({}));
    hideComponents();
  };
  const hideComponents = () => {
    setShowcart(true);
    setShowOrderingMode(false);
    setShowpromotion(false);
    setShowAssembly(false);
    setShowCustomerDetails(false);
    setShowPaymentMethod(false);
    setShowDeliverySlotSelection(false);
    setShowInvoiceBreakup(false);
    setShowComment(false);
  };
  /**
   *
   */
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create New Order</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Orders"
                breadcrumbItem="Create New Order"
              />
            </Col>
          </Row>
          <LoadingScreen />
          <AddToCart
            cart={cart}
            showcart={showcart}
            setShowmapandAddress={setShowmapandAddress}
            setShowShippingControll={setShowShippingControll}
          />
          <AddAddress
            showmapandAddress={showmapandAddress}
            setShowmapandAddress={setShowmapandAddress}
            setAddress={setAddress}
            setShowcart={setShowcart}
            setShowOrderingMode={setShowOrderingMode}
            setShowpromotion={setShowpromotion}
            setShowAssembly={setShowAssembly}
            setShowCustomerDetails={setShowCustomerDetails}
            setShowPaymentMethod={setShowPaymentMethod}
            setShowDeliverySlotSelection={setShowDeliverySlotSelection}
            setShowInvoiceBreakup={setShowInvoiceBreakup}
            setShowComment={setShowComment}
          />
          {cart?.shippingAddress ? (
            <Row>
              <Col md={8}>
                <AddPromotions cart={cart} showpromotion={showpromotion} />
                <Card>
                  <CardBody className="p-0">
                    <CustomerOrderingMode
                      showOrderingMode={showOrderingMode}
                      changeOrderType={changeOrderType}
                    />

                    {totalAssemblyCharge > 0 ? (
                      <>
                        <hr
                          style={{ height: 3, background: 'rgb(222 222 223)' }}
                        />
                        <Assembly
                          showAssembly={showAssembly}
                          cart={cart}
                          totalAssemblyCharge={totalAssemblyCharge}
                          setTotalAssemblyCharge={setTotalAssemblyCharge}
                        />
                      </>
                    ) : null}

                    <hr style={{ height: 3, background: 'rgb(222 222 223)' }} />
                    {cart?.shippingAddress?.latitude &&
                      showDeliverySlotSelection && (
                        <div className="DeliverySlotSelection d-flex justify-content-between px-3 py-0">
                          <div>
                            <h6>Delivery Slot Selection</h6>
                            <span>
                              {cart?.shipment?.items.length === 0
                                ? 'Please select a date & time fro your delivery'
                                : 'Change delivery slot'}
                            </span>

                            {cart?.shipment?.items &&
                              cart?.shipment?.items?.map(
                                ({ selected, provider }, key) => {
                                  if (!selected) return;
                                  return (
                                    <div className="mb-2">
                                      <span
                                        style={{ fontWeight: 'bold' }}
                                        className="mt-1 me-2"
                                      >
                                        Shipment {key + 1} : {provider} -{' '}
                                        {selected?.date} at{' '}
                                        {selected?.timeLabel}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <div>
                            <span
                              onClick={() =>
                                setShowDeliverySlots(!showDeliverySlots)
                              }
                              className="btn viewDeliverySlots"
                            >
                              View Delivery Slots
                            </span>
                            <ReactDrawer
                              open={showDeliverySlots}
                              onClose={() => setShowDeliverySlots(false)}
                              position="right"
                            >
                              <DeliverySlotSelection
                                showDeliverySlots={showDeliverySlots}
                                setShowDeliverySlots={setShowDeliverySlots}
                                cart={cart}
                              />
                            </ReactDrawer>
                          </div>
                        </div>
                      )}
                    <hr style={{ height: 3, background: 'rgb(222 222 223)' }} />
                    <PaymentMethods
                      cart={cart}
                      showPaymentMethod={showPaymentMethod}
                      totalShipingfee={totalShipingfee}
                      showShippingControll={showShippingControll}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <CardBody className="p-0">
                    <CustomerDetails
                      setShowmapandAddress={setShowmapandAddress}
                      address={address}
                      showCustomerDetails={showCustomerDetails}
                    />
                  </CardBody>
                  <InvoiceBreakup
                    cart={cart}
                    showInvoiceBreakup={showInvoiceBreakup}
                    totalAssemblyCharge={totalAssemblyCharge}
                    setTotalShipingfee={setTotalShipingfee}
                  />
                  <Comments
                    showComment={showComment}
                    setComment={setComment}
                    emailNotification={emailNotification}
                    setEmailNotification={setEmailNotification}
                  />
                </Card>
                {Object.keys(cart).length > 0 ? (
                  <Row>
                    <Col className="d-flex gap-2">
                      <Button
                        color="light"
                        cclassName="fs-13"
                        onClick={() => clearCart()}
                      >
                        Clear
                      </Button>
                      <Button
                        type="submit"
                        color={
                          !cart.items ||
                          !Object.keys(cart?.items).length > 0 ||
                          cart?.paymentMethod?.code === '' ||
                          cart?.shippingAddress === null ||
                          orderType === null
                            ? 'light' // Change color to light when disabled
                            : 'success' // Success color when enabled
                        }
                        className="fs-13 "
                        disabled={
                          !cart.items ||
                          !Object.keys(cart?.items).length > 0 ||
                          cart?.paymentMethod?.code === '' ||
                          cart?.shippingAddress === null ||
                          orderType === null
                        }
                        onClick={submitOrder}
                      >
                        Submit Order
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateOrder;
