import React, { useEffect, useState, useRef } from 'react';
import { MetaTags } from 'react-meta-tags';
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { lsStockSkuInsert } from './action/action';
import { CardHeader } from 'semantic-ui-react';
import toast, { Toaster } from 'react-hot-toast';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Button,
  Spinner,
} from 'reactstrap';

const ImportExport = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null); // Use ref to clear file input
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isTypeError, setisTypeError] = useState(false);
  const [excelImport, setexcelImport] = useState(false);
  const handleImportTypeChange = (e) => isTypeError && setisTypeError(null);
  const handleValidSubmit = (events, values) => {
    const form = events.currentTarget;

    const formData = new FormData();
    if (values?.importType === '') {
      setisTypeError(true);
      setErrorMsg('Please select the import type');
    } else if (selectedFile === null) {
      setisTypeError(false);
      setErrorMsg('Please select the file');
    } else {
      setUpdateDetailsLoad(true);
      formData.append('import_file', selectedFile);

      lsStockSkuInsert({
        formData,
        dispatch,
      });
    }
  };

  const { importExportList = [], isLoading } = useSelector(
    (state) => state.importexport
  );

  console.log('importExportList-------', importExportList);
  useEffect(() => {
    if (importExportList?.code === 100) {
      setUpdateDetailsLoad(false);
      toast.success(importExportList?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (importExportList?.message) {
        setUpdateDetailsLoad(false);

        toast.error(importExportList?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [importExportList]);
  // Function to clear the selected file, file input, and error message
  const clearFileSelection = () => {
    setSelectedFile(null); // Clear the selected file (content of `file`)

    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input value
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Import Export</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Import/Export"
                breadcrumbItem="Manage Import/Export"
              />
            </Col>
          </Row>
          <Row className="rowCenter">
            <Col md={12}>
              <Card>
                <CardHeader className="bg-soft-light px-3 py-3 d-flex justify-content-between">
                  <CardTitle>Import/Export File</CardTitle>
                </CardHeader>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md={12} className="mb-4">
                        <AvField
                          type="select"
                          name="importType"
                          className="form-select"
                          onChange={handleImportTypeChange} // Trigger the function on value change
                        >
                          <option value="">Select Import</option>
                          <option value="lsstock">LsStock</option>
                        </AvField>
                        {isTypeError && (
                          <div className="importError">{errorMsg}</div>
                        )}
                      </Col>
                      <Col md={12} className="mb-4">
                        <div
                          className="border-2 border-light text-center p-5 rounded position-relative"
                          style={{ borderStyle: 'dashed' }}
                        >
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bx-cloud-upload"></i>
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                          <input
                            type="file"
                            name="file"
                            required
                            ref={fileInputRef} // Attach the ref to file input
                            onChange={(e) => {
                              setSelectedFile(e.target.files[0]);
                              setErrorMsg('');
                            }}
                            className="opacity-0 w-100 h-100 position-absolute top-0 start-0"
                          />
                        </div>
                        {/* Display the selected file name */}
                        {selectedFile && (
                          <div className="mt-3 text-start d-flex align-items-center justify-content-between gap-2 border border-success rounded bg-soft-success px-3 py-2">
                            <p className="m-0 text-success d-flex align-items-center justify-content-center gap-2">
                              <i className="mdi mdi-microsoft-excel text-success fs-24"></i>
                              <strong>Selected file:</strong>
                              {selectedFile.name}
                            </p>
                            <i
                              className="mdi mdi-close-circle text-danger fs-24"
                              onClick={clearFileSelection} // Clear file on click
                              style={{ cursor: 'pointer' }}
                            ></i>
                          </div>
                        )}
                        {/* Error message if any */}
                        {errorMsg && (
                          <p className="text-danger mt-4 p-1 border border-danger rounded text-center">
                            {errorMsg}
                          </p>
                        )}
                      </Col>
                      <Col md={12} className="mb-4">
                        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                          <Button color="dark" size="lg">
                            {updateStsLoad === true && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{' '}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ImportExport;
