import './AddAddress.scss';
import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import gmapStyle from '../../gmapStyle.json';
import Geocode from 'react-geocode';
import { modify } from '../../../../../helpers/ShippingAddress';
import {
  Col,
  Row,
  FormGroup,
  Label,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setShippingAddress, setEmailToGuestCart } from '../../actions/action';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import toast, { Toaster } from 'react-hot-toast';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { lowerCase } from 'lodash';
import { Card } from 'react-bootstrap';
const center = {
  ae: {
    lat: 25.1675739,
    lng: 55.2614555,
  },
  qa: {
    lat: 25.2842534,
    lng: 51.3719073,
  },
  om: {
    lat: 23.5880307,
    lng: 58.3828717,
  },
  kw: {
    lat: 29.3760606,
    lng: 47.9468472,
  },
  bh: {
    lat: 26.2213902,
    lng: 50.5848642,
  },
};

const contryBounds = {
  ae: {
    north: 26.427929393569457,
    south: 22.427935149720497,
    west: 49.88720042141598,
    east: 57.8381892803542,
  },
  qa: {
    north: 26.2171,
    south: 24.471118,
    west: 50.7211001,
    east: 51.7144001,
  },
  om: {
    north: 28.808004285620157,
    south: 12.427888171440916,
    west: 38.38409066937567,
    east: 70.18813730023504,
  },
  kw: {
    north: 33.15088596365788,
    south: 25.497326905942987,
    west: 39.665945329232116,
    east: 55.5679686446618,
  },
  bh: {
    north: 26.653167758085463,
    south: 25.667091017781722,
    west: 49.63994697023152,
    east: 51.627694184966074,
  },
};
function AddAddressComponent(props) {
  const {
    setAddress,
    showmapandAddress,
    setShowmapandAddress,
    setShowcart,
    setShowOrderingMode,
    setShowpromotion,
    setShowAssembly,
    setShowCustomerDetails,
    setShowPaymentMethod,
    setShowDeliverySlotSelection,
    setShowInvoiceBreakup,
    setShowComment,
  } = props || {};

  const [country, setCountry] = useState();
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [isMapFirstLoad, setIsMapFirstLoad] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isScrollToMarker, setIsScrollToMarker] = useState(false);
  const [selectedAddressErrorMsg, setSelectedAddressErrorMsg] = useState(null);
  const [selectedPlusCode, setSelectedPlusCode] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(country);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isFromSearch, setIsFromSearch] = useState(false);
  const [isEnableMap, setIsEnableMap] = useState(true);
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const [alternatephoneError, setAlternatephoneError] = useState(null);
  const [selectedAddressError, setSelectedAddressError] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);

  const dispatch = useDispatch();
  const [addressData, setAddressData] = useState({
    firstname: '',
    lastname: '',
    fulladdress: '',
    email: '',
    mobile: '',
    companyName: '',
    trn: '',
    alteratemobile: '',
    isusedbillingaddress: false,
  });
  const searchBox = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDbpGEtOjH0Udl-dMEHPYuIl7gXItfWuKU',
    libraries: ['drawing', 'places', 'marker'],
  });
  const google = window.google;

  Geocode.setApiKey('AIzaSyDbpGEtOjH0Udl-dMEHPYuIl7gXItfWuKU');

  const onLoad = useCallback(function callback(map) {
    setIsMapFirstLoad(false);
  }, []);

  const onUnmount = useCallback(function callback(map) {}, []);

  const onMapClick = (...args) => {
    setSelectedLatLng({
      lat: args?.[0]?.latLng?.lat(),
      lng: args?.[0]?.latLng?.lng(),
    });
    setSelectedAddressErrorMsg(null);
    getFormattedAddressAndCode(
      args?.[0]?.latLng?.lat(),
      args?.[0]?.latLng?.lng()
    );
    getCountryCityRegionFromGeoCode(
      args?.[0]?.latLng?.lat(),
      args?.[0]?.latLng?.lng()
    );
  };
  const getFormattedAddressAndCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        setSelectedPlusCode(response?.plus_code);
        setSelectedAddress(response?.results?.[0]?.formatted_address);
        setSearchValue(response?.results?.[0]?.formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getCountryCityRegionFromGeoCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        response?.results?.reverse()?.map((result) => {
          if (result?.types?.includes('country')) {
            setSelectedCountry(result?.address_components?.[0]?.long_name);
            setSelectedCountryCode(result?.address_components?.[0]?.short_name);
          }
          if (
            result?.types?.includes('sublocality') ||
            result?.types?.includes('locality') ||
            result?.types?.includes('administrative_area_level_1')
          ) {
            setSelectedCity(result?.address_components?.[0]?.long_name);
          }
          if (
            result?.types?.includes('locality') ||
            result?.types?.includes('administrative_area_level_1')
          ) {
            setSelectedRegion(result?.address_components?.[0]?.long_name);
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const onPlacesChanged = () => {
    setIsFromSearch(true);
    searchBox?.current?.state?.searchBox?.getPlaces()?.map((item) => {
      if (item?.plus_code) {
        setSelectedPlusCode(item?.plus_code);
        setSelectedAddress(item?.formatted_address);
        setSearchValue(item?.formatted_address);
      } else {
        getFormattedAddressAndCode(
          item?.geometry?.location?.lat(),
          item?.geometry?.location?.lng()
        );
      }
      getCountryCityRegionFromGeoCode(
        item?.geometry?.location?.lat(),
        item?.geometry?.location?.lng()
      );
      setSelectedLatLng({
        lat: item?.geometry?.location?.lat(),
        lng: item?.geometry?.location?.lng(),
      });
    });
  };

  useEffect(() => {
    if (isFromSearch) {
      setIsScrollToMarker(true);
      setIsFromSearch(false);
    } else {
      setIsScrollToMarker(false);
    }
  }, [selectedLatLng, isFromSearch]);

  useEffect(() => {
    const savedAddress = JSON.parse(localStorage.getItem('guest_address'));
    if (savedAddress) {
      setAddressData({
        firstname: savedAddress.firstname,
        lastname: savedAddress.lastname,
        fulladdress: savedAddress.fulladdress,
        email: savedAddress.email,
        mobile: savedAddress.mobile,
        companyName: savedAddress.companyName,
        trn: savedAddress.trn,
        alternateTelephone: savedAddress.alternateTelephone,
      });
      setShowCompanyInfo(savedAddress.isUsedBilling);
      setIsAddressEdit(true);
      setIsEnableMap(false);
      getFormattedAddressAndCode(savedAddress.latitude, savedAddress.longitude);
      setAddress(savedAddress);
    }
    const currentCountry = lowerCase(localStorage.getItem('currentCountry'));
    setCountry(currentCountry);
    setSelectedLatLng(center?.[country]);
  }, []);

  const validateDataFromMap = async () => {
    if (selectedCountryCode) {
      if (selectedCountryCode?.toLowerCase() === country) {
        setSelectedAddressError(false);
        setSelectedAddressErrorMsg(null);
        setIsEnableMap(false);
        setIsAddressEdit(true);
      } else {
        setSelectedAddressError(true);
        setSelectedAddressErrorMsg('choose Location Inside Country');
      }
    } else {
      setSelectedAddressError(true);
      setSelectedAddressErrorMsg('Please select location');
    }
  };

  const PhoneNumberHandle = (e, fieldName) => {
    let phLength = e.target.value.length;
    if (phLength >= 7) {
      setIsValid(true);
      setAlternatephoneError(null);
      setPhoneError(null);
      let message = 'Please use valid phone No. without country code';
      if (selectedCountryCode === 'AE') {
        var pattern = new RegExp(
          /^(?:50|51|52|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/
        );
        if (!pattern.test(e.target.value)) {
          setIsValid(false);
          if (fieldName == 'phone') {
            setPhoneError(message);
          } else {
            setAlternatephoneError(message);
          }
        }
      } else if (selectedCountryCode === 'BH') {
        pattern = new RegExp(
          /^(?:\+973|00973|0)?(?:30|31|32|33|34|35|36|37|38|39|60|61|62|63|64|65|66|67|68|69)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          setIsValid(false);
          if (fieldName == 'phone') {
            setPhoneError(message);
          } else {
            setAlternatephoneError(message);
          }
        }
      } else if (selectedCountryCode === 'OM') {
        pattern = new RegExp(
          /^(?:\+968|00968|0)?(?:71|72|73|74|75|76|77|78|79|91|92|93|94|95|96|97|98|99)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          setIsValid(false);
          if (fieldName == 'phone') {
            setPhoneError(message);
          } else {
            setAlternatephoneError(message);
          }
        }
      } else if (selectedCountryCode === 'KW') {
        pattern = new RegExp(
          /^(?:\+965|00965|0)?(?:21|22|23|24|25|26|27|28|29|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|90|91|92|93|94|95|96|97|98|99)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          setIsValid(false);
          if (fieldName == 'phone') {
            setPhoneError(message);
          } else {
            setAlternatephoneError(message);
          }
        }
      } else {
        pattern = new RegExp(
          /^(?:\+974|00974|0)?(?:30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79)\d{6}$/
        );
        if (!pattern.test(e.target.value)) {
          setIsValid(false);
          if (fieldName == 'phone') {
            setPhoneError(message);
          } else {
            setAlternatephoneError(message);
          }
        }
      }
    } else {
      setIsValid(false);
      if (fieldName == 'phone') {
        setPhoneError('Mobile number min 7 digits');
      } else {
        setAlternatephoneError('Mobile number min 7 digits');
      }
    }
  };

  const handleSubmitSaveAddress = async (event, values) => {
    event.preventDefault();
    if (isValid) {
      const saveAddress = {
        _id: 'guest',
        firstname: values.firstname,
        lastname: values.lastname,
        fulladdress: values.fulladdress,
        email: values.email,
        mobile: values.mobile,
        alternateTelephone: values.alternateTelephone,
        countryCode: selectedCountryCode,
        city: selectedCity,
        country: selectedCountry,
        region: selectedRegion,
        mapData: selectedAddress,
        latitude: `${selectedLatLng?.lat}`,
        longitude: `${selectedLatLng?.lng}`,
        addressType: 'Home',
        isDefault: true,
        plusCode:
          selectedPlusCode?.compound_code || selectedPlusCode?.global_code,
        flatNo: 'NA',
        companyName: values.companyName,
        trn: values.trn,
        isUsedBilling: showCompanyInfo,
      };
      setAddress(saveAddress);
      setAddressData(saveAddress);
      localStorage.setItem('guest_address', JSON.stringify(saveAddress));
      setShowmapandAddress(false);
      setShowcart(true);
      setShowOrderingMode(true);
      setShowpromotion(true);
      setShowAssembly(true);
      setShowCustomerDetails(true);
      setShowPaymentMethod(true);
      setShowDeliverySlotSelection(true);
      setShowInvoiceBreakup(true);
      setShowComment(true);
      const modifiedAddress = await modify(saveAddress);
      const ShippingAddressUpdated = await setShippingAddress(
        { address: modifiedAddress },
        dispatch
      );
      if (ShippingAddressUpdated) {
        const setEmail = setEmailToGuestCart(values.email, dispatch);
        SuccessToast('Address Updated');
      } else {
        ErrorToast('Address update error');
      }
    }
  };

  const selectCompanyInfoForBilling = (e) => {
    setShowCompanyInfo(e.target.checked);
  };

  return (
    <>
      {showmapandAddress ? (
        <>
          <Card>
            <CardHeader className="bg-light">
              <h6>Fill Customer Shipping Address</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  {isEnableMap && (
                    <div className="addNewLocationMap">
                      <div className="addNewLocationGoogleMap position-relative">
                        <div className="LocationGoogleMap">
                          {isLoaded && (
                            <div className="addNewLocationGoogleMap">
                              <span
                                className="close"
                                onClick={() => {
                                  setSearchValue('');
                                }}
                                style={{ top: 8, right: 8 }}
                              ></span>

                              <GoogleMap
                                mapContainerStyle={{
                                  width: '100%',
                                  height: '500px',
                                }}
                                zoom={13}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                styles={gmapStyle}
                                options={{
                                  styles: gmapStyle,
                                  draggable: true,
                                  streetViewControl: false,
                                  fullscreenControl: false,
                                  mapTypeControl: false,

                                  restriction: {
                                    latLngBounds: contryBounds[country],
                                    strictBounds: true,
                                  },
                                }}
                                center={
                                  isMapFirstLoad
                                    ? center?.[country]
                                    : isScrollToMarker && selectedLatLng
                                }
                                onClick={onMapClick}
                              >
                                <>
                                  <Marker
                                    position={selectedLatLng}
                                    icon={{
                                      url: 'https://assets.danubehome.com/media/email/dhMapMarker.svg',
                                      anchor: new google.maps.Point(17, 46),
                                      scaledSize: new google.maps.Size(37, 37),
                                    }}
                                    animation="DROP"
                                  />

                                  <StandaloneSearchBox
                                    onPlacesChanged={onPlacesChanged}
                                    ref={searchBox}
                                    bounds={contryBounds[country]}
                                  >
                                    <>
                                      <input
                                        type="text"
                                        className="inputStyle"
                                        value={searchValue}
                                        onChange={(event) => {
                                          setSearchValue(event?.target?.value);
                                        }}
                                      />
                                    </>
                                  </StandaloneSearchBox>
                                </>
                              </GoogleMap>
                            </div>
                          )}
                        </div>
                      </div>
                      {selectedAddressError ? (
                        <div className="text-danger d-flex justify-content-end">
                          {selectedAddressErrorMsg}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="mapErrorSaveContainer">
                        <div
                          className="d-flex justify-content-end buttonContainSave"
                          onClick={() => {
                            validateDataFromMap();
                          }}
                        >
                          <Button
                            color="success"
                            type="submit"
                            className="mt-3"
                          >
                            Update & Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              {isAddressEdit && (
                <Row>
                  <Col md={6}>
                    <div className="position-relative rounded overflow-hidden h-100">
                      <div
                        className="d-flex justify-content-between align-items-center position-absolute bg-light px-2 py-2 w-100"
                        style={{ zIndex: 9 }}
                      >
                        <div className="fs-12 fw-medium text-dark">
                          {selectedAddress}
                        </div>
                        <Button
                          outline
                          color="dark"
                          className="fs-11 px-2 py-1"
                          onClick={() => {
                            setIsEnableMap(true);
                            setIsAddressEdit(false);
                            setIsScrollToMarker(true);
                          }}
                        >
                          Change
                        </Button>
                      </div>
                      {isLoaded && (
                        <GoogleMap
                          mapContainerStyle={{
                            minHeight: 250,
                            width: '100%',
                            height: '100%',
                          }}
                          className="gMap h-100"
                          center={selectedLatLng}
                          zoom={15}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          styles={gmapStyle}
                          icon={{
                            url: 'https://assets.danubehome.com/media/email/dhMapMarker.svg',
                            anchor: new google.maps.Point(17, 46),
                            scaledSize: new google.maps.Size(37, 37),
                          }}
                          animation="DROP"
                          options={{
                            styles: gmapStyle,
                            draggable: false,
                            streetViewControl: false,
                            fullscreenControl: false,
                          }}
                        >
                          <>
                            <Marker position={selectedLatLng} />
                          </>
                        </GoogleMap>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="addNewAddressForm">
                      <AvForm onValidSubmit={handleSubmitSaveAddress}>
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="validationCustom04"
                                className="fs-12"
                              >
                                First Name
                              </Label>
                              <AvField
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please Fill First name.',
                                  },
                                }}
                                name="firstname"
                                type="text"
                                value={addressData.firstname}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="validationCustom04"
                                className="fs-12"
                              >
                                Last Name
                              </Label>
                              <AvField
                                name="lastname"
                                type="text"
                                value={addressData.lastname}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="validationCustom04"
                                className="fs-12"
                              >
                                Apt/Flat No, Building Name
                              </Label>
                              <AvField
                                name="fulladdress"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please Fill valid address .',
                                  },
                                }}
                                type="text"
                                placeholder="e.g Apartment 4,Building Name, Street 3"
                                className="form-control"
                                value={addressData.fulladdress}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="validationCustom04"
                                className="fs-12"
                              >
                                Email
                              </Label>
                              <AvField
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please Fill Valid email.',
                                  },
                                }}
                                name="email"
                                type="email"
                                value={addressData.email}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="validationCustom04"
                                className="fs-12"
                              >
                                Mobile Number
                              </Label>
                              <AvField
                                name="mobile"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please Fill valid mobile number .',
                                  },
                                }}
                                value={addressData.mobile}
                                onChange={(e) => PhoneNumberHandle(e, 'phone')}
                              />
                              <span className="text-danger">{phoneError}</span>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="validationCustom04"
                                className="fs-12"
                              >
                                Alternate Phone
                              </Label>
                              <AvField
                                name="alternateTelephone"
                                type="text"
                                value={addressData.alternateTelephone}
                                onChange={(e) =>
                                  PhoneNumberHandle(e, 'alternatephone')
                                }
                              />
                              <span className="text-danger">
                                {alternatephoneError}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <AvCheckboxGroup name="isusedbillingaddress">
                                <AvCheckbox
                                  label="Company info for Billing"
                                  checked={showCompanyInfo}
                                  onChange={selectCompanyInfoForBilling}
                                />
                              </AvCheckboxGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {showCompanyInfo ? (
                          <Row>
                            <Col>
                              <FormGroup className="mb-3">
                                <Label
                                  htmlFor="validationCustom04"
                                  className="fs-12"
                                >
                                  Company name
                                </Label>
                                <AvField
                                  name="companyName"
                                  type="text"
                                  value={addressData.companyName}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="mb-3">
                                <Label
                                  htmlFor="validationCustom04"
                                  className="fs-12"
                                >
                                  TRN No.
                                </Label>
                                <AvField
                                  name="trn"
                                  type="text"
                                  value={addressData.trn}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}
                        <div className="d-flex justify-content-end gap-2">
                          <Button
                            color="light"
                            className="fs-13"
                            onClick={() => {
                              setIsEnableMap(true);
                              setIsAddressEdit(false);
                              setIsScrollToMarker(true);
                            }}
                          >
                            Back
                          </Button>

                          <Button
                            color="secondary"
                            className="fs-13"
                            type="submit"
                          >
                            Continue
                          </Button>
                        </div>
                      </AvForm>
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </>
      ) : (
        ''
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}

export default AddAddressComponent;
