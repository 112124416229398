import { useState, useEffect, button, useSearchParams } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { fetchPhotoShootDetail } from './actions/photoshootAction';
import PhotoShootOrderDetails from './ManageProductShootdetail';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import WalletStatus from '../Orders/components/WalletStatus';
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const PhotoDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchPhotoShootDetail({
      params: {
        photoshoot_id: id,
      },
      dispatch,
    });
  }, []);
  const { PhotoShootDetails = [], isDetailLoading } = useSelector(
    (state) => state.photoshoot
  );
  //console.log(PhotoShootDetails,"PhotoShootDetails-------------------")

  const orderData = PhotoShootDetails?.OrderDetails;
  //console.log(orderData,"orderData-------------------")
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>product Detail</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Manage Orders"
                breadcrumbItem="Order View"
              />
            </Col>
            <Col xl={6}>
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                  <div className="DashbordFliter ">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <button type="button" className="btn SecondaryBtn2">
                        Send Sms
                      </button>
                      <span>
                        <WalletStatus />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <PhotoShootOrderDetails orderData={orderData} />
        </Container>
      </div>
    </>
  );
};

export default PhotoDetails;
