import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  Spinner,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ImgDropzone from './imgDropzone';
import { Link } from 'react-router-dom';
import '../style.scss';
import axios from 'axios';
import resizeSvgImg from '../../../../assets/images/resize-fit.svg';

const ResizeForm = () => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageValues, setImageValues] = useState(null);
  const [helpText, setHelpText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropError, setdropError] = useState(false);
  const [zipDownloadlink, setZipDownlaodLink] = useState(null);
  const [openDiv, setOpenDiv] = useState(false);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles([...selectedFiles, ...files]);
    setdropError(false);
    setErrorMessage(null);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const downloadZip = () => {
    if (zipDownloadlink) {
      setTimeout(() => {
        axios
          .get(
            `https://studio-service-dev-66azmcivpq-uc.a.run.app/imagelibrary/resize-download/${zipDownloadlink}`,
            {
              responseType: 'blob',
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = '_blank'; // Open in a new tab
            const filename = zipDownloadlink.substring(
              zipDownloadlink.lastIndexOf('/') + 1
            );
            link.download = filename; // Use the extracted filename
            //link.download = 'your_zip_file.zip'; // Specify the desired file name
            link.click();
            setOpenDiv(false);
          })
          .catch((error) => {
            console.error('Error downloading file:', error);
          });
      }, 2000);
    }
  };

  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    setErrorMessage(null);
    if (values) {
      try {
        const formData = new FormData();
        if (selectedFiles.length === 0) {
          setErrorMessage('Please Select the Image file');
          setdropError(true);
          setIsLoading(false);
        } else {
          selectedFiles.forEach((file) => {
            formData.append('files', file);
          });

          formData.append('fitType', values.imgFitType);
          formData.append('width', values.imgWidth);
          formData.append('height', values.imgHeight);
          const response = await axios.post(
            'https://studio-service-dev-66azmcivpq-uc.a.run.app/imagelibrary/resize/bulk',
            formData
          );
          if (response) {
            setIsLoading(false);
            setImageValues('');
            setHelpText(null);
            setOpenDiv(true);
            setselectedFiles([]);
            setZipDownlaodLink(response?.data?.data?.downloadLink);
            downloadZip();
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
    }
  };

  useEffect(() => {
    if (imageValues === 'Contain') {
      setHelpText('Idk, this is an example. Deal with it!');
    } else if (imageValues === 'Fit') {
      setHelpText('Loreum Inspum');
    } else if (imageValues === 'Same Aspect Ratio') {
      setHelpText('dummy Text');
    } else {
      setHelpText('');
    }
  }, [imageValues]);

  return (
    <React.Fragment>
      {openDiv === true ? (
        <Col xl={12}>
          <div className="downloadBx">
            <span>
              Your Resized files are ready to Download. Please Click on Download
              button{' '}
            </span>
            <button
              onClick={downloadZip}
              disabled={!zipDownloadlink}
              className="btn btn-dark"
            >
              <i className="bx bx-download" style={{ padding: '3px' }}></i>{' '}
              Downlaod
            </button>
          </div>
        </Col>
      ) : null}
      <Col xl={12}>
        <Card>
          <CardHeader>
            <h4 className="card-title">Image Resize Tool</h4>
            <p className="card-title-desc">
              Provide proper values to get accurate results.
            </p>
          </CardHeader>
          <CardBody>
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col xl={5}>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="widthPixel">Width in Pixel</Label>
                        <AvField
                          name="imgWidth"
                          placeholder="Ex:1000"
                          type="number"
                          errorMessage="Enter Image Resize Width in Pixel"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="widthPixel"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="heightPixel">Height in Pixel</Label>
                        <AvField
                          name="imgHeight"
                          placeholder="Ex:1000"
                          type="text"
                          errorMessage="Enter Image Resize Height in Pixel"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: '^[0-9]+$',
                              errorMessage: 'Only Digits',
                            },
                          }}
                          id="heightPixel"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="imgFitType">Image Fit Type</Label>
                        <AvField
                          type="select"
                          name="imgFitType"
                          errorMessage=" Please select a valid fit type"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="imgFitType"
                          helpMessage={helpText}
                          onChange={(e) => {
                            setImageValues(e.target.value);
                          }}
                        >
                          <option>Choose an Option</option>
                          <option value={'cover'}>Cover</option>
                          <option value={'contain'}>Contain</option>
                          <option value={'fill'}>Fill</option>
                          <option value={'inside'}>Inside</option>
                          <option value={'outside'}>Outside</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="imgQuality">Image Quality</Label>
                        <AvField
                          name="imgQuality"
                          placeholder="Ex:1000"
                          type="text"
                          value="100"
                          errorMessage="Enter Image Quality"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: '^[0-9]+$',
                              errorMessage: 'Only Digits',
                            },
                          }}
                          id="imgQuality"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div>
                        <label>Image Fit Type Example</label>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="auto"
                          viewBox="0 0 998 203"
                        >
                          <defs>
                            <g id="placeholder">
                              <rect width="180" height="128" fill="#64bed8" />
                              <circle
                                cx="61.1"
                                cy="36.8"
                                r="19.3"
                                fill="#ffefa9"
                              />
                              <circle
                                cx="61.1"
                                cy="36.8"
                                r="18.1"
                                fill="#fdda42"
                              />
                              <path
                                d="m67.2 34.7 15.2 46L90 57.9l30.4 38 7.8-15.2 7.5 15.4H44z"
                                fill="#6a696f"
                              />
                              <path
                                d="m82.4 80.7-15.2-46-.3 69h22.9z"
                                fill="#474749"
                              />
                              <path
                                d="m90.1 58 12.2 15 18.2 23-13.9.1z"
                                fill="#474749"
                              />
                              <path
                                d="M135.8 96H131l-2.8-15.3z"
                                fill="#474749"
                              />
                              <path
                                d="M35.2 96h107.1c0 1.7-1.4 3.2-3.2 3.2H38.4a3.2 3.2 0 0 1-3.2-3.2z"
                                fill="#b9c861"
                              />
                              <path
                                d="m67.2 34.7-.1 31-6.2-3-5.3 2.7z"
                                fill="#fff"
                              />
                              <path
                                d="m67.2 34.7 7.6 23-7.7 8z"
                                fill="#b3b1b4"
                              />
                              <rect
                                width="30.8"
                                height="7.7"
                                x="71.1"
                                y="27.2"
                                rx="2.8"
                                ry="4.1"
                                fill="#fff"
                              />
                              <rect
                                width="30.8"
                                height="7.7"
                                x="82.2"
                                y="34.8"
                                rx="2.8"
                                ry="4.1"
                                fill="#fff"
                              />
                              <rect
                                width="30.8"
                                height="7.7"
                                x="36.2"
                                y="19.6"
                                rx="2.8"
                                ry="4.1"
                                fill="#fff"
                              />
                              <path
                                d="m89.6 72.8-7.2 7.9L90 57.9l10 23z"
                                fill="#fff"
                              />
                              <path d="m90.1 58 10 23 2.2-8z" fill="#b3b1b4" />
                              <path
                                d="M131.2 85.2 137 68l9 17.2-8 6z"
                                fill="#8da128"
                              />
                              <rect
                                width="109.4"
                                height="6.8"
                                x="33.9"
                                y="99.1"
                                rx="13.2"
                                ry="11.4"
                                fill="#22b0d6"
                              />
                              <path
                                d="m137 68-5.8 17.2 6.8 6.1.3-13.7z"
                                fill="#727d2e"
                              />
                              <rect
                                width="83.3"
                                height="6.8"
                                x="50.8"
                                y="103.6"
                                rx="10"
                                ry="11.4"
                                fill="#22b0d6"
                              />
                              <rect
                                width=".7"
                                height="18.4"
                                x="138"
                                y="77.6"
                                fill="#585657"
                              />
                              <rect
                                width=".5"
                                height="5.2"
                                x="2"
                                y="-161.3"
                                fill="#585657"
                                transform="rotate(120)"
                              />
                              <rect
                                width=".5"
                                height="5.3"
                                x="5.5"
                                y="-163.3"
                                fill="#585657"
                                transform="rotate(120)"
                              />
                              <rect
                                width=".5"
                                height="4.8"
                                x="-142.4"
                                y="77.7"
                                fill="#585657"
                                transform="rotate(240)"
                              />
                              <rect
                                width=".5"
                                height="5.1"
                                x="-146"
                                y="75.6"
                                fill="#585657"
                                transform="rotate(240)"
                              />
                            </g>
                            <pattern
                              id="img"
                              height="100%"
                              width="100%"
                              viewBox="0 0 180 128"
                            >
                              <use xlinkHref="#placeholder" />
                            </pattern>
                            <pattern
                              id="img-fill"
                              width="100%"
                              height="100%"
                              viewBox="0 0 180 128"
                              preserveAspectRatio="none"
                            >
                              <use xlinkHref="#placeholder" />
                            </pattern>
                          </defs>
                          <rect
                            x="0"
                            y="0"
                            width="100%"
                            height="auto"
                            fill="##fff;"
                          />
                          <g id="cover">
                            <rect
                              x="22"
                              y="28"
                              width="180"
                              height="132"
                              fill="url(#img)"
                            />
                            <rect
                              x="48"
                              y="30"
                              width="128"
                              height="128"
                              fill="none"
                              stroke="#000"
                              strokeWidth="4"
                              className={
                                imageValues === 'cover' ? 'strokeActive' : ''
                              }
                            />
                            <text
                              x="112"
                              y="86%"
                              dominant-baseline="middle"
                              text-anchor="middle"
                              font-family="sans"
                              font-size="24"
                              font-weight="bold"
                            >
                              cover
                            </text>
                          </g>
                          <g id="contain">
                            <rect
                              x="240"
                              y="30"
                              width="128"
                              height="128"
                              fill="url(#img)"
                              stroke="#000"
                              strokeWidth="4"
                              className={
                                imageValues === 'contain' ? 'strokeActive' : ''
                              }
                            />
                            <text
                              x="304"
                              y="86%"
                              dominant-baseline="middle"
                              text-anchor="middle"
                              font-family="sans"
                              font-size="24"
                              font-weight="bold"
                              fill="#555"
                            >
                              contain
                            </text>
                          </g>
                          <g id="fill">
                            <rect
                              x="432"
                              y="30"
                              width="128"
                              height="128"
                              fill="url(#img-fill)"
                              stroke="#000"
                              strokeWidth="4"
                              className={
                                imageValues === 'fill' ? 'strokeActive' : ''
                              }
                            />
                            <text
                              x="496"
                              y="86%"
                              dominant-baseline="middle"
                              text-anchor="middle"
                              font-family="sans"
                              font-size="24"
                              font-weight="bold"
                            >
                              fill
                            </text>
                          </g>
                          <g id="inside">
                            <rect
                              x="624"
                              y="48"
                              width="128"
                              height="92"
                              fill="url(#img)"
                              stroke="#000"
                              strokeWidth="4"
                              className={
                                imageValues === 'inside' ? 'strokeActive' : ''
                              }
                            />
                            <rect
                              x="624"
                              y="30"
                              width="128"
                              height="128"
                              fill="none"
                              stroke="#000"
                              strokeWidth="4"
                              stroke-dasharray="12 4"
                              stroke-dashoffset="6"
                            />
                            <text
                              x="688"
                              y="86%"
                              dominant-baseline="middle"
                              text-anchor="middle"
                              font-family="sans"
                              font-size="24"
                              font-weight="bold"
                              fill="#555"
                            >
                              inside
                            </text>
                          </g>
                          <g id="outside">
                            <rect
                              x="792"
                              y="30"
                              width="176"
                              height="128"
                              fill="url(#img)"
                              stroke="#000"
                              strokeWidth="4"
                              className={
                                imageValues === 'outside' ? 'strokeActive' : ''
                              }
                            />
                            <rect
                              x="816"
                              y="30"
                              width="128"
                              height="128"
                              fill="none"
                              stroke="#000"
                              strokeWidth="4"
                              stroke-dasharray="12 4"
                              stroke-dashoffset="-2"
                            />
                            <text
                              x="880"
                              y="86%"
                              dominant-baseline="middle"
                              text-anchor="middle"
                              font-family="sans"
                              font-size="24"
                              font-weight="bold"
                            >
                              outside
                            </text>
                          </g>
                        </svg>
                      </div>
                    </Col>
                  </Row>

                  <Button color="dark" type="submit">
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Resize Images
                  </Button>
                </Col>
                <Col xl={7}>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="imageUpload">Upload Image</Label>
                        <ImgDropzone
                          dropError={dropError}
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                          }}
                          acceptedFiles="image/*"
                        ></ImgDropzone>
                      </FormGroup>
                    </Col>
                    <span className="text-danger">{errorMessage}</span>
                  </Row>
                </Col>
              </Row>
            </AvForm>
            <Row>
              <Col md="12">
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.length > 0 ? (
                    <>
                      {' '}
                      <h3>Uploaded Images</h3>
                    </>
                  ) : null}
                  <div className="uploadedImgContainer">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete "
                          key={i + '-file'}
                        >
                          <div className="p-1">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  className=" rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              {/* <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col> */}
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ResizeForm;
