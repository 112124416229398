import React from 'react';
import axios from 'axios';
import OrderProgressbar from './OrderProgressbar';
import OrderDetailsBlock from './OrderDetailsBlock';
import OrderInvoice from './OrderInvoice';
import OrderItemDetails from './OrderItemDetails';
import PaymentInvoiceDetail from './PaymentInvoiceDetail';
import { useDispatch, useSelector } from 'react-redux';
import OrderNotebyAdmin from './OrderNotebyAdmin';
import { useParams, useHistory } from 'react-router-dom';
import RefundDetails from './RefundDetails';
import OrderUpdateDetailsForm from './OrderUpdateDetailsForm';
import ReturnOrderUpdateDetailsForm from './return/ReturnOrderUpdateDetailsForm';
import {
  fetchAllOrders,
  fetchOrderDetail,
  fetchOrderTriggerDetail,
  fetchUserView,
  getSendSMSDetail,
} from '../actions/orderActions';
import moment from 'moment';
import { useUrlSearchParams } from 'use-url-search-params';
import { OrderDeleteSlice } from '../../../Redux/order/orderSlice';
import { getOrderDeleteDate } from '../actions/orderActions';
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect, button } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  getTriggerOrders,
  sendSmSDetail,
} from '../../../Redux/order/orderSlice';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Modal,
  Spinner,
} from 'reactstrap';

function OrderDetails({
  orderData,
  itemData,
  reLoadOrderDetail,
  setOrderReload,
}) {
  const dispatch = useDispatch();

  const [triggerLoad, setTriggerDetailsLoad] = useState(false);
  const [SMSLoad, setSMSDetailsLoad] = useState(false);
  const [isBbB, setBBorderMode] = useUrlSearchParams({ order_mode: '' });
  const [togglePopup, setTogglePopup] = useState(false);
  const [togglePassword, setTogglePassword] = useState('');
  const history = useHistory();
  const [deleteButtonLoad, setDeleteButtonLoad] = useState(false);
  const [orderEntityId, setOrderEntityId] = useState('');
  const [isOrderCountry, setOrderCountry] = useUrlSearchParams({ orderCn: '' });
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [userName, setUserName] = useState('');
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }
  let UserRoleID = '';
  let userID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
      UserRoleID = userObj?.role_id;
    }
  }

  let currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  if (
    isOrderCountry?.orderCn === 'ae' &&
    (currentCountry === 'All' || currentCountry === 'all')
  ) {
    currentCountry = 'ae';
  } else if (
    isOrderCountry?.orderCn === 'qa' &&
    (currentCountry === 'All' || currentCountry === 'all')
  ) {
    currentCountry = 'qa';
  } else if (
    isOrderCountry?.orderCn === 'kw' &&
    (currentCountry === 'All' || currentCountry === 'all')
  ) {
    currentCountry = 'kw';
  } else if (
    isOrderCountry?.orderCn === 'bh' &&
    (currentCountry === 'All' || currentCountry === 'all')
  ) {
    currentCountry = 'bh';
  } else if (
    isOrderCountry?.orderCn === 'om' &&
    (currentCountry === 'All' || currentCountry === 'all')
  ) {
    currentCountry = 'om';
  }

  let Lsstate = '';

  if (orderData?.LsStatus === '0' && orderData?.WebOrderStatus !== 'Canceled') {
    Lsstate = 'New';
  } else if (
    orderData?.LsStatus === '0' &&
    orderData?.WebOrderStatus === 'Canceled'
  ) {
    Lsstate = 'Canceled';
  } else {
    Lsstate = orderData?.LsStatus;
  }
  let cdate = orderData?.OrderCreatedDate,
    orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
    orderTime = moment(new Date(cdate)).format('h:mm:ss a');

  const flowToLs = (country, entity_id, refNo) => {
    setTriggerDetailsLoad(true);
    fetchOrderTriggerDetail({
      params: {
        country: country,
        entity_id: entity_id,
        reference_no: refNo,
      },
      dispatch,
    });
  };
  const { triggerOrder = [], isOrderTriggerLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    if (triggerOrder?.code === 100) {
      setTriggerDetailsLoad(false);
      reLoadOrderDetail();
      toast.success(triggerOrder?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      dispatch(getTriggerOrders(null));
    } else {
      if (triggerOrder?.message) {
        setTriggerDetailsLoad(false);
        toast.error(triggerOrder?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        dispatch(getTriggerOrders(null));
      }
    }
  }, [triggerOrder]);
  const sendSMSApi = (refNo) => {
    setSMSDetailsLoad(true);
    getSendSMSDetail({
      params: {
        reference_no: refNo,
      },
      dispatch,
    });
  };
  const { sendSms = [], isSendSMSLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (sendSms?.code === 100) {
      setSMSDetailsLoad(false);
      reLoadOrderDetail();
      toast.success(sendSms?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      dispatch(sendSmSDetail(null));
    } else {
      if (sendSms?.message) {
        setSMSDetailsLoad(false);
        toast.error(sendSms?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        dispatch(sendSmSDetail(null));
      }
    }
  }, [sendSms]);
  const OrderDetailFunc = () => {
    setDeleteButtonLoad(true);
    getOrderDeleteDate({
      params: {
        entity_id: orderEntityId,
        user_id: userID,
        password: togglePassword,
      },
      dispatch,
    });
  };
  const { orderDelete = [], isOrderDeleteLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (orderDelete?.code === 100) {
      setDeleteButtonLoad(false);
      toast.success(orderDelete?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      setTogglePassword('');
      setmodal_backdrop(false);
      setTogglePopup(false);
      dispatch(OrderDeleteSlice(null));
      setTimeout(() => {
        history.push('/orders');
      }, 10);
    } else {
      if (orderDelete?.message) {
        setDeleteButtonLoad(false);
        toast.error(orderDelete?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        setTogglePassword('');
        setmodal_backdrop(false);
        setTogglePopup(false);
        dispatch(OrderDeleteSlice(null));
      }
    }
  }, [orderDelete]);
  useEffect(() => {
    const omsUserId = orderData?.omsUserId;
    if (omsUserId) {
      const adminUserInfo = async (omsUserId) => {
        let result = await fetchUserView({
          userid: omsUserId,
        });
        if (result) {
          setUserName(result.message.firstname + ' ' + result.message.lastname);
        }
      };
      adminUserInfo(omsUserId);
    }
  }, [orderData?.omsUserId]);
  return (
    <>
      {isOrderCountry?.orderCn === currentCountry.toLowerCase() ? (
        <>
          <Row>
            <Col>
              Order Details : Ref# <strong>{orderData?.OrderNo}</strong> &nbsp;
              | &nbsp; Order Date : {orderDt} &nbsp; {orderTime} &nbsp;
              WebStatus: &nbsp;
              {orderData?.FinancialStatus === '0' &&
              orderData?.PaymentMode !== 'cashondelivery' &&
              orderData?.WebOrderStatus !== 'Canceled' ? (
                <>Payment Failed</>
              ) : (
                <>
                  {' '}
                  <strong>{orderData?.WebOrderStatus}</strong>
                </>
              )}
              &nbsp; | &nbsp; LsStatus: &nbsp;
              <strong>{Lsstate}</strong>&nbsp; &nbsp;
              {orderData?.refundDetails?.length > 0 ? (
                <a href="#RefundBlock" className="btnTracking ">
                  Refunded{' '}
                </a>
              ) : (
                ''
              )}
              {UserRoleID === '1' && orderData?.isDeleted !== 1 ? (
                <>
                  <button
                    className="btnTracking mb-2 mr-2"
                    onClick={() =>
                      flowToLs(
                        currentCountry,
                        orderData?.OrderId,
                        orderData?.OrderNo
                      )
                    }
                  >
                    {triggerLoad === true && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    Flow Ls{' '}
                  </button>
                </>
              ) : null}
              {isBbB?.order_mode === 'store' &&
              orderData?.LsStatus.toLowerCase() === 'material_received' ? (
                <>
                  <button
                    className="btnTracking mb-2"
                    onClick={() => sendSMSApi(orderData?.OrderNo)}
                  >
                    {SMSLoad === true && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    Send SMS{' '}
                  </button>
                </>
              ) : (
                <></>
              )}
              {orderData?.FinancialStatus === '0' &&
              orderData?.isDeleted !== 1 ? (
                <>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => {
                      setOrderEntityId(orderData?.OrderId);
                      tog_backdrop();
                    }}
                  >
                    Delete Order
                  </button>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop();
            }}
            backdrop={'static'}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete Order
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            {togglePopup === false ? (
              <>
                <div className="modal-body">
                  <p>Do you want to Delete this order?</p>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      setmodal_backdrop(false);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={() => {
                      setTogglePopup(true);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </>
            ) : (
              <>
                {togglePopup === true ? (
                  <>
                    <div className="modal-body">
                      <AvForm
                        onValidSubmit={() => {
                          OrderDetailFunc();
                        }}
                      >
                        <p>Enter the password</p>
                        <Row className="mb-3">
                          <Col>
                            <AvField
                              type="password"
                              className="form-control"
                              name="password"
                              value={togglePassword}
                              onChange={(e) => {
                                setTogglePassword(e?.target?.value);
                              }}
                              required
                            />
                          </Col>
                        </Row>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setmodal_backdrop(false);
                            }}
                          >
                            Cancel
                          </button>

                          <button type="submit" className="btn btn-dark">
                            {deleteButtonLoad === true && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{' '}
                            Submit
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Modal>
          {(isBbB?.order_mode !== 'All' && isBbB?.orderCn === 'ae') ||
          (isBbB?.order_mode === 'All' && isBbB?.orderCn !== 'ae') ? (
            <Row>
              {' '}
              <Col>
                <OrderProgressbar orderData={orderData} />
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row className="mt-3">
            <Col xl={9} md={7}>
              <OrderDetailsBlock
                orderData={orderData}
                reLoadOrderDetail={reLoadOrderDetail}
                username={userName}
              />
              <PaymentInvoiceDetail orderData={orderData} />
            </Col>
            <Col xl={3} md={5}>
              <OrderInvoice orderData={orderData} />
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <OrderItemDetails
                itemData={itemData}
                orderData={orderData}
                setOrderReload={setOrderReload}
              />
            </Col>
          </Row>

          {/* {orderData?.returnDetails?.length > 0 ? (
            <Row>
              <Col id="ReturnBlock">
                <ReturnDetails returnData={orderData?.returnDetails} />
              </Col>
            </Row>
          ) : null} */}

          <Row>
            <Col>
              <OrderNotebyAdmin orderData={orderData} />
            </Col>
          </Row>

          {orderData?.refundDetails?.length > 0 ? (
            <Row>
              <Col id="RefundBlock">
                <RefundDetails refundData={orderData?.refundDetails} />
              </Col>
            </Row>
          ) : null}
        </>
      ) : (
        <>Order Detail Not Found</>
      )}
    </>
  );
}

export default OrderDetails;
