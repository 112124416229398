import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  Input,
  Form,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
// import { CheckTreePicker } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CheckTree } from 'rsuite';
import { Link } from 'react-router-dom';
import './style.scss';

import { useParams } from 'react-router-dom';

//Import Breadcrumb
import { DashBreadcrumb } from '../../components/Common/Breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import classnames from 'classnames';

const StoreConfig = () => {
  const [roleData, setRoleData] = useState(false);
  const [roleVal, setRolevalues] = useState([]);
  /*  [{"label":"Dashboard","value":"1","children":[]},{"label":"DH Online","value":"2","children":[{"label":"Manage orders","value":"3"},{"label":"Manage UAE orders","value":"4"},{"label":"Manage Split orders","value":"5"},{"label":"Manage store orders","value":"6"},{"label":"Manage country store orders","value":"7"},{"label":"Manage b2b orders","value":"8"},{"label":"Manage Return orders","value":"9"},{"label":"Manage bulk orders","value":"10"}]},{"label":"Non planning orders","value":"11","children":[{"label":"Manage Non planning CR orders","value":"12"},{"label":"Manage Non service area","value":"13"}]},{"label":"Marketplace","value":"14","children":[{"label":"Manage orders","value":"15"},{"label":"Import orders","value":"16"}]},{"label":"Photo shoot","value":"17","children":[]}] */

  const dispatch = useDispatch();

  const { roles = [], isLoading } = useSelector((state) => state.user);
  // console.log("roles------", roles, "----", isLoading, "----");
  const RoleData = roles?.data; // Object.assign({selected: false}, roles?.data);
  // console.log("newObj---",RoleData)

  const [verticalActiveTab, setverticalActiveTab] = useState('1');

  const [headerTab, setHeaderTab] = useState('1');
  const [headerPillsTab, setHeaderPillsTab] = useState('1');

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col xl={10}>
            <DashBreadcrumb title="Master" breadcrumbItem="Store Config" />
          </Col>
          {/* Render Breadcrumbs */}

          <Row>
            <Col>
              <Row>
                <Col md="3">
                  <Card>
                    <Nav pills className="flex-column">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '1',
                          })}
                          onClick={() => {
                            toggleVertical('1');
                          }}
                        >
                          Shipping Detail
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '2',
                          })}
                          onClick={() => {
                            toggleVertical('2');
                          }}
                        >
                          Store Information
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '3',
                          })}
                          onClick={() => {
                            toggleVertical('3');
                          }}
                        >
                          Payment Detail
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Card>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <React.Fragment>
                        <Col xl={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle className="mb-0">
                                Shipping Detail
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <AvForm className="needs-validation">
                                <Row>
                                  <Col md="12">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom01">
                                        Express Delivery Amount
                                      </Label>
                                      <AvField
                                        name="delivery_amount"
                                        type="number"
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <div className="d-flex flex-wrap gap-2">
                                  <button
                                    type="submit"
                                    className="btn btn-dark"
                                  >
                                    Save
                                  </button>{' '}
                                </div>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </React.Fragment>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col xl={6}>
                        <Card>
                          <CardHeader>
                            <CardTitle className="mb-0">
                              Store Information{' '}
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <AvForm>
                              <div className="mb-3">
                                <AvField
                                  name="telephone1"
                                  label="Telephone No1"
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="telephone2"
                                  label="Telephone No2"
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="whatsapp"
                                  label="Whatsapp No"
                                  type="text"
                                  value=""
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="sale_email"
                                  label="Sales Email"
                                  type="email"
                                  value=""
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <AvField
                                  name="gemeral_email"
                                  label="General Contact Email"
                                  type="email"
                                  value=""
                                  required
                                />
                              </div>

                              <div className="d-flex flex-wrap gap-2">
                                <button type="submit" className="btn btn-dark">
                                  Save
                                </button>{' '}
                              </div>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>

                      {/*  <CheckTreePicker defaultExpandAll  appearance="default"  toggleComponentClass={Button} data={data} style={{ width: 300 }} /> */}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default StoreConfig;
