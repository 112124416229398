import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { useUrlSearchParams } from 'use-url-search-params';
import ReactDrawer from 'react-drawer';
import Select from 'react-select';
import {
  DEVICE_TYPES,
  FILTER_BY_CHARGES,
  ORDER_TYPES,
  PAYMENT_METHODS,
} from '../../constants';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const FilterSearch = (props) => {
  const { setSearchFilter, setStatusFilter, setAdvancedFilter } = props;
  const currentDate = moment().format('YYYY-MM-DD');
  //Advance Filter Data
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [selectedChargeType, setSelectedChargeType] = useState(null);
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(null);
  // const [formattedStartDate, setFormattedStartDate] = useState([]);
  // const [formattedEndDate, setFormattedEndDate] = useState([]);

  //End Advance Filter Data
  const [stsSearchParams, setStsSearchParams] = useUrlSearchParams({
    status: 'All',
  });
  const [searchParams, setSearchParams] = useUrlSearchParams({ search: '' });

  const [searchValue, setSearchValue] = useState(searchParams.search || '');
  const [status, setStatus] = useState(stsSearchParams.status || 'All');
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const disableFutureDates = (date) => {
    // Disables dates after today
    return date.getTime() > new Date().getTime();
  };

  const handleDateChange = (dates) => {
    if (dates?.[0] && dates?.[1]) {
      const startDate = moment(dates[0]).format('YYYY-MM-DD');
      const endDate = moment(dates[1]).format('YYYY-MM-DD');
      // Update state with formatted dates
      // setFormattedStartDate(startDate);
      // setFormattedEndDate(endDate);
      setAdvanceFilterData({
        ...advanceFilterData,
        frmDt: startDate,
        toDt: endDate,
      });
      setDateRangeValue(dates);
    }
  };
  const handleDateClose = () => {
    setAdvanceFilterData({
      ...advanceFilterData,
      frmDt: '',
      toDt: '',
    });
    setDateRangeValue(null);
  };
  // Set filters only on search button click
  const handleSearchButtonClick = () => {
    setIsActiveSearch(true);
    setAdvancedFilter(advanceFilterData);
    setSearchParams({ search: searchValue });
    setStsSearchParams({ status });
    setSearchFilter({ search: searchValue });
    setStatusFilter({ status });
    // setDeviceFilter(selectedDevice);
    // setPaymentFilter(selectedPayment);
  };

  const handleResetSearch = () => {
    setIsActiveSearch(false);
    setSearchParams({ search: '' });
    setStsSearchParams({ status: 'All' });
    setSearchFilter({ search: '' });
    setStatusFilter({ status: 'All' });
    setSearchValue('');
    setStatus('All');
    setAdvancedFilter({});
    setAdvanceFilterData({});
    setSelectedPayment(null);
    setSelectedDevice(null);
    setSelectedOrderType(null);
    setSelectedChargeType(null);
    setDateRangeValue(null);
    //setFormattedStartDate([]);
    //setFormattedEndDate([]);
  };

  //Drwaer features
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [ActiveFilter, setActiveFilter] = useState(false);
  const onDrawerClose = () => {
    setOpen(false);
  };

  const AdvanceFilterOnClick = (filterStatus) => {
    if (filterStatus === false) {
      setActiveFilter(true);
      setPosition('right');
      setOpen(!open);
    } else {
      setActiveFilter(false);
    }
  };

  const handleSelectPayment = (selectedGroup) => {
    setSelectedPayment(selectedGroup);
    setAdvanceFilterData({
      ...advanceFilterData,
      paymentType: selectedGroup?.value,
    });
  };

  const handleSelectDevice = (selectedGroup) => {
    setSelectedDevice(selectedGroup);
    setAdvanceFilterData({
      ...advanceFilterData,
      deviceType: selectedGroup?.value,
    });
  };

  const handleSelectOrderType = (selectedGroup) => {
    setSelectedOrderType(selectedGroup);
    setAdvanceFilterData({
      ...advanceFilterData,
      orderType: selectedGroup?.value,
    });
  };

  const handleSelectChargeType = (selectedGroup) => {
    setSelectedChargeType(selectedGroup);
    setAdvanceFilterData({
      ...advanceFilterData,
      chargeType: selectedGroup?.value,
    });
  };

  const handleAdvanceFilter = () => {
    setIsActiveSearch(true);
    setAdvancedFilter(advanceFilterData);
    // setAdvanceFilterData(data);
  };
  return (
    <React.Fragment>
      <div
        className="p-3 bg-soft-light border-1 border-light"
        style={{ borderStyle: 'dashed' }}
      >
        <Row>
          <Col md={7} className="d-flex flex-row align-items-center gap-2">
            <div className="position-relative" style={{ minWidth: 200 }}>
              <Input
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
                type="text"
                className="form-control form-control"
                onChange={handleSearch}
                value={searchValue}
              ></Input>
              <span className="p-2 text-light position-absolute top-50 end-0 translate-middle-y">
                <i className="bx bx-search-alt font-size-16 align-middle"></i>
              </span>
            </div>
            <div style={{ minWidth: 150 }}>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleStatus}
                value={status}
              >
                <option value="All">Select Status</option>
                <option value="All">All</option>
                <option value="open">New</option>
                <option value="Confirmed">Confirmed</option>
                <option value="posted">Posted</option>
                <option value="Picked">Shipped</option>
                <option value="delivered">Delivered</option>
                <option value="canceled">Canceled</option>
                <option value="pending_payment">Pending Payment</option>
                <option value="material_received">Material Received</option>
                <option value="NotInLs">Not in LS</option>
                <option value="delivery_scheduled">Delivery Scheduled</option>
                <option value="Material N/A">Material N/A</option>
                <option value="DmsNotFlow">DmsNotFlow</option>
              </select>
            </div>
            <div>
              <DateRangePicker
                size="lg"
                className="w-100"
                placement="auto"
                placeholder="Select a Date Range"
                showOneCalendar={false}
                disabledDate={disableFutureDates}
                onChange={handleDateChange}
                onClean={handleDateClose}
                value={dateRangeValue}
              />
            </div>

            <Button
              color="secondary"
              type="button"
              id="srchBtn"
              onClick={handleSearchButtonClick}
            >
              <i className="bx bx-search-alt font-size-16 align-middle"></i>
            </Button>
            <Button
              outline
              color="danger"
              type="button"
              onClick={handleResetSearch}
            >
              <i className="bx bx-revision font-size-16 align-middle"></i>
            </Button>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ cursor: 'pointer' }}
            >
              <span
                className="bg-white border border-soft-dark px-3 py-2 rounded cursor-pointer fw-bold d-flex align-items-center justify-content-end gap-2"
                onClick={() => {
                  AdvanceFilterOnClick(ActiveFilter);
                }}
              >
                Filter <i className="bx bx-filter-alt"></i>
              </span>
            </div>
          </Col>
          {/* <Col
            md={5}
            className="d-flex flex-row align-items-center gap-2 justify-content-end"
          >
            <div>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Choose Export Report Type</option>
                <option value="1">Online Sale</option>
                <option value="2">Order Item Summary</option>
                <option value="3">Installation Orders</option>
              </select>
            </div>
            <div>Select Date Range</div>
            <Button
              disabled
              outline
              color="success"
              type="button"
              className="waves-effect waves-light d-flex align-items-center gap-1"
            >
              Export
              <i className="bx bx-download font-size-16 align-middle"></i>
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col className="mt-2">
            <p className="m-0 d-flex gap-2 align-items-center">
              {isActiveSearch &&
              (searchParams?.search ||
                (stsSearchParams?.status && stsSearchParams?.status != 'All') ||
                (advanceFilterData &&
                  (advanceFilterData?.frmDt ||
                    advanceFilterData?.paymentType ||
                    advanceFilterData?.deviceType ||
                    advanceFilterData?.chargeType))) ? (
                <span className="fs-11">Showing Result for : </span>
              ) : null}

              {searchParams?.search ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Keyword : {searchParams?.search}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setSearchFilter({ search: '' });
                      setSearchParams({ search: '' });
                      setSearchValue('');
                    }}
                  />
                </span>
              ) : null}
              {stsSearchParams?.status && stsSearchParams?.status != 'All' ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Status : {stsSearchParams?.status}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setStatusFilter({ status: 'All' });
                      setStsSearchParams({ status: 'All' });
                      setStatus('All');
                    }}
                  />
                </span>
              ) : null}
              {advanceFilterData && advanceFilterData?.frmDt ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Date From : {advanceFilterData?.frmDt}
                  {' to '} {advanceFilterData?.toDt}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setAdvancedFilter((advanceFilterData) => ({
                        ...advanceFilterData,
                        frmDt: '',
                        toDt: '',
                      }));
                      setAdvanceFilterData((advanceFilterData) => ({
                        ...advanceFilterData,
                        frmDt: '',
                        toDt: '',
                      }));
                      handleDateClose();
                      // setFormattedStartDate([]);
                      // setFormattedEndDate([]);
                    }}
                  />
                </span>
              ) : null}

              {advanceFilterData && advanceFilterData?.paymentType ? (
                <span className="border px-1 bg-white rounded fs-10">
                  Payment :{' '}
                  {advanceFilterData?.paymentType.charAt(0).toUpperCase() +
                    advanceFilterData?.paymentType.slice(1)}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setAdvancedFilter((advanceFilterData) => ({
                        ...advanceFilterData,
                        paymentType: '',
                      }));
                      setAdvanceFilterData((advanceFilterData) => ({
                        ...advanceFilterData,
                        paymentType: '',
                      }));
                      setSelectedPayment(null);
                    }}
                  />
                </span>
              ) : null}

              {advanceFilterData && advanceFilterData?.deviceType ? (
                <span className="border px-1 bg-white rounded fs-10">
                  DeviceType :{' '}
                  {advanceFilterData?.deviceType.charAt(0).toUpperCase() +
                    advanceFilterData?.deviceType.slice(1)}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setAdvancedFilter((advanceFilterData) => ({
                        ...advanceFilterData,
                        deviceType: '',
                      }));
                      setAdvanceFilterData((advanceFilterData) => ({
                        ...advanceFilterData,
                        deviceType: '',
                      }));
                      setSelectedDevice(null);
                    }}
                  />
                </span>
              ) : null}

              {advanceFilterData && advanceFilterData?.chargeType ? (
                <span className="border px-1 bg-white rounded fs-10">
                  ChargeType :{' '}
                  {advanceFilterData?.chargeType.charAt(0).toUpperCase() +
                    advanceFilterData?.chargeType.slice(1)}{' '}
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setAdvancedFilter((advanceFilterData) => ({
                        ...advanceFilterData,
                        chargeType: '',
                      }));
                      setAdvanceFilterData((advanceFilterData) => ({
                        ...advanceFilterData,
                        chargeType: '',
                      }));
                      setSelectedChargeType(null);
                    }}
                  />
                </span>
              ) : null}
            </p>
          </Col>
        </Row>
      </div>

      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
        className="sideNavWidth"
      >
        <Card
          className="card-h-100 OrderDetailsBox"
          style={{ overflowY: 'scroll' }}
        >
          <CardHeader>
            <Row>
              <Col>
                <CardTitle className="border-bottom">Advance Filter </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <>
              {' '}
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Payment Method
                    </label>
                    <Select
                      value={selectedPayment}
                      onChange={handleSelectPayment}
                      options={PAYMENT_METHODS}
                      defaultValue={selectedPayment}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Device
                    </label>
                    <Select
                      value={selectedDevice}
                      onChange={handleSelectDevice}
                      options={DEVICE_TYPES}
                      defaultValue={selectedDevice}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Type of Orders
                    </label>
                    <Select
                      value={selectedOrderType}
                      onChange={handleSelectOrderType}
                      options={ORDER_TYPES}
                      defaultValue={selectedOrderType}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Filter by Charges
                    </label>
                    <Select
                      value={selectedChargeType}
                      onChange={handleSelectChargeType}
                      options={FILTER_BY_CHARGES}
                      defaultValue={selectedChargeType}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
              <button
                className="btn btn-success"
                onClick={() => {
                  onDrawerClose();
                  handleAdvanceFilter();
                }}
              >
                Apply
              </button>
            </>
            {/* ) : null} */}
          </CardBody>
        </Card>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default FilterSearch;
