import './CustomerOrderingMode.scss';
import React, { useState } from 'react';

function CustomerOrderingModeComponent(props) {
  const { showOrderingMode, changeOrderType } = props || {};

  return showOrderingMode ? (
    <>
      <div className="p-3 pb-0 d-flex gap-3 align-items-center">
        <h6 className="text-nowrap m-0">Customer Ordering Mode*</h6>
        <select
          className="form-select"
          onChange={(e) => changeOrderType(e.target.value)}
        >
          <option>Select Ordering Mode</option>
          <option defaultValue="whatsapp">Whatsapp</option>
          <option value="facebook">Facebook</option>
          <option value="email">Email</option>
          <option value="phone">Phone</option>
        </select>
      </div>
    </>
  ) : (
    ''
  );
}

export default CustomerOrderingModeComponent;
