import './InvoiceBreakup.scss';
import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { useCallback, useEffect } from 'react';
import { viewCart } from '../../actions/action';
import { useDispatch } from 'react-redux';
function InvoiceBreakupComponent(props) {
  const dispatch = useDispatch();
  const { cart, showInvoiceBreakup, totalAssemblyCharge, setTotalShipingfee } =
    props || {};
  const [modal_center, setmodal_center] = useState(false);
  const {
    grandTotal = 0,
    subTotal = 0,
    countryInfo = {},
    coupon = {},
    cartMeta = {},
    items,
    discountApplied = 0,
    giftCard = {},
    creditNote = {},
    shipment = {},
    wallet = {},
  } = cart || {};
  const {
    couponDiscount = 0,
    couponDescription,
    couponwiseDiscount = [],
    sellerAssemblyInfo = [],
  } = cartMeta || {};
  const totalDiscountsApplied =
    Number(discountApplied) +
    Number(couponDiscount) +
    Number(giftCard?.redeemedAmount || 0);
  const totalShipping = (cartMeta?.sellers || []).reduce(
    (acc, seller) => acc + (seller?.totalShippingCharge || 0),
    0
  );

  setTotalShipingfee(totalShipping);
  /**
   * Modal
   */
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }
  const [sellerAllInfo, setSellerAllInfo] = useState([]);
  useEffect(() => {
    if (Object.keys(cartMeta).length === 0) {
      // cartMeta is empty, do nothing or return
      return;
    }

    try {
      const data = (cartMeta?.sellers || []).map(
        ({ sellerId, sellerInfo = {}, totalShippingCharge = 0 }) => {
          const sellerItems = items
            .filter(
              (item) =>
                item?.product?.coreFields?.supplierInfo?.code === sellerId
            )
            .map((item) => item?.product?.name || '');
          return {
            totalShippingCharge,
            name: sellerInfo?.name || '',
            sellerId,
            orderItems: sellerItems,
          };
        }
      );

      setSellerAllInfo(data);
    } catch (e) {}
  }, [cartMeta]);
  //}, [cartMeta]);

  const SellerAllInfo = useCallback(() => {
    return (sellerAllInfo || [])?.map((seller) => {
      const {
        name = '',
        totalShippingCharge = 0,
        orderItems = [],
        sellerId,
      } = seller || {};
      return (
        <li key={sellerId} className="listStyle">
          <div className="shippingList">
            <p className="shippingType">
              <div className="shipped">
                <strong>Shipped and delivered by {name}</strong>
              </div>
              <span className="feeAmount">
                {totalShippingCharge > 0 ? totalShippingCharge : 'Free'}
              </span>
            </p>
            <p>
              {orderItems.map((item) => {
                return <span key={item}>{item}</span>;
              })}
            </p>
          </div>
        </li>
      );
    });
  }, [sellerAllInfo]);
  const show_cod_free = localStorage.getItem('is_cod_free');
  const show_shipping_free = localStorage.getItem('is_shipping_free');

  const viewCartData = async () => {
    await viewCart({}, dispatch);
  };
  const removePromotionPrice = (event) => {
    localStorage.setItem('is_remove_promo_prices', event.target.checked);
    viewCartData();
  };
  const is_remove_promo_prices = localStorage?.getItem(
    'is_remove_promo_prices'
  );

  return (
    <>
      <div className="invoiceBreakup bg-light">
        <h4 className="font-size-16 p-3 m-0">Invoice Break up</h4>
        <div className="orderSummary px-3 pb-3">
          <div className="orderSummaryInner">
            <div className="orderSummaryValue">
              <div className="orderSummaryLabel">
                <label>
                  <strong>Subtotal</strong>
                </label>
              </div>
              <div className="orderSummaryLabel">
                <span>
                  <strong>
                    {countryInfo?.currency} {subTotal.toFixed(2)}
                  </strong>
                </span>
              </div>
            </div>
            {coupon.code ? (
              <div className="orderSummaryValue">
                <div className="orderSummaryLabel">
                  <div className="orderSummaryShippingFee">
                    <label>
                      Coupon{' '}
                      <span className="text-success"> ({coupon.code})</span>
                    </label>
                  </div>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    <strong className="text-success">
                      - {countryInfo?.currency} {cartMeta.couponDiscount}
                    </strong>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}

            {giftCard?.redeemedAmount > 0 ? (
              <div className="orderSummaryValue ">
                <div className="orderSummaryLabel">
                  <div className="orderSummaryShippingFee">
                    <label>
                      Giftcard{' '}
                      <span className="text-success">
                        {' '}
                        ({giftCard.giftVoucherNumber})
                      </span>
                    </label>
                  </div>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    <strong className="text-success">
                      - {countryInfo?.currency} {giftCard?.redeemedAmount}
                    </strong>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
            {wallet?.redeemedAmount > 0 ? (
              <div className="orderSummaryValue ">
                <div className="orderSummaryLabel">
                  <div className="orderSummaryShippingFee">
                    <label className="text-success">Online Wallet</label>
                  </div>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    <strong className="text-success">
                      - {countryInfo?.currency} {wallet?.redeemedAmount}
                    </strong>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}

            {shipment?.charge > 0 ? (
              <div className="orderSummaryValue">
                <div className="orderSummaryLabel">
                  <label>Express Delivery Charge</label>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    {countryInfo?.currency} {shipment?.charge}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}

            {creditNote?.redeemedAmount > 0 ? (
              <div className="orderSummaryValue">
                <div className="orderSummaryLabel">
                  <div className="orderSummaryShippingFee">
                    <label>
                      Credit Note{' '}
                      <span className="text-success">
                        {' '}
                        ({creditNote.number})
                      </span>
                    </label>
                  </div>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    <strong className="text-success">
                      - {countryInfo?.currency} {creditNote?.amount}
                    </strong>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}

            {totalShipping > 0 /*&& show_shipping_free != 'true'*/ ? (
              <div className="orderSummaryValue">
                <div className="orderSummaryLabel">
                  <div className="orderSummaryShippingFee">
                    <label>
                      <span>Shipping fee</span>{' '}
                      <span
                        onClick={() => {
                          tog_center();
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        className="link-info text-decoration-underline"
                      >
                        Details
                      </span>
                    </label>
                  </div>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    {countryInfo?.currency} {totalShipping}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}

            {totalAssemblyCharge > 0 ? (
              <div className="orderSummaryValue">
                <div className="orderSummaryLabel">
                  <label>Assambly Charges</label>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    {countryInfo?.currency} {totalAssemblyCharge}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
            {cart?.paymentMethod?.code === 'cashondelivery' &&
            show_cod_free != 'true' ? (
              <div className="orderSummaryValue">
                <div className="orderSummaryLabel">
                  <label>COD Fee</label>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    {countryInfo?.currency} {cart?.paymentMethod?.charge}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}

            {couponDiscount > 0 ? (
              <div className="orderSummaryValue text-success">
                <div className="orderSummaryLabel">
                  <label>
                    {couponDescription !== '' ? couponDescription : 'Discount'}
                  </label>
                </div>
                <div className="orderSummaryLabel">
                  <span>
                    <strong>
                      -{countryInfo?.currency || 'AED'}{' '}
                      {couponDiscount.toFixed(2)}
                    </strong>
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="orderSummaryTotal">
            <div className="orderSummaryLabel">
              <label>
                <strong className="font-size-16">You Pay</strong>
                <em>Inclusive of all taxes</em>
              </label>
            </div>
            <div className="orderSummaryLabel">
              <p className="m-0 font-size-16">
                <strong>
                  {countryInfo?.currency || 'AED'} {grandTotal.toFixed(2)}
                </strong>
              </p>
            </div>
          </div>

          <div className="RemovePromotions">
            <label style={{ display: 'flex', gap: '5px' }}>
              <input
                type="checkbox"
                name="RemovePromotions"
                checked={is_remove_promo_prices !== 'true' ? false : true}
                onChange={removePromotionPrice}
              />
              Remove Promotion Prices{' '}
            </label>
          </div>
        </div>

        {/* open */}
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center();
          }}
          centered={true}
          className="shippingChargesPopup"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">How Shipping Charges applies</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Free shipping is applicable on DanubeHome items above 200 AED. 10
              AED shipping fee will be applied at checkout when the order value
              is less than 200 AED.
            </p>
            <p>
              Up to 15 AED shipping fee will be applied per seller on some
              market items. This per-seller fee will depend on the order
              subtotal from each seller as follows: AED 10 if seller subtotal is
              less than AED 100; AED 5 if the seller subtotal is between AED 100
              and AED 500; and AED 0 if the seller subtotal is greater than AED
              500. This fee applies even if your order includes both DanubeHome
              & market items.
            </p>

            <div className="shippingChargesContain">
              <p className="shippingChargesContainNotice m-0">
                <strong>
                  Shipping fee may vary according to different warehouses and
                  sellers
                </strong>
              </p>
              <div className="shippingListContain">
                <div className="shippingList">
                  <p className="shippingType"></p>
                  <ul className="listStyle">
                    <SellerAllInfo />
                  </ul>
                </div>
              </div>
              <div className="shippingTotal">
                <strong className="shippingTotalTitle">Total</strong>
                <strong className="shippingTotalAmount">
                  {countryInfo?.currency} {totalShipping}
                </strong>
              </div>
            </div>
          </div>
        </Modal>
        {/* close */}
      </div>
    </>
  );
}

export default InvoiceBreakupComponent;
