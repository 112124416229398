import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Card, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { Table, Row, Col, Container, Spinner } from 'reactstrap';
import { fetchStoresLists } from './action/action';
import { SORTING_ORDER } from '../../constants';
import toast, { Toaster } from 'react-hot-toast';
import CsLineIcons from '../../cs-line-icons/CsLineIcons';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';

const ClickandCollectList = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [btnprimary1, setBtnprimary1] = useState(false);
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 1 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 20 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [isDataLoading, setDataLoading] = useState(false);
  const { country = [] } = useSelector((state) => state.country);
  const { storesList = [], isStoreDataLoading } = useSelector(
    (state) => state.permissions
  );

  useEffect(() => {
    setDataLoading(true);
    if (country) {
      fetchStoresLists({
        params: {
          country: country,
          start: 1,
          limit: 20,
          sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
          search_qry: searchValue.search,
        },
        dispatch,
      }).finally(() => setDataLoading(false));
    }
  }, [
    paginationValue.page,
    limitValue,
    sortingValue.sort,
    searchValue.search,
    country,
  ]);

  const limitByValues = [5, 10, 20, 50];

  const handlePagination = (page) => {
    setPaginationValue({ page });
  };

  const handleLimit = (limit) => {
    setLimitValue({ limit: Number(limit) });
  };

  const handleSorting = (type, order = SORTING_ORDER.ASC) => {
    setSortingValue({ sort: type, order });
  };

  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };

  const totalPages = Math.ceil(storesList.totalCount / limitValue.limit);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Click & Collect </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl={11}>
              <DashBreadcrumb
                title="Click & Collect Store"
                breadcrumbItem="Click & Collect"
              />
            </Col>
            <Col className="align-items-end">
              <a
                href={`/ClickandCollect`}
                type="button"
                className="btn btn-secondary"
              >
                Add Store
              </a>
            </Col>
          </Row>

          <Row className="align-items-end">
            <Col xl={2} md={6} className="mb-3 textAlignRight">
              <div className="searchBox">
                <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={searchValue.search}
                    id="listSearch"
                    onChange={handleSearch}
                  />
                  <span className="search-magnifier-icon searchIcon">
                    <CsLineIcons icon="search" />
                  </span>
                  <span className="search-delete-icon d-none">
                    <CsLineIcons icon="close" />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="table-responsive dt-table">
            {isStoreDataLoading || isDataLoading ? (
              <Spinner className="ms-2 sploader" color="primary" />
            ) : null}
            {storesList?.stores?.length > 0 ? (
              <>
                <Table className="OrderListTable table mb-0">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Store(Status)</th>
                      <th>Store(C&C)</th>
                      <th>Country</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {(storesList.stores || []).map((store, index) => (
                    <tbody key={store._id}>
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td className="noWrapText">{store?.storeName?.en}</td>
                        <td>{store.storeCode}</td>

                        <td>
                          {store.isDisplayInForm === true
                            ? 'Active'
                            : 'InActive'}
                        </td>
                        <td>
                          {store.isClickCollect === true
                            ? 'Active'
                            : 'InActive'}
                        </td>

                        <td>{store.country}</td>

                        <td className="noWrapText">{store.address?.en}</td>
                        <td>{store.telephone1}</td>

                        <td>
                          <a
                            href={`/ClickandCollectForm/${store._id}`}
                            type="button"
                            className="btn btn-sm btn-outline-warning waves-effect waves-light"
                          >
                            {' '}
                            <i className="mdi mdi-eye-outline"></i>{' '}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>

                {/* <div className="dataTblFooter">
                  <div>
                    <DropdownButton
                      title={`${limitValue.limit} Items`}
                      id="dropdown-menu-align-right"
                      onSelect={(e) => handleLimit(e)}
                    >
                      {limitByValues.map((limit, i) => (
                        <Dropdown.Item key={i} eventKey={limit}>
                          {limit} Items
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Pagination
                      id="listPage"
                      value={Number(paginationValue.page)}
                      totalPages={totalPages}
                      onChange={(page) => handlePagination(page)}
                    />
                  </div>
                </div> */}
              </>
            ) : (
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Store(Status)</th>
                    <th>Store(C&C)</th>

                    <th>Country</th>
                    <th>Address</th>
                    <th>Phone</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={12}>No Records found</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
          <Toaster position="top-right" reverseOrder={false} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClickandCollectList;
