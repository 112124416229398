import { Label } from 'reactstrap';
import { Tooltip, Whisper, Button } from 'rsuite';

const AnalyticsTooltip = (props) => {
  const { anlyticData } = props;
  console.log(anlyticData, 'ddddd');
  return (
    <>
      <Whisper
        trigger="hover"
        followCursor
        placement="topStart"
        controlId="control-id-topStart"
        speaker={
          <Tooltip className="p-2 pb-3">
            <Label className="mb-1">Analytics</Label>
            <p className="d-flex gap-2 flex-wrap mb-0">
              {anlyticData?.map((item, index) =>
                item?.source ? (
                  <span
                    key={index}
                    className="bg-primary d-flex justify-content-center align-items-center px-1 rounded gap-1"
                  >
                    <span className="bg-transparent">{item?.source}</span>
                    <span className="rounded-pill bg-danger fs-9">
                      {item?.count}
                    </span>
                  </span>
                ) : null
              )}
            </p>
          </Tooltip>
        }
      >
        <Button color="white" appearance="subtle" className="py-0 px-1">
          <i
            className="mdi mdi-information-outline"
            style={{ cursor: 'pointer' }} // Make the icon clickable
          ></i>
        </Button>
      </Whisper>
    </>
  );
};
export default AnalyticsTooltip;
