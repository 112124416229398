import { useState, useEffect, button } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePaymentMethodApi } from '../actions/orderActions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toast, { Toaster } from 'react-hot-toast';
function ChangepaymentMethod({ orderData, reLoadOrderDetail, onDrawerClose }) {
  const dispatch = useDispatch();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);

  const optionGroup = [
    {
      label: 'Payment Methods',
      options: [
        { label: 'PayFort ', value: 'payfort' },
        { label: 'Tamara ', value: 'tamara' },
        { label: 'Payfort Apple Pay ', value: 'apple_pay' },
        { label: 'Apple Pay ', value: 'apple_pay' },
        { label: 'Oman Net', value: 'oman_net' },
        { label: 'Tap', value: 'tap' },
        { label: 'Checkout', value: 'checkout' },
        { label: 'Tabby', value: 'tabby' },
      ],
    },
  ];
  const paymentLabel = {
    payfort: 'Payfort',
    payfort_fort_cc: 'Payfort',
    payfort_fort_installments: 'Payfort Installment',
    apple_pay: 'Payfort - Apple Pay',
    apple_pay: 'Apple pay',
    payfort_omannet: 'Payfort - Oman Net',
    tap: 'Tap',
    tap_apple_pay: 'Tap - Apple Pay',
    tamara: 'Tamara',
    Oman_net: 'Oman Net',
    checkout: 'Checkout',
    tabby: 'Tabby',
  };
  const handleSelectPayment = (selectedGroup) => {
    setSelectedPayment(selectedGroup);
  };
  let userID = '';
  let UserRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
      UserRoleID = userObj?.role_id;
    }
  }
  const handleValidSubmit = () => {
    setUpdateDetailsLoad(true);
    ChangePaymentMethodApi({
      params: {
        payment_method: selectedPayment?.value,
        reference_no: orderData?.OrderNo,
        user_id: userID,
      },
      dispatch,
    });
  };
  const { changepayment = [], isPaymentLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (changepayment?.code === 100) {
      setUpdateDetailsLoad(false);
      onDrawerClose();
      reLoadOrderDetail();
      toast.success(changepayment?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (changepayment?.message) {
        setUpdateDetailsLoad(false);
        toast.error(changepayment?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [changepayment]);
  return (
    <Card className="Order UpdateDetailsForm">
      <CardHeader>
        <label
          htmlFor="choices-single-default"
          className="form-label font-size-13 text-muted"
        >
          Payment Method : <b>{paymentLabel[orderData?.PaymentMode]}</b>
        </label>
        <CardTitle>Change Payment Method</CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm
          className="needs-validation row gx-3 gy-2 align-items-center"
          onValidSubmit={(e, v) => {
            handleValidSubmit();
          }}
        >
          <Col xl={12} md={12}>
            <Row>
              <Col xl={12} md={12}>
                <div className="mb-3">
                  <Select
                    value={selectedPayment}
                    onChange={handleSelectPayment}
                    options={optionGroup}
                    defaultValue={selectedPayment}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col xl={12} md={12}>
            <button type="submit" className="btn btn-dark">
              {updateStsLoad === true && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}{' '}
              Submit
            </button>
          </Col>
        </AvForm>
      </CardBody>
      <Toaster position="top-right" reverseOrder={false} />
    </Card>
  );
}
export default ChangepaymentMethod;
