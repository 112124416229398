import { AvField, AvForm } from 'availity-reactstrap-validation';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Modal, Spinner } from 'reactstrap';
import { getOrderDeleteDate } from '../actions/orderActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

const OrderDelete = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setDeleteModal, deleteModal, userID, entityId } = props;

  const [isClosePopup, setIsClosePopup] = useState(deleteModal);
  const [delPassword, setDelPassword] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const toggleDeletePopup = () => {
    setDeleteModal(!deleteModal);
  };

  const handleBtnCancel = (e) => {
    console.log(e, 'dddd');
    setDeleteModal(e);
    setIsClosePopup(e);
  };

  const handleDeleteOrder = async () => {
    setIsDeleted(true);
    await getOrderDeleteDate({
      params: {
        entity_id: entityId,
        user_id: userID,
        password: delPassword,
      },
      dispatch,
    });
  };

  const { orderDelete = [], isOrderDeleteLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (orderDelete?.code === 100) {
      setIsDeleted(false);
      toast.success(orderDelete?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      setDelPassword('');
      setDeleteModal(false);
      handleBtnCancel(false);
      setTimeout(() => {
        history.push('/orders');
      }, 10);
    } else {
      if (orderDelete?.message) {
        setIsDeleted(false);
        toast.error(orderDelete?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        setDelPassword('');
        handleBtnCancel(false);
        setDeleteModal(false);
      }
    }
  }, [orderDelete]);

  return (
    <Modal
      isOpen={deleteModal}
      toggle={() => {
        toggleDeletePopup();
      }}
      backdrop={'static'}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Delete Order
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            handleBtnCancel(false);
          }}
          aria-label="Close"
        ></button>
      </div>

      {isClosePopup ? (
        <AvForm
          onValidSubmit={() => {
            handleDeleteOrder();
          }}
        >
          <div className="modal-body">
            <p>Enter the password</p>
            <Row className="mb-3">
              <Col>
                <AvField
                  type="password"
                  className="form-control"
                  name="password"
                  value={delPassword}
                  onChange={(e) => {
                    setDelPassword(e?.target?.value);
                  }}
                  required
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              type="button"
              color="light"
              outline
              onClick={() => {
                handleBtnCancel(false);
              }}
            >
              Cancel
            </Button>

            <Button type="submit" color="dark" className="d-flex gap-2">
              {isDeleted ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Submit
            </Button>
          </div>
        </AvForm>
      ) : (
        <>
          <div className="modal-body">
            <p>Do you want to Delete this order?</p>
          </div>

          <div className="modal-footer">
            <Button
              type="button"
              color="light"
              outline
              onClick={() => {
                handleBtnCancel(false);
              }}
            >
              Cancel
            </Button>

            <Button
              type="button"
              color="success"
              onClick={() => {
                handleBtnCancel(true);
              }}
            >
              Yes
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default OrderDelete;
