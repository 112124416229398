import { useState, useEffect, useSearchParams } from 'react';
import { MetaTags } from 'react-meta-tags';
import { DashBreadcrumb } from '../../components/Common/Breadcrumb';
import 'react-drawer/lib/react-drawer.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/themes/material_blue.css';
import { Col, Container, Row, Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Form } from 'react-bootstrap';
import KBCategoryList from './components/KbCategoryList';
const KnowledgeBaseCategory = () => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              {' '}
              <DashBreadcrumb
                title="Knowledge Base"
                breadcrumbItem="Categories"
              />
            </Col>
            <Col md="auto" sm="auto" xs="auto" className="MobileView">
              <Link className="btn btn-dark" to="/AddNewCategoryForm">
                Add Category
              </Link>
            </Col>
          </Row>
          <KBCategoryList />
        </Container>
      </div>
    </>
  );
};

export default KnowledgeBaseCategory;
