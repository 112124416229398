import { useState, useEffect, button, useSearchParams } from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import { BccwDataDetail } from '../bccwOrders/action/action';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import RiverOrderDetails from '../bccwOrders/ManageRiverHillOrdersDetail';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import WalletStatus from './components/WalletStatus';
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const RiverHillOrderDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get('order_mode');
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [apiCounts, setApiCounts] = useState(0);

  const { country = [] } = useSelector((state) => state.country);

  useEffect(() => {
    BccwDataDetail({
      params: {
        country: country,
        order_id: id,
        order_mode: 'All',
      },
      dispatch,
    });
  }, []);
  const { bccwDetail = [], isbccwLoading } = useSelector((state) => state.bccw);

  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  const orderData = bccwDetail?.OrderDetails?.[0];

  const itemData = bccwDetail.OrderDetails?.[0]?.orderitems;
  const splitDeviceTrack = orderData?.DeviceTracking?.split('||');

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>BCCW Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="BCCW Orders" breadcrumbItem="Order View" />
            </Col>
            <Col xl={6}>
              <Row className="align-items-end">
                <Col xl={12} className="textAlignRigh">
                  <div className="DashbordFliter ">
                    {splitDeviceTrack !== undefined ? (
                      <Button
                        color="dark"
                        className=""
                        onClick={toggleRightDrawer}
                        disabled={open}
                      >
                        Device Tracking
                      </Button>
                    ) : (
                      ''
                    )}
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <button type="button" className="btn SecondaryBtn2">
                        Send Sms
                      </button>
                      <span>
                        <WalletStatus />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>DEVICE TRACKING</CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                  {splitDeviceTrack?.map((device, deviceInx) => {
                    return (
                      <>
                        <span key={deviceInx} className="status">
                          {device}{' '}
                        </span>
                        <br />
                      </>
                    );
                  })}
                </p>
              </CardBody>
            </Card>
          </ReactDrawer>
          <RiverOrderDetails orderData={orderData} itemData={itemData} />
        </Container>
      </div>
    </>
  );
};

export default RiverHillOrderDetail;
