import { useState, useEffect, button } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, Placeholder } from 'rsuite';
import { orderBy } from 'lodash';
import React from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';
import Places from '../../../components/maps/GoogleMaps';
import { updateCoordinate } from '../../Orders/actions/orderActions';
import { nodeName } from 'rsuite/esm/DOMHelper';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { SORTING_ORDER, OMS_URL } from '../../../constants/index';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function OrderDetailsBlock({ orderData }) {
  const [dmsStsLoad, setDmsStsLoad] = useState(false);
  const [generatePaymentLoad, setgeneratePaymentLoad] = useState(false);
  const [generatePayfortPaymentLoad, setgeneratePayfortPaymentLoad] =
    useState(false);
  const [generatePostPayPaymentLoad, setgeneratePostPayPaymentLoad] =
    useState(false);
  const [updatecoordinateLoad, setupdateCoordinateLoad] = useState(false);

  const dispatch = useDispatch();
  const [isMap, setIsMap] = useState(false);
  const onMapClose = () => {
    setIsMap(false);
  };
  const onMapOpen = () => {
    setIsMap(true);
  };

  const [isCoordinates, setCoordinates] = useState(false);
  const Latitude = orderData?.ShippingDetails?.[0]?.Latitude;
  const Longitude = orderData?.ShippingDetails?.[0]?.Longitude;
  const entity_id = orderData?.OrderId;
  if (Latitude === 0 && Longitude === 0) {
    setCoordinates(true);
  }

  const handleUpdateCoordinatesApi = (Latitude, Longitude) => {
    setupdateCoordinateLoad(true);
    updateCoordinate({
      params: {
        entity_id: entity_id,
        latitude: Latitude,
        longitude: Longitude,
      },
      dispatch,
    });
  };

  const { updateLatLng = [], isLoading } = useSelector((state) => state.order);
  const { dms = [], dmsLoading } = useSelector((state) => state.order);
  const { paymentTap = [], paymentLoading } = useSelector(
    (state) => state.order
  );
  const { paymentPayfort = [], paymentPayfortLoading } = useSelector(
    (state) => state.order
  );
  const { paymentPostpay = [], paymentPostpayLoading } = useSelector(
    (state) => state.order
  );

  const { coordinates = [], coordinateLoading } = useSelector(
    (state) => state.order
  );

  const paymentInvoiceDetails = orderData?.PaymentInvoiceDetails;

  useEffect(() => {
    if (coordinates?.code === 100) {
      setupdateCoordinateLoad(false);
      toast.success(coordinates?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (coordinates?.message) {
        toast.error(coordinates?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [coordinates]);
  useEffect(() => {
    if (updateLatLng?.code === 100) {
      setIsMap(false);
    }
  }, [updateLatLng]);

  return (
    <>
      <Row className="row-fluid-scroll">
        <Col md={6} xl={4}>
          <Card className="OrderDetailsBox card-h-100">
            <CardHeader className="bg-light">
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
              <div className="paymentDetailSec">
                <CardBody>
                  <label>
                    <strong>{orderData?.CustomerName}</strong>
                  </label>

                  <label>{orderData?.ShippingDetails?.[0]?.Address1}</label>
                  <label>{orderData?.ShippingDetails?.[0]?.Address2}</label>
                  <label>
                    {orderData?.ShippingDetails?.[0]?.Country} -{' '}
                    {orderData?.ShippingDetails?.[0]?.City} -{' '}
                    {orderData?.ShippingDetails?.[0]?.Region}{' '}
                  </label>

                  <label>Email: {orderData?.Email}</label>

                  <label>
                    Phone: {orderData?.ShippingDetails?.[0]?.Telephone}
                  </label>
                  <a
                    href={`https://www.google.com/maps?q=${Latitude},${Longitude}`}
                    className="btn btn-dark btn-sm"
                    target="_blank"
                  >
                    {/* {orderData?.ShippingDetails?.[0]?.Latitude}, {orderData?.ShippingDetails?.[0]?.Longitude} */}
                    View Location Map
                  </a>

                  {isCoordinates ? (
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={onMapOpen}
                    >
                      {' '}
                      {coordinateLoading === true && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      Update Coordinates
                    </button>
                  ) : (
                    ''
                  )}
                </CardBody>
              </div>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={8} />
                </CardBody>
              </>
            )}
          </Card>
        </Col>
        <Col md={6} xl={4}>
          <Card className=" OrderDetailsBox card-h-100">
            <CardHeader className="bg-light">
              <CardTitle>PAYMENT DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
              <div className="paymentDetailSec">
                <CardBody>
                  <label>
                    Shipment Type: <strong>{orderData?.PaymentMode}</strong>
                  </label>

                  <label>Order Remarks:{orderData?.OrderRemarks}</label>
                </CardBody>
              </div>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={1} />
                </CardBody>
              </>
            )}
          </Card>
        </Col>
        <Col xl={4} md={6}>
          <Row>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>Vendor Details</CardTitle>
              </CardHeader>
              {orderData !== undefined ? (
                <>
                  <CardBody>
                    <img
                      alt=""
                      width="120"
                      height="120"
                      className="img-thumbnail"
                      src={
                        OMS_URL.BASE_URL +
                        'uploads/logo/' +
                        orderData?.Vendor +
                        '.png'
                      }
                    />
                  </CardBody>
                </>
              ) : (
                <>
                  <CardBody>
                    <Skeleton count={1} />
                  </CardBody>
                </>
              )}
            </Card>
          </Row>
          <Row>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>Shipping Details</CardTitle>
              </CardHeader>
              {orderData !== undefined ? (
                <>
                  <CardBody>
                    <strong>Shipment Type : </strong>{' '}
                    {orderData?.ShippingMethod}
                    <br />
                    <strong>Mode : </strong> {orderData?.DeliveryMode}
                  </CardBody>
                </>
              ) : (
                <>
                  <CardBody>
                    <Skeleton count={2} />
                  </CardBody>
                </>
              )}
            </Card>
          </Row>
        </Col>
      </Row>
      {isMap ? (
        <>
          <Row style={{ zIndex: 990 }}>
            <div>
              <button
                type="button"
                className="btn btn-danger btn-sm waves-effect waves-light"
                onClick={onMapClose}
                style={{ float: 'right', top: 30, right: 30, zIndex: 999 }}
              >
                Close
              </button>
            </div>
            <Col xl={4} md={6}>
              <Places handleUpdateCoordinatesApi={handleUpdateCoordinatesApi} />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
export default OrderDetailsBlock;
