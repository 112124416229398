import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import deliveryFile from '../../../../../assets/files/delivery.csv';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Button,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { CardHeader } from 'semantic-ui-react';
import { fetchCheckData, deliveryImportSummary } from '../../action/action';

const ImportFileComponent = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // Use ref to clear file input

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [updateStsLoad, setUpdateDetailsLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleValidSubmit = (events, values) => {
    const formData = new FormData();
    if (selectedFile === undefined || selectedFile === null) {
      setErrorMsg('Please select the file');
    } else {
      setUpdateDetailsLoad(true);
      formData.append('marketplace_file', selectedFile);

      fetchCheckData({
        formData,
        dispatch,
      });
    }
  };

  const { deliveryCheckdata = [], isCkeckdataLoading } = useSelector(
    (state) => state.deliveryReport
  );

  useEffect(() => {
    if (deliveryCheckdata?.code === 100) {
      setSuccessMessage(true);
      setUpdateDetailsLoad(false);
      deliveryImportSummary({
        params: {
          file_name: deliveryCheckdata?.file_name,
          user_id: '1',
        },
        dispatch,
      });
    } else {
      if (deliveryCheckdata?.message) {
        setUpdateDetailsLoad(false);
        toast.error(deliveryCheckdata?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [deliveryCheckdata]);

  const { importSummary = [], isImportSummaryLoading } = useSelector(
    (state) => state.deliveryReport
  );

  useEffect(() => {
    if (importSummary?.code === 100) {
      setUpdateDetailsLoad(false);
      setSelectedFile(null);

      toast.success(importSummary?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      window.location.reload(false);
    } else {
      if (importSummary?.message) {
        setUpdateDetailsLoad(false);

        toast.error(importSummary?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [importSummary]);

  // Function to clear the selected file, file input, and error message
  const clearFileSelection = () => {
    setSelectedFile(null); // Clear the selected file (content of `file`)
    setErrorMsg(''); // Clear the error message
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input value
    }
  };

  return (
    <React.Fragment>
      <Row className="rowCenter">
        <Col>
          <Card>
            <CardHeader className="bg-soft-light px-3 py-3 d-flex justify-content-between">
              <CardTitle className="m-0">Upload Delivery Report</CardTitle>
              <a
                href={deliveryFile}
                download={deliveryFile}
                className="btn btn-info"
              >
                <span className="d-flex gap-2 align-items-center">
                  <i className="bx bx-download"></i>Sample File To Download
                </span>
              </a>
            </CardHeader>
            <CardBody>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md={12}>
                    <div
                      className="border-2 border-light text-center p-5 rounded position-relative"
                      style={{ borderStyle: 'dashed' }}
                    >
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bx-cloud-upload"></i>
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                      <input
                        type="file"
                        name="file"
                        ref={fileInputRef} // Attach the ref to file input
                        required
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setSelectedFile(file); // Set the selected file
                          if (!file) {
                            setErrorMsg('No file selected');
                          } else {
                            setErrorMsg('');
                          }
                        }}
                        className="opacity-0 w-100 h-100 position-absolute top-0 start-0"
                      />
                    </div>
                    {/* Display the selected file name */}
                    {selectedFile && (
                      <div className="mt-3 text-start d-flex align-items-center justify-content-between gap-2 border border-success rounded bg-soft-success px-3 py-2">
                        <p className="m-0 text-success d-flex align-items-center justify-content-center gap-2">
                          <i className="mdi mdi-microsoft-excel text-success fs-24"></i>
                          <strong>Selected file:</strong> {selectedFile.name}
                        </p>
                        <i
                          className="mdi mdi-close-circle text-danger fs-24"
                          onClick={clearFileSelection} // Clear file on click
                          style={{ cursor: 'pointer' }}
                        ></i>
                      </div>
                    )}
                    {/* Error message if any */}
                    {errorMsg && (
                      <p className="text-danger mt-4 p-1 border border-danger rounded text-center">
                        {errorMsg}
                      </p>
                    )}
                  </Col>
                  <Col md={12}>
                    <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                      <Button color="dark" size="lg">
                        {updateStsLoad && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        SUBMIT
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              {successMessage === true && (
                <Row>
                  <Col md={12}>
                    <p className="bg-success my-3 py-2 text-center rounded text-white bg-success fs-14">
                      <b>
                        Delivery status updation will be reflected after some
                        time
                      </b>
                    </p>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default ImportFileComponent;
