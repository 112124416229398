import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import { useMutation, gql } from '@apollo/client';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { GET_WISHLIST_ADMIN } from './query/AddWishlist';

const AddWishListDetail = forwardRef((props, ref) => {
  const [isDataLoading, setDataLoading] = useState(false);

  const [addWishlistFunction, { errors, data, isLoading }] =
    useLazyQuery(GET_WISHLIST_ADMIN);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isGetWishlist, setIsGetWishlist] = useState(false);

  const editValue = data?.getWishlistAdmin;

  const getWishlistDataAdminFunc = () => {
    try {
      setDataLoading(true);
      addWishlistFunction({
        variables: {
          userId: id,
        },
        dispatch,
      });
    } catch {}
  };

  useEffect(() => {
    if (isGetWishlist) {
      getWishlistDataAdminFunc();
      if (editValue?.products?.length > 0) {
        setDataLoading(false);
        setIsGetWishlist(false);
      } else if (editValue?.products?.length === 0) {
        setDataLoading(false);
        setIsGetWishlist(false);
      }
    }
  }, [isGetWishlist, editValue]);

  useImperativeHandle(ref, () => ({
    log() {
      setIsGetWishlist(true);
    },
  }));

  return (
    <>
      <Card className="card-h-100 OrderDetailsBox border-0 p-0">
        <CardHeader className="border-bottom p-0 pb-2">
          <CardTitle>Items in Wishlist</CardTitle>
        </CardHeader>
        <CardBody className="px-0">
          <Row>
            {isLoading || isDataLoading ? (
              <Spinner className="ms-2 sploader" color="primary" />
            ) : null}
            {editValue?.products?.length > 0 ? (
              <>
                {editValue?.products?.map((products) => {
                  return (
                    <Col xs={3}>
                      <Card>
                        <CardBody className="p-2">
                          <div
                            className="d-flex gap-2 align-items-center"
                            nowrap="nowrap"
                          >
                            <img
                              alt=""
                              width="90"
                              height="90"
                              className="img-thumbnail1"
                              src={products?.media_gallery?.[0]?.value}
                            />
                            <div>
                              <p className="m-0">{products?.name}</p>
                              <p className="m-0">Sku : {products?._id}</p>
                              <p className="d-flex gap-1 align-items-center m-0">
                                <b>
                                  {products?.selectedPrice?.currency}{' '}
                                  {products?.selectedPrice?.specialPrice}
                                </b>
                                <del>
                                  {products?.defaultPrice?.specialPrice}
                                </del>
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </>
            ) : (
              <>
                <Col>
                  <div className="border-1 border-light bg-soft-light rounded d-flex flex-column align-items-center justify-content-center gap-1 p-4 p-5">
                    <img
                      src="https://images.ctfassets.net/058vu9hqon0j/1b28mU534LIbwZ3bpuaASF/31800fa822dd4787a40a2de408941eb2/empty_data_icon_149938.svg"
                      alt="No Record Found"
                    />
                    <h3 className="fs-18 fw-normal mt-3">No Record Found</h3>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
});
export default AddWishListDetail;
