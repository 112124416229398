import './AddPromotions.scss';
import React, { useState, useEffect } from 'react';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import giftSucessIcon from '.././../../../../../src/assets/images/giftSucessIcon.svg';
import {
  Row,
  Col,
  Button,
  Label,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { setCartDetails } from '../../../../../Redux/order/createOrderSlice';
import guestUserManagement from '../../../../../utils/Auth/guestUserManagement';
import {
  applyCoupon,
  removeCoupon,
  validateGiftCard,
  validateGiftCardPin,
  updateGiftCardInformation,
  applyCreditNote,
  getWalletBalance,
  generateWalletOTP,
  VerifyWalletOTP,
  setWalletInfo,
} from '../../actions/action';
import { setPageLoader } from '../../../../../Redux/order/createOrderSlice';
function AddPromotionsComponent(props) {
  const { cart = {}, showpromotion } = props || {};
  const couponCode = cart?.coupon?.code;
  const appliedGiftCardNumber = cart?.giftCard?.giftVoucherNumber;
  const appliedCreditNoteNumber = cart?.creditNote?.number;
  const appliedCreditNoteRedeemAmount = cart?.creditNote?.redeemedAmount;
  const validGiftCardInfoCart = cart?.giftCard;
  const { country = [] } = useSelector((state) => state.country);
  const [isGiftVoucherExist, setIsGiftVoucherExist] = useState(false);
  const [isGiftVoucherPinValidated, setIsGiftVoucherPinValidated] =
    useState(false);
  const [walletVerifyOTP, setWalletVerifyOTP] = useState(false);
  const [walletPin, setWalletPin] = useState(null);
  const [verifiedWalletID, setVerifiedWalletID] = useState(null);
  const [validGiftCardInfo, setValidGiftCardInfo] = useState();
  const [validCreditNoteInfo, setValidCreditNoteInfo] = useState();
  const [walletBalance, setWalletBalance] = useState(null);
  const [position, setPosition] = useState();
  const [couponCodeOption, setCouponCodeOption] = useState(false);
  const [giftCardOption, setGiftCardOption] = useState(false);
  const [walletOption, setWalletOption] = useState(false);
  const [creidtNoteOption, setCreditNoteOption] = useState(false);
  const [rewardsOption, setRewardsOption] = useState(false);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const onDrawerClose = () => {
    setOpen(false);
  };
  const GVOTP = (props) => (
    <InputMask
      mask="9 9 9 9"
      value={props.value}
      className="form-control input-color"
      onChange={props.onChange}
      name={props.name}
      type="text"
    ></InputMask>
  );

  const applyCouponCode = (e, v) => {
    e.preventDefault();
    //setIsLoading(true);
    const applyCartCoupon = async (v) => {
      dispatch(setPageLoader(true));
      const couponApplied = await applyCoupon(
        { couponCode: v.couponCode, cartMeta: [] },
        dispatch
      );
      if (couponApplied?._id) {
        dispatch(setPageLoader(false));
        SuccessToast('Coupon code Applied Successfully');
      } else {
        dispatch(setPageLoader(false));
        ErrorToast('Coupon code not valid');
      }
    };
    applyCartCoupon(v);
  };

  /**
   * Remove a cart coupon
   */
  const removeAppliedCoupon = (value) => {
    const removeCartCoupon = async () => {
      dispatch(setPageLoader(true));
      const coupenRemoved = await removeCoupon({}, dispatch);
      if (coupenRemoved) {
        dispatch(setPageLoader(false));
        SuccessToast('Coupon code Removed');
      } else {
        dispatch(setPageLoader(false));
        ErrorToast('Coupon code Remove error');
      }
    };
    removeCartCoupon(value);
  };

  //APPLY GIFTCARD
  const validateGiftVoucher = (e, v) => {
    e.preventDefault();
    //setIsLoading(true);
    const applyCartGiftCard = async (v) => {
      dispatch(setPageLoader(true));
      const giftCardInfo = await validateGiftCard(
        { giftCardNumber: v.giftCardNumber },
        dispatch
      );
      if (giftCardInfo?.giftVoucherNumber) {
        dispatch(setPageLoader(false));
        setIsGiftVoucherExist(true);
        setValidGiftCardInfo(giftCardInfo);
        //SuccessToast("Coupon code Applied Successfully");
      } else {
        dispatch(setPageLoader(false));
        ErrorToast('Gift Voucher not valid');
      }
    };
    applyCartGiftCard(v);
  };

  //VALIDATE EGIFT PIN
  const verifyGiftVoucherPin = (e, v) => {
    e.preventDefault();
    const verifyCartGiftCardPin = async (v) => {
      dispatch(setPageLoader(true));
      const giftcardPinValidate = await validateGiftCardPin(
        { giftCardNumber: validGiftCardInfo.giftVoucherNumber, pin: v.pin },
        dispatch
      );
      if (giftcardPinValidate?.giftVoucherNumber) {
        setIsGiftVoucherPinValidated(true);
        setCardInfoToCart(validGiftCardInfo);
      } else {
        dispatch(setPageLoader(false));
        ErrorToast('Gift Voucher pin not valid');
      }
    };
    verifyCartGiftCardPin(v);
  };

  const setCardInfoToCart = async () => {
    const cardInput = {
      currentBalance: validGiftCardInfo?.currentBalance || 0,
      giftVoucherNumber: validGiftCardInfo?.giftVoucherNumber || '',
      gvType: validGiftCardInfo?.gvType || '',
      validUpTo: validGiftCardInfo?.validUpTo || '',
    };
    const updatedCart = await updateGiftCardInformation(cardInput, dispatch);
    if (updatedCart?._id) {
      dispatch(setPageLoader(false));
      SuccessToast('Coupon code Applied Successfully');
    }
  };

  const removeAppliedGiftCard = async () => {
    if (validGiftCardInfoCart?.giftVoucherNumber) {
      // api call to set cart
      const unsetCardInfoToCart = async () => {
        const updatedCart = await updateGiftCardInformation({}, dispatch);
        dispatch(setPageLoader(false));
        if (updatedCart) {
          SuccessToast('Gift card removed Successfully');
        }
        resetAll();
      };
      dispatch(setPageLoader(true));
      unsetCardInfoToCart();
    }
  };

  const resetAll = async () => {
    setIsGiftVoucherExist(false);
    setIsGiftVoucherPinValidated(false);
  };

  //CREDIT NOTE
  const validateCreditNote = (e, v) => {
    e.preventDefault();
    const applyCartCreditNote = async (v) => {
      dispatch(setPageLoader(true));
      const creditNoteInfo = await applyCreditNote(
        { creditNoteNumber: v.creditNoteNumber },
        dispatch
      );
      if (creditNoteInfo?.creditNote?.number) {
        if (creditNoteInfo?.creditNote?.redeemedAmount > 0) {
          dispatch(setPageLoader(false));
          setValidCreditNoteInfo(creditNoteInfo);
          SuccessToast('Credit note Applied Successfully');
        } else if (creditNoteInfo?.creditNote?.redeemedAmount < 0) {
          dispatch(setPageLoader(false));
          setValidCreditNoteInfo(creditNoteInfo);
          ErrorToast(
            'Order Amount Greater than or Equal to Credit note amount'
          );
        }
      } else {
        dispatch(setPageLoader(false));
        ErrorToast('Credit Note Number Not valid');
      }
    };
    applyCartCreditNote(v);
  };

  //REMOVE CREDIT NOTE
  const removeAppliedCreditNote = () => {
    if (appliedCreditNoteNumber) {
      // api call to set cart
      const unsetCreditNoteInfoToCart = async () => {
        const updatedCart = await applyCreditNote(
          { creditNoteNumber: '' },
          dispatch
        );
        dispatch(setPageLoader(false));
        if (updatedCart) {
          SuccessToast('Credit Note removed Successfully');
        }
        resetAll();
      };
      dispatch(setPageLoader(true));
      unsetCreditNoteInfoToCart();
    }
  };
  const getWalletBalanceFunc = async () => {
    if (cart?.shippingAddress?.telephone) {
      let data = {
        country: country === 'all' ? 'ae' : country,
        mobileNumber: cart?.shippingAddress?.telephone,
        walletId: '',
      };
      let result = await getWalletBalance(data);

      if (result) {
        setWalletBalance(result);
      }
    }
  };

  useEffect(() => {
    getWalletBalanceFunc();
  }, [country, cart?.shippingAddress?.telephone]);

  const walletGenerateOTP = async () => {
    if (cart?.shippingAddress?.telephone) {
      let data = {
        country: country === 'all' ? 'ae' : country,
        phoneNumber: cart?.shippingAddress?.telephone,
      };
      let result = await generateWalletOTP(data);

      if (result) {
        SuccessToast(result?.message);
        // setVerifiedWalletID(result)
        setWalletVerifyOTP(true);
      }
    }
  };
  const setWalletInfoFunc = async () => {
    const guestCartToken = guestUserManagement.getGuestCartToken();
    let inputData = {
      walletId: walletBalance?.data?.walletId,
      isApplied: true,
      cartToken: guestCartToken,
    };
    const result = await setWalletInfo(inputData);
    if (result) {
      dispatch(setCartDetails(result || {}));
      getWalletBalanceFunc();
    } else {
    }
  };
  const walletVerifyOTPFunc = async () => {
    if (cart?.shippingAddress?.telephone) {
      let data = {
        country: country === 'all' ? 'ae' : country,
        otp: walletPin,
        phoneNumber: cart?.shippingAddress?.telephone,
      };
      let result = await VerifyWalletOTP(data);

      if (result) {
        SuccessToast(result?.message);
        setWalletVerifyOTP(false);
        setWalletInfoFunc();
      }
    }
  };

  return showpromotion ? (
    <>
      <Card className="promotionsContain">
        <CardHeader className="bg-light">
          <h6>Promotions</h6>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="d-flex gap-3 border-bottom mb-3">
              <Button
                color={`${couponCodeOption ? 'dark' : 'light'}`}
                outline={!couponCodeOption}
                className="shadow-none border-bottom-0 rounded-0 rounded-top"
                onClick={() => {
                  setCouponCodeOption(true);
                  setGiftCardOption(false);
                  setCreditNoteOption(false);
                  setWalletOption(false);
                  setRewardsOption(false);
                }}
              >
                <i className="bx bx-barcode"></i> Coupon Code
              </Button>

              <Button
                color={`${giftCardOption ? 'dark' : 'light'}`}
                outline={!giftCardOption}
                className="shadow-none border-bottom-0 rounded-0 rounded-top"
                onClick={() => {
                  setGiftCardOption(true);
                  setCreditNoteOption(false);
                  setWalletOption(false);
                  setRewardsOption(false);
                  setCouponCodeOption(false);
                }}
              >
                <i className="bx bx-gift"></i> Gift Card
              </Button>
              <Button
                color={`${creidtNoteOption ? 'dark' : 'light'}`}
                outline={!creidtNoteOption}
                className="shadow-none border-bottom-0 rounded-0 rounded-top"
                onClick={() => {
                  setCreditNoteOption(true);
                  setGiftCardOption(false);
                  setWalletOption(false);
                  setRewardsOption(false);
                  setCouponCodeOption(false);
                }}
              >
                <i className="mdi mdi-note-text-outline"></i> Credit Note
              </Button>
              <Button
                color={`${rewardsOption ? 'dark' : 'light'}`}
                outline={!rewardsOption}
                className="shadow-none border-bottom-0 rounded-0 rounded-top"
                onClick={() => {
                  setCreditNoteOption(false);
                  setGiftCardOption(false);
                  setWalletOption(false);
                  setRewardsOption(true);
                  setCouponCodeOption(false);
                }}
              >
                <i className=" fas fa-coins"></i> Rewards
              </Button>
              <Button
                color={`${walletOption ? 'dark' : 'light'}`}
                outline={!walletOption}
                className="shadow-none border-bottom-0 rounded-0 rounded-top"
                onClick={() => {
                  setCreditNoteOption(false);
                  setGiftCardOption(false);
                  setWalletOption(true);
                  setRewardsOption(false);
                  setCouponCodeOption(false);
                }}
              >
                <i className="bx bx-wallet"></i> Wallet
              </Button>
            </Col>
          </Row>
          <Row>
            {couponCodeOption ? (
              <Col>
                {couponCode ? (
                  <div className="alert bg-soft-success border-1 border-success p-2 mt-3">
                    <img
                      className="giftSucessIcon"
                      alt=""
                      src={giftSucessIcon}
                      width="20"
                      height="20"
                      decoding="async"
                      data-nimg="1"
                      loading="lazy"
                    ></img>
                    {couponCode}
                    <span
                      className="removeCoupon font-size-12"
                      onClick={() => {
                        removeAppliedCoupon(couponCode);
                      }}
                      style={{ textDecoration: 'underline' }}
                    >
                      <em>Remove</em>
                    </span>
                  </div>
                ) : (
                  <AvForm
                    onValidSubmit={applyCouponCode}
                    className="position-relative"
                  >
                    <div>
                      <AvField
                        name="couponCode"
                        label="Apply Coupon Code"
                        type="text"
                        required
                      />
                    </div>
                    <Button color="success" type="submit" className="mt-3">
                      Apply
                    </Button>
                  </AvForm>
                )}
              </Col>
            ) : null}
            {giftCardOption ? (
              <>
                {!appliedGiftCardNumber &&
                  !isGiftVoucherExist &&
                  !isGiftVoucherPinValidated && (
                    <Col>
                      <AvForm onValidSubmit={validateGiftVoucher}>
                        <AvField
                          name="giftCardNumber"
                          label="Gift Card"
                          type="text"
                          required
                          className="1111"
                        />
                        <Button color="success" type="submit" className="mt-3">
                          Apply
                        </Button>
                      </AvForm>
                    </Col>
                  )}

                {!appliedGiftCardNumber && isGiftVoucherExist && (
                  <Col>
                    <AvForm onValidSubmit={verifyGiftVoucherPin}>
                      {/* <GVOTP name="pin" /> */}
                      <AvField
                        name="pin"
                        label="Gift Card Pin"
                        type="text"
                        required
                        value=""
                      />
                      <span className="font-13 text-muted">
                        Enter 4 Digit OTP
                      </span>
                      <Button type="submit" className="btn btn-dark promoBtn">
                        Verify
                      </Button>
                    </AvForm>
                  </Col>
                )}
                {appliedGiftCardNumber && (
                  <Col>
                    <div className="alert alert-success mt-3">
                      {appliedGiftCardNumber}

                      <span
                        className="removeCoupon "
                        onClick={() => {
                          removeAppliedGiftCard(appliedGiftCardNumber);
                        }}
                      >
                        Remove
                      </span>
                    </div>
                  </Col>
                )}
              </>
            ) : null}
            {creidtNoteOption ? (
              <>
                {appliedCreditNoteRedeemAmount <= 0 && (
                  <Col>
                    <AvForm onValidSubmit={validateCreditNote}>
                      <AvField
                        name="creditNoteNumber"
                        label="Credit Note"
                        type="text"
                        required
                      />
                      <Button color="success" type="submit" className="mt-3">
                        Apply
                      </Button>
                    </AvForm>
                  </Col>
                )}
                {appliedCreditNoteNumber &&
                  appliedCreditNoteRedeemAmount > 0 && (
                    <Col>
                      <div className="alert alert-success mt-3">
                        {appliedCreditNoteNumber}

                        <span
                          className="removeCoupon "
                          onClick={() => {
                            removeAppliedCreditNote(appliedCreditNoteNumber);
                          }}
                        >
                          Remove
                        </span>
                      </div>
                    </Col>
                  )}
              </>
            ) : null}
            {walletOption ? (
              <>
                <Col className="wallet">
                  <strong>Wallet</strong>
                  <span className="walletAmount">
                    {walletBalance?.data?.currency}{' '}
                    {walletBalance?.data?.balance}
                  </span>
                  <span>Available Balance</span>
                </Col>
                {!walletVerifyOTP && (
                  <>
                    <Col>
                      <AvForm onValidSubmit={walletGenerateOTP}>
                        <AvField
                          name="walletRedeem"
                          label="Wallet Redeem Amount"
                          type="text"
                          value={walletBalance?.data?.balance || '0'}
                          readOnly
                        />
                        <Button color="success" type="submit" className="mt-3">
                          Apply
                        </Button>
                      </AvForm>
                    </Col>
                  </>
                )}
                {walletVerifyOTP && (
                  <Col>
                    <AvForm onValidSubmit={walletVerifyOTPFunc}>
                      {/* <GVOTP name="pin" /> */}
                      <AvField
                        name="pin"
                        label="Wallet PIN"
                        type="text"
                        required
                        value=""
                        onChange={(e) => {
                          setWalletPin(e?.target?.value);
                        }}
                      />
                      <span className="font-13 text-muted">
                        Enter 6 Digit OTP
                      </span>
                      <Button color="success" type="submit" className="mt-3">
                        Verify
                      </Button>
                    </AvForm>
                  </Col>
                )}
              </>
            ) : null}
          </Row>
          {/* <ReactDrawer
              open={open}
              position={position}
              onClose={onDrawerClose}
              className="createNewOrderPopup"
            ></ReactDrawer> */}
        </CardBody>
      </Card>
    </>
  ) : (
    ''
  );
}

export default AddPromotionsComponent;
