import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from 'react';

import { Row, Col, Alert, Container, Spinner } from 'reactstrap';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link, useHistory } from 'react-router-dom';
import Turnstile from 'react-turnstile';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { LoginUser, ResetUserPassword } from '../../store/actions';

// import images
import logo from '../../assets/images/logo-sm.svg';
import toast, { Toaster } from 'react-hot-toast';
//Import config
import { facebook, google } from '../../config';
import CarouselPage from './CarouselPage';
import { useCookies } from 'react-cookie';
import { setResetPassword } from '../../Redux/user/userSlice';
//const error = '';
const Login = (props) => {
  const SITE_KEY = '0x4AAAAAAAEdKjlfBcJ0OZIu';
  const dispatch = useDispatch();
  const history = useHistory();
  const { users = [], isLoading } = useSelector((state) => state.user);
  const [stsLoad, setStatusLoad] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [errorMsg, setErrorMessage] = useState();
  const [errorResetMsg, setErrorResetMessage] = useState();
  const [inputData, setInputData] = useState({});
  const [isCapchaVerified, setIsCapchaVerified] = useState(false);

  const [isChecked, setIsChecked] = useState(cookies?.checked);

  const [loginClick, setTriggerLogin] = useState(false);
  const [userName, setUserName] = useState('');
  const [isResetPwd, setIsResetPwd] = useState(false);
  const [resetPwdClick, setTriggerResetPwd] = useState(false);
  const [token, setToken] = useState(false);

  const handleValidSubmit = (events, values) => {
    setInputData(values);
    setTriggerLogin(true);
  };

  const handleValidResetPwdSubmit = (events, values) => {
    setInputData(values);
    setTriggerResetPwd(true);
  };
  const UserLoginFunc = async () => {
    let result = await LoginUser({
      params: {
        username: inputData?.email,
        password: inputData?.password,
        token: token,
      },
      dispatch,
    });
  };
  useEffect(() => {
    if (loginClick === true && isCapchaVerified) {
      UserLoginFunc();
    }
  }, [inputData, loginClick]);

  const resetPwdSubmit = async () => {
    let res = await ResetUserPassword({
      params: {
        username: userName,
        currentPassword: inputData?.oldPassword,
        newPassword: inputData?.newPassword,
        confirmPassword: inputData?.confirmPassword,
      },
      dispatch,
    });

    if (res?.code === 100) {
      setIsResetPwd(false);
    } else {
      setErrorResetMessage(res?.message);
    }
  };

  useEffect(() => {
    if (resetPwdClick === true) {
      setStatusLoad({ stsLoad: true });
      resetPwdSubmit();
    }
  }, [inputData, resetPwdClick]);

  useEffect(() => {
    setStatusLoad({ stsLoad: false });
    if (users?.code === 100) {
      setLoggeedInUser(users?.data);
      if (isChecked && users?.data) {
        var now = new Date();
        now.setDate(now.getDate() + 30); // timestamp
        now = new Date(now);
        setCookie('Name', inputData?.email, { path: '/', expires: now });
        setCookie('Password', inputData?.password, { path: '/', expires: now });
        setCookie('checked', isChecked, { path: '/', expires: now });
      }

      if (loginClick === true) {
        if (users?.data?.country.length > 2) {
          let [a, b] = users?.data?.country.split(',');
          localStorage.setItem('WEBSITE_COUNTRY', a.toLowerCase());
        } else {
          localStorage.setItem(
            'WEBSITE_COUNTRY',
            users?.data?.country.toLowerCase()
          );
        }
        // history.push("/dashboard");
        // setCookie('Password', '', { path: '/', expires: new Date(0) });
      }

      setTriggerLogin(false);
    } else if (users?.code === 104) {
      setIsResetPwd(true);
      setTriggerLogin(false);
      console.log('expired');
    } else {
      setTriggerLogin(false);
      const errMessage = users?.message;
      setErrorMessage(errMessage);
    }
  }, [users]);

  const setLoggeedInUser = async (users) => {
    await localStorage.setItem('authUser', JSON.stringify(users));
    history.push('/dashboard');
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Danube Home OMS Dashboard</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              {!isResetPwd ? (
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logo} alt="" height="23" />{' '}
                          <span className="logo-txt">Danube Home OMS</span>
                        </Link>
                      </div>

                      <div
                        className="auth-content"
                        style={{ marginTop: '20%' }}
                      >
                        <div className="text-center">
                          <h5 className="mb-0">Welcome Back !</h5>
                          <p className="text-muted mt-2">
                            Sign in to continue to Danube Home OMS.
                          </p>
                        </div>
                        <AvForm
                          className="custom-form mt-4 pt-2"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {errorMsg ? (
                            <Alert color="danger">{errorMsg}</Alert>
                          ) : null}
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              value=""
                              className="form-control"
                              placeholder="Enter email / Username"
                              type="text"
                              onChange={(e) => {
                                setUserName(e.target.value);
                              }}
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <label className="form-label">Password</label>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="">
                                  <Link
                                    to="/forgot-password"
                                    className="text-muted"
                                  >
                                    Forgot password?
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="password"
                                value={cookies?.Password}
                                type="password"
                                className="form-control"
                                required
                                placeholder="Enter Password"
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck2"
                                name="checked"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="formCheck2"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <Turnstile
                              sitekey={SITE_KEY}
                              onVerify={(token) => {
                                setIsCapchaVerified(true);
                                setToken(token);
                              }}
                              onError={(error) => {
                                console.log(error);
                                setIsCapchaVerified(false);
                              }}
                              onExpire={() => {
                                setIsCapchaVerified(false);
                              }}
                              onTimeout={() => {
                                setIsCapchaVerified(false);
                              }}
                              onLoad={() => {}}
                            />
                          </div>
                          <div className="mb-3">
                            <button
                              className="btn btn-dark w-100 waves-effect waves-light"
                              type="submit"
                            >
                              {' '}
                              {stsLoad === true && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Log In
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // {errorMsg ? (
                //   <Alert color="danger">{errorMsg}</Alert>
                // ) : null}
                //  {/* Update Your Password */}
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logo} alt="" height="28" />
                          <span className="logo-txt">Danube Home OMS</span>
                        </Link>
                      </div>

                      <div className="auth-content my-auto">
                        <div className="text-center">
                          <h5 className="mb-0">Your Password is Expired</h5>
                          <p className="text-muted mt-2">
                            Please update your password below.
                          </p>
                        </div>
                        {errorResetMsg ? (
                          <Alert color="danger">{errorResetMsg}</Alert>
                        ) : null}
                        <AvForm
                          className="custom-form mt-4"
                          onValidSubmit={(e, v) =>
                            handleValidResetPwdSubmit(e, v)
                          }
                        >
                          <div className="mb-3">
                            <AvField
                              name="username"
                              type="hidden"
                              value={cookies?.Name}
                            />
                            <AvField
                              name="oldPassword"
                              label="Current Password"
                              className="form-control"
                              placeholder="Current Password"
                              type="password"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="newPassword"
                              label="New Password"
                              className="form-control"
                              placeholder="New Password"
                              type="password"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="confirmPassword"
                              label="Confirm Password"
                              className="form-control"
                              placeholder="Confirm Password"
                              type="password"
                              required
                            />
                          </div>
                          <div className="mb-3 mt-4">
                            <button
                              className="btn btn-dark w-100 waves-effect waves-light"
                              type="submit"
                            >
                              Update Password
                            </button>
                          </div>
                        </AvForm>

                        <div className="mt-5 text-center">
                          <p className="text-muted mb-0">
                            Remember It ?{' '}
                            <a href="/login" className="text-dark fw-semibold">
                              {' '}
                              Sign In{' '}
                            </a>{' '}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} oms.danubehome.com.
                          Crafted with{' '}
                          <i className="mdi mdi-heart text-danger"></i> by
                          danubehome.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
