import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { fetchOrderLogDetail } from '../actions/orderActions';
import { Col, Row } from 'reactstrap';

function OrderLogReport({ orderData }) {
  const { ref } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    fetchOrderLogDetail({
      params: {
        order_reference: ref,
      },
      dispatch,
    });
  }, []);

  const { orderLogDetail = [], isOrderLogLoading } = useSelector(
    (state) => state.order
  );
  let result;
  try {
    result = JSON.parse(orderLogDetail?.data?.[0]?.request_data);
    console.log(result);
  } catch (err) {
    // 👇️ This runs
    console.log('Error: ', err.message);
  }

  return (
    <Row style={{ marginTop: '100px', marginRight: '50px' }}>
      <Col>
        <ReactJson src={result} />
      </Col>
      <Col>
        <button
          className="btn btn-dark"
          style={{ display: 'flex' }}
          onClick={() => history.push('/orders')}
        >
          Back
        </button>
      </Col>
    </Row>
  );
}
export default OrderLogReport;
