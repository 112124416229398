import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  Input,
  Form,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { UserRole, UserRoleResource } from '../../../store/auth/login/actions';
import { useDispatch, useSelector } from 'react-redux';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CheckTree } from 'rsuite';
import './style.scss';
import { UpdateRoleView, UpdateRoleData } from '../action/action';
import { useParams } from 'react-router-dom';

//Import Breadcrumb
import { DashBreadcrumb } from '../../../components/Common/Breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import classnames from 'classnames';

const EditNewRole = () => {
  const [roleData, setRoleData] = useState(false);
  const [roleVal, setRolevalues] = useState([]);
  const [roleValue, setValue] = React.useState([]);
  const groupParent = ['1', '2', '11', '14', '17'];

  const dispatch = useDispatch();

  const { roles = [], isLoading } = useSelector((state) => state.user);

  const RoleData = roles?.data;
  const handleActionClick = () => {
    UserRole({
      params: {
        country: 'AE',
      },

      dispatch,
    });
  };

  useEffect(() => {
    if (roles?.code === 100) {
      setRoleData(true);
      var tempProps = JSON.parse(JSON.stringify(roles?.data));
      const datas = Object.preventExtensions(tempProps);
      setRolevalues(datas);
    } else {
    }
  }, [roles]);
  const location = useLocation();
  const pathSpilt = location?.pathname.split('/');
  console.log('pathSpilt-----------', pathSpilt[1]);
  let isShowBred = 1;
  if (pathSpilt[1] !== 'NewRoleForm') {
    isShowBred = 0;
  }

  const [verticalActiveTab, setverticalActiveTab] = useState('1');

  const [headerTab, setHeaderTab] = useState('1');
  const [headerPillsTab, setHeaderPillsTab] = useState('1');
  const { id } = useParams();
  const newArray = [];
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  //

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  const handleValidSubmit = (events, values) => {
    const role_name = values?.role_name ? values?.role_name : '';
    const is_active = values?.is_active ? values?.is_active : '';

    UpdateRoleData({
      params: {
        type: 'edit',
        role_name: role_name,
        role_id: id,
        is_active: is_active,
        resources: newArray,
        created_by: 1,
      },
      dispatch,
    });
  };
  const { updateRole = [], isUpdateRoleLoading } = useSelector(
    (state) => state.permissions
  );

  useEffect(() => {
    if (updateRole?.code === 100) {
      // setUpdateDetailsLoad(false);
      toast.success(updateRole?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (updateRole?.message) {
        toast.error(updateRole?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [updateRole]);

  useEffect(() => {
    UpdateRoleView({
      params: {
        role_id: id,
      },
      dispatch,
    });
  }, []);
  const { UserMenuView = [], isMenuViewLoading } = useSelector(
    (state) => state.permissions
  );

  const userView = UserMenuView?.data?.role?.[0];
  const userViewResource = UserMenuView?.data?.resource;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col xl={10}>
            <DashBreadcrumb title="Role Details" breadcrumbItem="Roles" />
          </Col>
          {/* Render Breadcrumbs */}

          <Row>
            <Col>
              <Row>
                <Col md="3">
                  <Card>
                    <Nav pills className="flex-column">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '1',
                          })}
                          onClick={() => {
                            toggleVertical('1');
                          }}
                        >
                          Role Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className={classnames({
                            'mb-2': true,
                            active: verticalActiveTab === '2',
                          })}
                          onClick={() => {
                            toggleVertical('2');
                            handleActionClick();
                          }}
                        >
                          Role Resourses
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Card>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <React.Fragment>
                        <Col xl={9}>
                          <Card>
                            <CardBody>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  console.log(e, v);
                                  handleValidSubmit(e, v);
                                }}
                              >
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        name="role_name"
                                        label="RoleName"
                                        value={userView?.role_name}
                                        className="form-control"
                                        placeholder=""
                                        type="text"
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        type="select"
                                        name="is_active"
                                        label="Status"
                                        className="form-select"
                                        value={userView?.is_active}
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Button color="dark" type="submit">
                                  Save User
                                </Button>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </React.Fragment>
                    </TabPane>
                    <TabPane tabId="2">
                      <h4>Role Resourse</h4>

                      {roleData ? (
                        <>
                          <CheckTree
                            data={roleVal}
                            defaultExpandAll
                            defaultValue={userViewResource}
                            showIndentLine
                            getChildren
                            renderValue={(
                              values,
                              checkedItems,
                              selectedElement
                            ) => {
                              newArray.length = 0;
                              values.forEach((ele) => {
                                newArray.push(ele);
                              });
                              checkedItems.forEach((element) => {
                                if ('children' in element) {
                                  element?.children.forEach((elem) => {
                                    newArray.push(elem?.value);
                                  });
                                }
                              });
                            }}
                            onExited={() => {}}
                          />
                        </>
                      ) : (
                        <>
                          <p>Loading ...</p>
                        </>
                      )}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default EditNewRole;
