import React, { useState } from 'react';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import {
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
const DHPagination = (props) => {
  const { totalPages, currentPage, setPaginationValue, setLimitValue } = props;
  const [paginateOpen, setPaginateOpen] = useState(false);
  const limitByValues = [5, 10, 20, 50];
  const [pageLimit, setPageLimit] = useState(10);
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    localStorage.setItem('PageNumber', e.target.value);
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
    setPageLimit(Number(e));
  };

  return (
    <div className="dataTblFooter" nowrap="nowrap">
      <div>
        <Dropdown
          isOpen={paginateOpen}
          toggle={() => setPaginateOpen(!paginateOpen)}
          style={{ width: 'max-content' }}
        >
          <DropdownToggle
            tag="button"
            className="btn btn-dark"
            nowrap="nowrap"
            sytyle={{ width: '30px' }}
          >
            {pageLimit} Items <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            {limitByValues.map((limit, i) => {
              return (
                <DropdownItem
                  key={i}
                  href="#"
                  className="DropdownItem1"
                  onClick={() => handleLimit(limit)}
                >
                  {limit} Items
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-center">
        <Pagination
          id="listPage"
          value={currentPage}
          totalPages={totalPages}
          onChange={(e) => handlePagination(e)}
        />
      </div>
    </div>
  );
};
export default DHPagination;
