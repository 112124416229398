import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  Input,
  Form,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Select from 'react-select';
import CsLineIcons from '../../../cs-line-icons/CsLineIcons';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { fetchUserRoleLists } from '../action/action';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Link, useHistory } from 'react-router-dom';
import { CreateNewUser } from '../action/action';
import { SORTING_ORDER } from '../../../constants/index';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import toast, { Toaster } from 'react-hot-toast';
import { getUserRoleLists } from '../../../Redux/permissions/permissionsSlice';
import { fetchUserRolemaster } from '../action/action';

//Import Breadcrumb
import { DashBreadcrumb } from '../../../components/Common/Breadcrumb';
import classnames from 'classnames';

const UserForm = () => {
  const dispatch = useDispatch();
  const [newRole, setPlacedNewRole] = useState();
  const [activeDate, setActiveDate] = useState();
  const [activeValue, setActiveValue] = useState();
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [verticalActiveTab, setverticalActiveTab] = useState('1');

  const [headerTab, setHeaderTab] = useState('1');
  const [headerPillsTab, setHeaderPillsTab] = useState('1');
  const history = useHistory();

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  let UserRoleID = '';
  let UserID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserRoleID = userObj?.role_id;
      UserID = userObj?.user_id;
    }
  }

  useEffect(() => {
    fetchUserRolemaster({
      params: {
        role_id: UserRoleID,
      },
      dispatch,
    });
  }, []);

  const { UserRoleMaster = [], isLoadingMaster } = useSelector(
    (state) => state.permissions
  );
  const orderData = UserRoleMaster?.Data;

  const handleNewUser = (events, values) => {
    const firstname = values?.firstname ? values?.firstname : '';
    const lastname = values?.lastname ? values?.lastname : '';
    const username = values?.username ? values?.username : '';
    const email = values?.email ? values?.email : '';
    const Userpass = values?.Userpass ? values?.Userpass : '';
    const password1 = values?.password1 ? values?.password1 : '';
    const is_active = activeValue;
    const expirationdate = activeDate;
    const userCountry = [];
    selectedCountry?.map((selCountry) => {
      userCountry.push(selCountry?.value);
    });
    const password = values?.password ? values?.password : '';
    CreateNewUser({
      params: {
        firstname: firstname,
        lastname: lastname,
        username: username,
        email: email,
        password: password,
        confirmpassword: password1,
        is_active: is_active,
        expirationdate: expirationdate,
        role_id: newRole,
        user_id: UserID,
        currentuserpass: Userpass,
        country: userCountry,
      },
      dispatch,
    });
  };
  const { Users = [], isUsersLoading } = useSelector(
    (state) => state.permissions
  );

  useEffect(() => {
    if (Users?.code === 100) {
      // setUpdateDetailsLoad(false);
      toast.success(Users?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      setTimeout(() => {
        history.push('/permission');
      }, 10);
    } else {
      if (Users?.message) {
        toast.error(Users?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [Users]);
  const optionMulti = [
    { label: 'UAE', value: 'AE' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Oman', value: 'OM' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Qatar', value: 'QA' },
  ];
  const handleCountryMultiSelection = (newselectedOptions) => {
    setSelectedCountry(newselectedOptions);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row style={{ marginTop: '6rem' }}>
            <Col>
              <DashBreadcrumb title="Users Detail" breadcrumbItem="User" />
            </Col>
          </Row>

          <Row>
            <Col>
              <AvForm
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleNewUser(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <Card>
                      <Nav pills className="flex-column">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              'mb-2': true,
                              active: verticalActiveTab === '1',
                            })}
                            onClick={() => {
                              toggleVertical('1');
                            }}
                          >
                            User Info
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Card>
                  </Col>
                  <Col md="9">
                    <TabContent
                      activeTab={verticalActiveTab}
                      className="text-muted mt-4 mt-md-0"
                    >
                      <TabPane tabId="1">
                        <React.Fragment>
                          <Col xl={9}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom01">
                                        First name
                                      </Label>
                                      <AvField
                                        name="firstname"
                                        placeholder="First name"
                                        type="text"
                                        errorMessage="Enter First Name"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom01"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Last name
                                      </Label>
                                      <AvField
                                        name="lastname"
                                        placeholder="Last name"
                                        type="text"
                                        errorMessage="Enter Last name"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom02"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom01">
                                        User Name
                                      </Label>
                                      <AvField
                                        name="username"
                                        placeholder="Type Something"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom03">
                                        Email
                                      </Label>
                                      <AvField
                                        name="email"
                                        placeholder="Enter Valid Email"
                                        type="email"
                                        errorMessage="Invalid Email"
                                        validate={{
                                          required: { value: true },
                                          email: { value: true },
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        Password
                                      </Label>
                                      <AvField
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        errorMessage="Enter password"
                                        validate={{ required: { value: true } }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        Confirmed password
                                      </Label>
                                      <AvField
                                        name="password1"
                                        type="password"
                                        placeholder="Re-type Password"
                                        errorMessage="Enter Re-password"
                                        validate={{
                                          required: { value: true },
                                          match: { value: 'password1' },
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup className="mb-3">
                                      <Label>Expiration Date</Label>
                                      <Input
                                        className="form-control"
                                        name="expirationdate"
                                        type="date"
                                        onChange={(e) =>
                                          setActiveDate(e.target.value)
                                        }
                                        id="example-datetime-local-input"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup className="mb-3">
                                      <label
                                        htmlFor="choices-single-no-search"
                                        className="form-label font-size-13 text-muted"
                                      >
                                        Options added This account is
                                      </label>
                                      <select
                                        className="form-control"
                                        name="is_active"
                                        id="choices-single-no-search"
                                        onChange={(e) =>
                                          setActiveValue(e.target.value)
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom04">
                                        Current User Identity Verification
                                      </Label>
                                      <AvField
                                        name="Userpass"
                                        type="password"
                                        placeholder="Password"
                                        errorMessage="Enter password"
                                        validate={{ required: { value: true } }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <AvField
                                      type="select"
                                      name="role"
                                      label="User Role*"
                                      className="form-select"
                                      onChange={(e) =>
                                        setPlacedNewRole(e.target.value)
                                      }
                                      required
                                    >
                                      <option>Select the Role</option>
                                      {orderData?.map((ele, im) => (
                                        <option value={ele.role_id} key={im}>
                                          {ele.role_name}
                                        </option>
                                      ))}
                                    </AvField>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col md="6" classname="mt-2">
                                    <Label>Associate to Website</Label>
                                    <Select
                                      defaultValue=""
                                      isMulti
                                      name="country"
                                      options={optionMulti}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(value) =>
                                        handleCountryMultiSelection(value)
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Button color="dark" type="submit">
                                  Save User
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                        </React.Fragment>
                      </TabPane>
                      {/* <TabPane tabId="2">
                                                <Col xl={6} md={6}>
            
                    <AvField
                      type="select"
                      name="role"
                      label="User Role*"
                      className="form-select"
                    onChange={(e) => setPlacedNewRole(e.target.value)}
                      required
                    >
                      <option>Select the Role</option>
                      {orderData?.map((ele,im) => (
                        <option value={ele.role_id} key={im}>{ele.role_name}</option>
                      ))}
                    </AvField>
                
                    </Col>
             
             
                             
                                                 
                                                </TabPane> */}
                    </TabContent>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
};

export default UserForm;
