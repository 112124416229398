import React from 'react';
import axios from 'axios';
import OrderProgressbar from '../../Orders/components/OrderProgressbar';
import MarketplaceDetailsBlock from './MarketplaceDetailsBlock';
import MarketplaceNotebyAdmin from './MarketplaceNotebyAdmin';
import MarketplaceOrderItemDetails from './MarketplaceOrderItemDetails';
import MarketPlaceInvoice from './MarketPlaceinvoice';
import { useDispatch, useSelector } from 'react-redux';

import { useState, useEffect, button } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from 'reactstrap';
import moment from 'moment';

function MarketplaceDetails({ orderData, itemData }) {
  let cdate = orderData?.OrderUpdatedDate,
    orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
    orderTime = moment(new Date(cdate)).format('h:mm:ss a');
  return (
    <>
      <Card className="card-dh card-h-100">
        <CardBody className="card-body-dh">
          <Row>
            <Col>
              Order Detail : Ref# <strong>{orderData?.OrderNo}</strong> / Order
              Date :
              <strong>
                {orderDt} {orderTime}
              </strong>
              Status:
              <strong>{orderData?.orderitems?.[0]?.LsStatus} </strong>
            </Col>
          </Row>

          <Row>
            <Col xl={9} md={7}>
              <MarketplaceDetailsBlock orderData={orderData} />
            </Col>

            <Col xl={3} md={5}>
              <MarketPlaceInvoice orderData={orderData} />
              {/*  <OrderUpdateDetailsForm orderData={orderData} /> */}
            </Col>
          </Row>
          <Col>
            <MarketplaceOrderItemDetails
              itemData={itemData}
              orderData={orderData}
            />
          </Col>

          <Row>
            <Col xl={9} md={7}>
              <MarketplaceNotebyAdmin orderData={orderData} />
            </Col>
            <Col xl={3} md={5}></Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default MarketplaceDetails;
