import { useState, useEffect, button } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, Placeholder } from 'rsuite';
import { orderBy } from 'lodash';
import React from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';
import Places from '../../../components/maps/GoogleMaps';
import { BccwDataDetail } from '../action/action';
import { updateCoordinate } from '../../Orders/actions/orderActions';
import { nodeName } from 'rsuite/esm/DOMHelper';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function ManageRiverHillOrderBlock({ orderData }) {
  const [dmsStsLoad, setDmsStsLoad] = useState(false);

  const [updatecoordinateLoad, setupdateCoordinateLoad] = useState(false);

  const dispatch = useDispatch();
  const [isMap, setIsMap] = useState(false);
  const onMapClose = () => {
    setIsMap(false);
  };
  const onMapOpen = () => {
    setIsMap(true);
  };

  const [isCoordinates, setCoordinates] = useState(false);
  const Latitude = orderData?.ShippingDetails?.[0]?.Latitude;
  const Longitude = orderData?.ShippingDetails?.[0]?.Longitude;
  const entity_id = orderData?.OrderId;
  if (Latitude === 0 && Longitude === 0) {
    setCoordinates(true);
  }

  const handleUpdateCoordinatesApi = (Latitude, Longitude) => {
    setupdateCoordinateLoad(true);
    updateCoordinate({
      params: {
        entity_id: entity_id,
        latitude: Latitude,
        longitude: Longitude,
      },
      dispatch,
    });
  };

  const { updateLatLng = [], isLoading } = useSelector((state) => state.order);
  const { dms = [], dmsLoading } = useSelector((state) => state.order);

  const { coordinates = [], coordinateLoading } = useSelector(
    (state) => state.order
  );
  const { checkpayment = [], ischeckPaymentLoading } = useSelector(
    (state) => state.order
  );
  const { paymentInvoice = [], ischeckpaymentInvoiceLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    if (coordinates?.code === 100) {
      setupdateCoordinateLoad(false);
      toast.success(coordinates?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
    } else {
      if (coordinates?.message) {
        toast.error(coordinates?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [coordinates]);
  useEffect(() => {
    if (updateLatLng?.code === 100) {
      setIsMap(false);
    }
  }, [updateLatLng]);

  const renderPaymentDetils = (payData) => {
    const paymentMode = payData?.PaymentMode;
    const paymentGateway = payData?.Gateway;
    const paymentStatus = payData?.FinancialStatus;

    {
      return (
        <div>
          <span className="status">
            <b>{paymentGateway}</b>
          </span>
          <br />
          <br />
          <span className="status">
            {' '}
            <b>Payment provider :</b>
            {paymentMode}{' '}
          </span>
          <br />
          <span className="status">
            <b>Status :</b>
            <span
              style={
                paymentStatus === '0' ? { color: 'red' } : { color: 'green' }
              }
            >
              {paymentStatus === '0' ? 'Failed' : 'Success'}
            </span>
          </span>
        </div>
      );
    }
  };
  const renderShippingDetails = (shippingData) => {
    const ShippingMethod = shippingData?.ShippingMethod;
    const ShippingCharge = shippingData?.ShippingCharge;

    return (
      <div>
        <span className="status">
          <b>Shipping Type :</b> {ShippingMethod}
        </span>
        <br />
      </div>
    );
  };
  return (
    <>
      <Row className="row-fluid-scroll">
        <Col xl={4} md={6}>
          <Card className="card-h-100 OrderDetailsBox">
            <CardHeader>
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
              <>
                <CardBody>
                  <p>
                    <strong>{orderData?.CustomerName}</strong>
                    <br />
                    {orderData?.ShippingDetails?.[0]?.Address1}
                    <br />
                    {orderData?.ShippingDetails?.[0]?.Address2}
                    <br />
                    {orderData?.ShippingDetails?.[0]?.City}
                    <br />
                    <b>Email : </b>
                    {orderData?.Email}
                    <br />
                    <b>Phone :</b>
                    {orderData?.ShippingDetails?.[0]?.Telephone}
                    <br />
                    <a
                      href={`https://www.google.com/maps?q=${Latitude},${Longitude}`}
                      className="btn btn-dark btn-sm"
                      target="_blank"
                    >
                      {/* {orderData?.ShippingDetails?.[0]?.Latitude}, {orderData?.ShippingDetails?.[0]?.Longitude} */}
                      View Location Map
                    </a>
                  </p>
                  {!isCoordinates ? (
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={onMapOpen}
                    >
                      {' '}
                      {coordinateLoading === true && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      Update Coordinates
                    </button>
                  ) : (
                    ''
                  )}
                </CardBody>
              </>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={8} />
                </CardBody>
              </>
            )}
          </Card>
        </Col>
        <Col xl={4} md={6}>
          <Card className="card-h-100 OrderDetailsBox">
            <CardHeader>
              <CardTitle>PAYMENT DETAILS</CardTitle>
            </CardHeader>
            <CardBody>
              {orderData !== undefined ? (
                <>{renderPaymentDetils(orderData)}</>
              ) : (
                <>
                  <Skeleton count={5} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} md={6}>
          <Row>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader>
                <CardTitle>SHIPPING DETAILS</CardTitle>
              </CardHeader>
              <CardBody>
                {orderData !== undefined ? (
                  <>{renderShippingDetails(orderData)}</>
                ) : (
                  <>
                    <Skeleton count={5} />
                  </>
                )}
              </CardBody>
            </Card>
          </Row>
        </Col>
      </Row>
      {isMap ? (
        <>
          <Row style={{ zIndex: 990 }}>
            <div>
              <button
                type="button"
                className="btn btn-danger btn-sm waves-effect waves-light"
                onClick={onMapClose}
                style={{ float: 'right', top: 30, right: 30, zIndex: 999 }}
              >
                Close
              </button>
            </div>
            <Col xl={4} md={6}>
              <Places handleUpdateCoordinatesApi={handleUpdateCoordinatesApi} />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
export default ManageRiverHillOrderBlock;
