import { useState, useEffect, useSearchParams, useRef } from 'react';
import { MetaTags } from 'react-meta-tags';
import { DashBreadcrumb } from '../../../components/Common/Breadcrumb';
import 'react-drawer/lib/react-drawer.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/themes/material_blue.css';
import ApolloClientKnowledgeBaseService from '../../../ApolloClientKBService';
import { CREATE_DEPARTMENT } from '../../Customers/query/AddDepartment';
import { Col, Container, Card, CardBody, Row, Spinner } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
const AddNewDepartment = () => {
  const [categoryName, setCategoryName] = useState('');
  const [kbCreateDepartmentData, setCreateDepartmentData] = useState('');
  const [depLoad, setDepLoad] = useState(false);
  let userName = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userName = userObj?.user_name;
    }
  }
  const createDepartmentData = async (events, values) => {
    setDepLoad(true);
    try {
      const { data } = await ApolloClientKnowledgeBaseService.mutate({
        mutation: CREATE_DEPARTMENT,
        variables: {
          input: {
            name: values?.name_e,
            slug: values?.slug,
            host: values?.host,
            icon: values?.icon,
            createdBy: userName,
            updatedBy: userName,
          },
        },
        fetchPolicy: 'network-only',
      });
      setCreateDepartmentData(data);
    } catch (error) {}
  };
  const depCreateData = kbCreateDepartmentData?.createDepartments;

  useEffect(() => {
    if (depCreateData?._id !== undefined) {
      setDepLoad(false);
      toast.success('Department Successfully Created', {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      setDepLoad('');
    } else {
      if (depCreateData?._id === '') {
        setDepLoad(false);
        toast.error('Department Not Created', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [depCreateData]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Articles</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <DashBreadcrumb
                title="Department"
                breadcrumbItem="Add New Department"
              />

              <Row>
                <Col md={4}>
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          createDepartmentData(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="name_e"
                            label="Department Name "
                            placeholder="Type Something"
                            type="text"
                            value=""
                            onChange={(e) => {
                              setCategoryName(e?.target?.value);
                            }}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="slug"
                            label="Slug*"
                            type="text"
                            value={categoryName
                              ?.replace(/[^A-Z0-9]+/gi, '-')
                              ?.toLowerCase()}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="host"
                            label="Host*"
                            type="text"
                            value=""
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="icon"
                            label="Icon*"
                            type="text"
                            value=""
                          />
                        </div>

                        <button type="submit" className="btn btn-dark">
                          {depLoad && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}{' '}
                          Submit
                        </button>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default AddNewDepartment;
