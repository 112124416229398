import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import moment from 'moment';
import { fetchOrdersCouponSummary } from '../actions';
import { SessionsByCountriesCharts } from './couponChart';
const CouponOrdersDashboard = ({ startDate, endDate }) => {
  const { country = [] } = useSelector((state) => state.country);
  const [couponSummaryData, setCouponSummaryData] = useState(null);

  const getCouponsSummary = async () => {
    let isSuccess = await fetchOrdersCouponSummary({
      params: {
        country: country === 'all' ? 'ae' : country,
        date_from:
          startDate === ''
            ? moment(new Date()).format('YYYY-MM-DD')
            : moment(new Date(startDate)).format('YYYY-MM-DD'),
        date_to:
          endDate === ''
            ? moment(new Date()).format('YYYY-MM-DD')
            : moment(new Date(endDate)).format('YYYY-MM-DD'),
      },
    });

    if (isSuccess) {
      setCouponSummaryData(isSuccess);
    } else {
    }
  };
  useEffect(() => {
    getCouponsSummary();
  }, [startDate, endDate]);
  return (
    <Col xxl={4} xl={6}>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Orders Coupons Summary
          </h4>
          {/* <div>
                        <Button color="secondary" size="sm" className="btn-soft-secondary me-1 shadow-none">
                            ALL
                        </Button>
                        <Button color="primary" size="sm" className="btn-soft-primary me-1 shadow-none">
                            1M
                        </Button>
                        <Button color="secondary" size="sm" className="btn-soft-secondary shadow-none">
                            6M
                        </Button>
                    </div> */}
        </CardHeader>
        <CardBody className="p-0">
          <div>
            <div id="countries_charts" className="apex-charts" dir="ltr">
              {/* Sessions by Countries */}
              <SessionsByCountriesCharts
                dataColors='["--vz-info", "--vz-info", "--vz-info", "--vz-info", "--vz-danger", "--vz-info", "--vz-info", "--vz-info", "--vz-info", "--vz-info"]'
                couponSummaryList={couponSummaryData?.data?.couponList}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CouponOrdersDashboard;
