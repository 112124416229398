import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import toast, { Toaster } from 'react-hot-toast';
import { Card, Form } from 'react-bootstrap';
import 'flatpickr/dist/themes/material_blue.css';
import { Badge } from 'reactstrap';
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { Link, BrowserRouter } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import {
  fetchInstallationOrders,
  fetchSplitStatusCount,
  getOrderDeleteDate,
} from '../actions/orderActions';
import { OrderDeleteSlice } from '../../../Redux/order/orderSlice';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { SORTING_ORDER } from '../../../constants/index';
import android from '../../../assets/images/android.png';
import huawei from '../../../assets/images/huawei.png';
import apple from '../../../assets/images/apple.png';
import owndd from '../../../assets/images/user-3-fill-1.svg';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import '../style.scss';
import { DateRangePicker } from 'rsuite';
import DeliveryAlert from './DeliveryAlert';
import {
  Table,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  NavLink,
  Nav,
  NavItem,
  Modal,
} from 'reactstrap';
import ExpressDelivery from './Shipping/ExpressDelivery';
const OrderInstallationList = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobileView = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 1279 && isMobileView === 0) {
    isMobileView++;
  }

  const location = useLocation();
  const search = useLocation().search;
  const paramsCountry = new URLSearchParams(search).get('cn');
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedShippingCharge, setSelectedShippingCharge] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [isDeviceOrPaymentUpdated, setIsDeviceOrPaymentUpdated] =
    useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [togglePopup, setTogglePopup] = useState(false);
  const [togglePassword, setTogglePassword] = useState('');
  const [deleteButtonLoad, setDeleteButtonLoad] = useState(false);
  const [orderEntityId, setOrderEntityId] = useState('');
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });

  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [previousFromValue, setPreviousFromValue] = useState(null);

  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: '_date',
    order: SORTING_ORDER.ASC,
  });
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });

  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [statusValue, setStatusValue] = useUrlSearchParams({
    activeTab: 'All',
  });

  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState('');

  const { afterToday } = DateRangePicker;
  const [orderMode, setOrderMode] = useState('All');
  const [isViewItemsVal, setViewActionLoad] = useState('');

  const [ActiveFilter, setActiveFilter] = useState(false);
  const [ActiveGrid, setActiveGrid] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);

  const [orderFilterType, setOrderFilterType] = useState('');
  const [differenceInDays, setDifferenceInDays] = useState(null);
  const { country = [] } = useSelector((state) => state.country);
  const [expandedOrders, setExpandedOrders] = useState({});

  const handleShowMore = (orderIndex) => {
    setExpandedOrders((prevState) => ({
      ...prevState,
      [orderIndex]: true,
    }));
  };
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }
  useEffect(() => {
    if (previousFromValue !== location?.state?.from) {
      setPreviousFromValue(location?.state?.from);
      resetState();
    }
  }, [location?.state?.from]);

  const resetState = () => {
    setPosition();
    setOpen(false);
    setSelectedStatus(null);
    setSelectedDevice(null);
    setSelectedVersion(null);
    setBtnprimary1(false);
    setIsDeviceOrPaymentUpdated(false);
    setPreviousFromValue(null);
    setSearchValue({ search: '' });
    setApiCall(0);
    setStartDate('');
    setEndDate('');
  };

  let is_split = 0;

  const splitVal = location?.pathname.split('/');
  useEffect(() => {
    if (splitVal[1] === 'splitorders') {
      localStorage.setItem('splitType', JSON.stringify(selectedStatus));
      is_split = 1;
      setOrderMode('split');
      localStorage.setItem('splitType', is_split);
    } else if (splitVal[1] === 'b2borders') {
      is_split = 2;
      setOrderMode('b2b');
      localStorage.setItem('splitType', is_split);
    } else if (splitVal[1] === 'storeorders') {
      is_split = 3;
      setOrderMode('store');
      localStorage.setItem('splitType', is_split);
    } else {
      is_split = 0;
      setOrderMode('All');
      localStorage.setItem('splitType', is_split);
    }
  }, [splitVal]);

  let isSplitFinal = '';
  let splitType = JSON.parse(localStorage.getItem('splitType'));

  const [isApiCall, setApiCall] = useState(0);

  let currentCountry = localStorage.getItem('WEBSITE_COUNTRY');

  let cntry = currentCountry;

  useEffect(() => {
    setDataLoading(true);

    if (isApiCall === 0) {
      let filterType = JSON.parse(localStorage.getItem('splitType'));
      if (orderFilterType !== '' && orderFilterType !== undefined) {
        filterType = orderFilterType;
      }

      fetchInstallationOrders({
        params: {
          country: paramsCountry ? paramsCountry : cntry,
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
          deviceFilter: selectedDevice?.value,
          sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
          search_qry: searchValue.search,
          status: statusValue.activeTab,
          paymentFilter: selectedPayment?.value,
          is_split: filterType,
          order_source: selectedVersion?.value,
          date_from: startDate,
          date_to:
            endDate === undefined || endDate === '' ? startDate : endDate,
          type_charge: selectedShippingCharge?.value,
        },
        dispatch,
      });
      setTimeout(() => setApiCall(0), 8000);
    }
    if (isDeviceOrPaymentUpdated === true) {
      setIsDeviceOrPaymentUpdated(false);
    }
    setDataLoading(false);
  }, [
    paginationValue.page,
    limitValue.limit,
    sortingValue.sort,
    searchValue.search,
    statusValue.activeTab,
    isDeviceOrPaymentUpdated,
    orderFilterType,
  ]);

  const limitByValues = [5, 10, 20, 50];
  const { installationlist = [], isLoading } = useSelector(
    (state) => state.order
  );

  const { listOrders } = installationlist;
  const orderData = listOrders?.orderList;

  useEffect(() => {
    if (installationlist?.code === 100) {
      setApiCall(1);
    } else {
    }
  }, [listOrders]);
  const { splitStatus = [], issplitLoading } = useSelector(
    (state) => state.order
  );

  const splitData = splitStatus?.data;

  let LsSts = 'New';

  const optionGroup = [
    {
      label: 'Payment Methods',
      options: [
        { label: 'All', value: 'All' },
        { label: 'PostPay', value: 'postpay' },
        { label: 'PayFort', value: 'payfort' },
        { label: 'Payfort Installments', value: 'payfort_fort_installments' },
        { label: 'Tamara', value: 'tamara' },
        { label: 'Payfort Apple Pay', value: 'apple_pay' },
        { label: 'Apple Pay', value: 'apple_pay' },
        { label: 'Tabby', value: 'tabby' },
        { label: 'Tap', value: 'tap' },
        { label: 'Tap Careem Pay', value: 'tap_careem_pay' },
        { label: 'Tap Apple Pay', value: 'tap_apple_pay' },
        { label: 'Checkout', value: 'checkout' },
        { label: 'Spotii', value: 'spotiipay' },
        { label: 'COD', value: 'cashondelivery' },
        { label: 'Tap Knet', value: 'tap_knet' },
        { label: 'Payfort Omannet', value: 'payfort_omannet' },
        { label: 'Cashew', value: 'cashew' },
        { label: 'Oman Net', value: 'oman_net' },
        { label: "'Synapse", value: 'synapse' },
        { label: "'IFIN", value: 'ifin' },
      ],
    },
  ];
  const optionGroup1 = [
    {
      label: 'Device',
      options: [
        { label: 'All', value: 'All' },
        { label: 'App', value: 'phone' },
        { label: 'Mobile Web', value: 'mobile' },
        { label: 'Desktop', value: 'Desktop' },
      ],
    },
  ];
  const optionGroup2 = [
    {
      label: 'Version',
      options: [
        { label: '1.0', value: '0' },
        { label: '2.0', value: '1' },
      ],
    },
  ];
  let optionGroup3 = [];
  if (cntry === 'ae' || cntry === 'all') {
    optionGroup3 = [
      {
        label: 'Order Type',
        options: [
          { label: 'Ware House', value: '4' },
          { label: 'Courier', value: '5' },
          { label: 'Sanitary', value: '7' },
          { label: 'Back to Back', value: '2' },
          { label: 'Click & Collect', value: '3' },
          { label: 'Gift Card', value: '6' },
        ],
      },
    ];
  } else {
    optionGroup3 = [
      {
        label: 'Order Type',
        options: [
          { label: 'Click & Collect', value: '3' },
          { label: 'Gift Card', value: '6' },
        ],
      },
    ];
  }
  const optionGroup4 = [
    {
      label: 'Shipping Charge',
      options: [
        { label: 'Shipping Amt', value: 'shipping_amt' },
        { label: 'Discount Amt', value: 'discount_amt' },
        { label: 'Giftcard Amt', value: 'giftcard_amt' },
        { label: 'Assembly Amt', value: 'assembly_amt' },
        { label: 'Express Shipping Amt', value: 'express_shipping_amt' },
      ],
    },
  ];
  let SplitType = JSON.parse(localStorage.getItem('splitType'));
  const AdvanceFilterOnClick = (filterStatus) => {
    if (filterStatus === false) {
      setActiveFilter(true);
      setPosition('right');
      setOpen(!open);
    } else {
      setActiveFilter(false);
    }
  };
  const AdvanceGridOnClick = (gridStatus, entityID, index) => {
    setViewActionLoad(index);
    if (gridStatus === false) {
      fetchSplitStatusCount({
        params: {
          entity_id: entityID,
        },
        dispatch,
      });
      setActiveGrid(true);
    } else {
      setActiveGrid(false);
    }
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
    localStorage.setItem('PageNumber', e.target.value);

    setApiCall(0);
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
    setApiCall(0);
  };

  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
    setPaginationValue({ page: 0 });
    setApiCall(0);
  };

  const handleSelectPayment = (selectedGroup) => {
    setSelectedPayment(selectedGroup);
    setApiCall(0);
  };

  const handleSelectDevice = (selectedGroup) => {
    setSelectedDevice(selectedGroup);
    setApiCall(0);
  };
  const handleSelectVersion = (selectedGroup) => {
    setSelectedVersion(selectedGroup);
    setApiCall(0);
  };
  const handleSelectShippingCharge = (selectedGroup) => {
    setSelectedShippingCharge(selectedGroup);
    setApiCall(0);
  };
  const handleSelectOrderType = (selectedGroup) => {
    setSelectedOrderType(selectedGroup);
    setApiCall(0);
  };

  const toggleCustom = (tab) => {
    setStatusValue({ activeTab: tab });
    setApiCall(0);
  };

  const AdvanceFilterClear = (filterStatus) => {
    setApiCall(0);
    setActiveFilter(false);
    setSelectedDevice(null);
    setSelectedPayment(null);
    setSearchValue({ search: '' });

    setStartDate('');
    setEndDate('');
  };
  const [isActive, setIsActive] = useState(true);

  const handleClick = () => {
    setIsActive((current) => !current);
  };
  const renderPaymentMethods = (paymentMode) => {
    var PayMode = '';
    if (paymentMode === 'payfort') {
      PayMode = 'Payfort';
    } else if (paymentMode === 'payfort_fort_cc') {
      PayMode = 'Payfort';
    } else if (paymentMode === 'postpay') {
      PayMode = 'Postpay';
    } else if (paymentMode === 'payfort_fort_installments') {
      PayMode = 'Payfort Installments';
    } else if (paymentMode === 'tamara') {
      PayMode = 'Tamara';
    } else if (paymentMode === 'tap') {
      PayMode = 'Tap';
    } else if (paymentMode === 'tap_careem_pay') {
      PayMode = 'Tap Careem Pay';
    } else if (paymentMode === 'tap_apple_pay') {
      PayMode = 'Tap Apple Pay';
    } else if (paymentMode === 'checkout') {
      PayMode = 'Checkout';
    } else if (paymentMode === 'spotiipay') {
      PayMode = 'Spotii';
    } else if (paymentMode === 'tap_knet') {
      PayMode = 'Tap Knet';
    } else if (paymentMode === 'apple_pay') {
      PayMode = 'Apple Pay';
    } else if (paymentMode === 'payfort_omannet') {
      PayMode = 'Payfort Omannet';
    } else if (paymentMode === 'cashew') {
      PayMode = 'Cashew';
    } else if (paymentMode === 'oman_net') {
      PayMode = 'Oman Net';
    } else if (paymentMode === 'tabby') {
      PayMode = 'Tabby';
    } else if (paymentMode === 'ifin') {
      PayMode = 'Ifin - Loan';
    } else if (paymentMode === 'synapse') {
      PayMode = 'Synapse - By Bank';
    }
    if (paymentMode !== 'cashondelivery') {
      return <Badge className="badge-soft-dark">{PayMode}</Badge>;
    } else {
      return <></>;
    }
  };
  let userID = '';
  let UserRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserRoleID = userObj?.role_id;
      userID = userObj?.user_id;
    }
  }
  const OrderDetailFunc = () => {
    setDeleteButtonLoad(true);
    getOrderDeleteDate({
      params: {
        entity_id: orderEntityId,
        user_id: userID,
        password: togglePassword,
      },
      dispatch,
    });
  };
  const { orderDelete = [], isOrderDeleteLoading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    if (orderDelete?.code === 100) {
      setDeleteButtonLoad(false);
      toast.success(orderDelete?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      setTogglePassword('');
      setmodal_backdrop(false);
      setTogglePopup(false);
      dispatch(OrderDeleteSlice(null));
      setTimeout(() => {
        history.push('/orders');
      }, 10);
    } else {
      if (orderDelete?.message) {
        setDeleteButtonLoad(false);
        toast.error(orderDelete?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
        setTogglePassword('');
        setmodal_backdrop(false);
        setTogglePopup(false);
        dispatch(OrderDeleteSlice(null));
      }
    }
  }, [orderDelete]);

  let pageIndex = Number(listOrders?.start);

  return (
    <>
      <Row className="justify-content-between align-items-center dh-TabRow">
        <Col md={7} sm={6} xs={12} className=" overflow-y-hidden">
          <div className="align-items-center"></div>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="MobileView">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
                className={isActive ? 'searchBar' : ''}
              />
              <i
                className="bx bx-search-alt search-icon"
                onClick={handleClick}
              />
            </div>
          </div>
        </Col>
      </Row>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
        className="sideNavWidth"
      >
        <Card
          className="card-h-100 OrderDetailsBox"
          style={{ overflowY: 'scroll' }}
        >
          <CardHeader>
            <Row className="mb-3">
              <Col>
                <CardTitle>Advance Filter </CardTitle>
              </Col>
              <Col className="textAlignRight">
                {' '}
                <button
                  type="button"
                  className="btn btn-outline-info
                    waves-effect waves-light "
                  onClick={() => {
                    AdvanceFilterClear(ActiveFilter);
                  }}
                >
                  Clear Filter{' '}
                </button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <>
              {' '}
              <Row>
                <Col md={6}>
                  <div className="mb-3" style={{ position: 'relative' }}>
                    <div>
                      <label
                        htmlFor="choices-single-default"
                        className="form-label font-size-13 text-muted"
                      >
                        Date Range
                      </label>
                    </div>
                    <div>
                      <>
                        {isMobileView !== 0 ? (
                          <>
                            <DateRangePicker
                              placement="auto"
                              placeholder="Select Date Range"
                              showOneCalendar
                              // disabledDate={afterToday()}
                              onChange={(selectedDates, instance) => {
                                const dateStr = selectedDates?.[0];
                                const first_Date = new Date(
                                  dateStr
                                )?.toString();
                                const firstD = moment(
                                  new Date(first_Date)
                                )?.format('YYYY/MM/DD');
                                const firstDateReplace = firstD?.replaceAll(
                                  '/',
                                  '-'
                                );
                                const dateStr1 = selectedDates?.[1];
                                const second_Date = new Date(
                                  dateStr1
                                )?.toString();
                                const secondD = moment(
                                  new Date(second_Date)
                                )?.format('YYYY/MM/DD');
                                const secondDateReplace = secondD?.replaceAll(
                                  '/',
                                  '-'
                                );
                                setStartDate(firstDateReplace);
                                setEndDate(secondDateReplace);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <DateRangePicker
                              placement="autoVerticalStart"
                              placeholder="Select Date Range"
                              // disabledDate={afterToday()}
                              onChange={(selectedDates, instance) => {
                                const dateStr = selectedDates?.[0];
                                const first_Date = new Date(
                                  dateStr
                                )?.toString();
                                const firstD = moment(
                                  new Date(first_Date)
                                )?.format('YYYY/MM/DD');
                                const firstDateReplace = firstD?.replaceAll(
                                  '/',
                                  '-'
                                );
                                const dateStr1 = selectedDates?.[1];
                                const second_Date = new Date(
                                  dateStr1
                                )?.toString();
                                const secondD = moment(
                                  new Date(second_Date)
                                )?.format('YYYY/MM/DD');
                                const secondDateReplace = secondD?.replaceAll(
                                  '/',
                                  '-'
                                );
                                setStartDate(firstDateReplace);
                                setEndDate(secondDateReplace);
                              }}
                            />{' '}
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Payment Method
                    </label>
                    <Select
                      value={selectedPayment}
                      onChange={handleSelectPayment}
                      options={optionGroup}
                      defaultValue={selectedPayment}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
              {}
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Device
                    </label>
                    <Select
                      value={selectedDevice}
                      onChange={handleSelectDevice}
                      options={optionGroup1}
                      defaultValue={selectedDevice}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Version
                    </label>
                    <Select
                      value={selectedVersion}
                      onChange={handleSelectVersion}
                      options={optionGroup2}
                      defaultValue={selectedVersion}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Type of Orders
                    </label>
                    <Select
                      value={selectedOrderType}
                      onChange={handleSelectOrderType}
                      options={optionGroup3}
                      defaultValue={selectedOrderType}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      Shipping Charge
                    </label>
                    <Select
                      value={selectedShippingCharge}
                      onChange={handleSelectShippingCharge}
                      options={optionGroup4}
                      defaultValue={selectedShippingCharge}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
              <button
                className="btn btn-success"
                onClick={() => {
                  setIsDeviceOrPaymentUpdated(true);
                  onDrawerClose();
                  setOrderFilterType(selectedOrderType?.value);
                }}
              >
                Apply
              </button>
            </>
            {/* ) : null} */}
          </CardBody>
        </Card>
      </ReactDrawer>

      <div className=" dt-table">
        {isLoading || isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}
        {orderData?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Reference No</th>
                    <th>Name</th>
                    <th>Grand Total</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {(orderData || []).map((order, index) => {
                  const viewMoreBtn = `itv-${index}`;
                  pageIndex++;

                  let deliveryDt = order.delivery_dt ? order.delivery_dt : null;

                  // Or using the conditional operator in a more concise way:

                  let order_id = order.entity_id;

                  let split = 'All';
                  let orderCn = order.country.toLowerCase();
                  const orderSplits = order.reference_no?.slice(0, 2);
                  if (
                    (orderMode === 'split' && country === 'ae') ||
                    orderSplits === 'WH' ||
                    orderSplits === 'CR' ||
                    orderSplits === 'ST'
                  ) {
                    split = 'split';
                    order_id = order.order_id;
                    orderCn = 'ae';
                  } else if (
                    (orderMode === 'b2b' && country === 'ae') ||
                    orderSplits === 'BB'
                  ) {
                    split = 'b2b';
                    order_id = order.order_id;
                    orderCn = 'ae';
                  } else if (
                    orderMode === 'store' &&
                    (country === 'ae' || orderCn === 'ae')
                  ) {
                    split = 'store';
                    order_id = order.entity_id;
                    orderCn = 'ae';
                  } else if (
                    orderMode === 'store' &&
                    country !== 'ae' &&
                    orderCn !== 'ae'
                  ) {
                    split = 'All';
                    order_id = order.entity_id;
                  }
                  let cdate = order.created_at,
                    orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                    orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                  let stsBtn = 'bg-soft-dark text-dark';
                  let lsOrderSts = '';
                  if (!!order.ls_status) {
                    lsOrderSts = order.ls_status.toLowerCase().trim();
                  } else {
                    lsOrderSts = order.ls_status;
                  }

                  const webOrderSts = order.status.toLowerCase().trim();
                  if (
                    lsOrderSts === '0' ||
                    lsOrderSts === '' ||
                    lsOrderSts === null
                  ) {
                    if (webOrderSts === 'canceled' || lsOrderSts === 'cancel') {
                      LsSts = 'Canceled';
                      stsBtn = 'bg-danger';
                    } else if (webOrderSts === 'pending_payment') {
                      LsSts = 'Pending Payment';
                      stsBtn = 'bg-soft-danger text-danger';
                    } else if (webOrderSts === 'pending') {
                      LsSts = 'Pending';
                      stsBtn = 'bg-soft-warning text-warning';
                    } else {
                      LsSts = 'New';
                      stsBtn = 'bg-soft-info text-info';
                    }
                  } else if (
                    splitType === 3 &&
                    lsOrderSts === 'material_received'
                  ) {
                    LsSts = 'Material Received';
                    stsBtn = 'bg-warning';
                  } else if (
                    webOrderSts === 'canceled' ||
                    lsOrderSts === 'cancel'
                  ) {
                    LsSts = 'Canceled';
                    stsBtn = 'bg-danger';
                  } else if (lsOrderSts === 'open') {
                    stsBtn = 'bg-soft-warning text-warning';
                    LsSts = 'Pending';
                  } else if (lsOrderSts === 'accepted') {
                    stsBtn = 'bg-dark';
                    LsSts = 'Accepted';
                  } else if (lsOrderSts === 'confirmed') {
                    stsBtn = 'bg-dark';
                    LsSts = 'Confirmed';
                  } else if (lsOrderSts === 'posted') {
                    stsBtn = 'bg-primary';
                    LsSts = 'Posted';
                  } else if (lsOrderSts === 'material n/a') {
                    stsBtn = 'bg-warning';
                    LsSts = 'Material N/A';
                  } else if (lsOrderSts === 'delivered') {
                    stsBtn = 'bg-success';
                    LsSts = 'Delivered';
                  } else if (lsOrderSts === 'completed') {
                    stsBtn = 'bg-success';
                    LsSts = 'Completed';
                  } else if (lsOrderSts === 'picked') {
                    stsBtn = 'bg-warning';
                    LsSts = 'Picked';
                  } else if (lsOrderSts === 'delivery_scheduled') {
                    stsBtn = 'bg-soft-success';
                    LsSts = 'Delivery Scheduled';
                  } else if (lsOrderSts === 'rescheduled') {
                    stsBtn = 'bg-soft-success';
                    LsSts = 'Rescheduled';
                  } else if (lsOrderSts === 'partially_delivered') {
                    stsBtn = 'bg-warning';
                    LsSts = 'Partially Delivered';
                  } else if (lsOrderSts === 'out_for_delivery') {
                    stsBtn = 'bg-soft-success';
                    LsSts = 'Out For Delivery';
                  } else if (lsOrderSts === 'packed') {
                    LsSts = 'Packed';
                    stsBtn = ' bg-dark';
                  } else {
                    LsSts = 'New';
                    stsBtn = 'bg-soft-dark';
                  }

                  let paymentMode = '',
                    paymentIcon = '';
                  if (order.payment_mode !== 'cashondelivery') {
                    paymentIcon = 'fa-credit-card';
                    paymentMode = order.payment_mode;
                  }
                  let device_data = '';
                  let deviceArray = '';
                  let deviceVersion = '';
                  let osData = '';
                  let device_Version = '';
                  let deviceTypeValue = '';

                  if (order?.device !== null || order?.device !== undefined) {
                    let deviceData = order?.device_tracking;
                    deviceArray = deviceData?.split('||');
                    deviceTypeValue = deviceArray?.reduce((value, item) => {
                      if (item?.startsWith('deviceType:')) {
                        value = item?.split(':')[1].trim();
                      }
                      return value;
                    }, null);

                    if (order?.os === 'android' || order?.os === 'ios') {
                      deviceVersion = deviceArray[9]?.split(':');

                      device_data =
                        deviceTypeValue === 'huawei'
                          ? deviceTypeValue
                          : order?.os;
                      device_Version = deviceVersion?.[1];
                    } else {
                      if (deviceData && deviceData !== undefined) {
                        if (deviceArray && deviceArray?.length > 0) {
                          if (deviceArray[9]) {
                            deviceVersion = deviceArray[9]?.split(':');
                            device_Version = deviceVersion?.[1];
                          }
                        }
                      }

                      device_data = '';
                    }
                  }
                  let isWeb = '';
                  var installdata = '';
                  let deData = '';
                  if (order.device === 'phone') {
                    let appData = order.device_tracking;
                    const appArray = appData.split('||');
                    if (typeof appArray[12] !== 'undefined') {
                      const appVersion = appArray[12]?.slice(
                        appArray[12].indexOf(':') + 1
                      ); //appArray[12].split(":");
                      if (
                        typeof appVersion !== 'undefined' &&
                        appVersion != ''
                      ) {
                        const objInstall = JSON.parse(appVersion);
                        if (
                          typeof objInstall?.number_of_installment !==
                          'undefined'
                        ) {
                          const percentInstall =
                            objInstall.fee_display_value / 100;
                          installdata =
                            objInstall.number_of_installment +
                            'Month EMI' +
                            ' ' +
                            objInstall.amountPerMonth +
                            '/Month';
                        }
                      }
                    }
                  } else {
                    if (
                      order.device === '' ||
                      order.device === 'web' ||
                      order.device === 'Desktop' ||
                      order.device === 'Mobile'
                    ) {
                      if (deviceVersion === 'web') {
                        deData = 'mdi mdi-laptop-windows';
                      }
                      if (deviceVersion === 'Desktop') {
                        deData = 'mdi mdi-laptop-windows';
                      }
                      if (deviceVersion === 'Mobile') {
                        deData = 'mdi mdi-laptop-windows';
                      }

                      if (order.os === 'Windows') {
                        osData = 'mdi mdi-microsoft-windows';
                      } else if (order.os === 'Mac OS') {
                        osData = 'mdi mdi-apple';
                      } else if (order.os === 'Linux') {
                        osData = 'mdi mdi-linux';
                      } else {
                        osData = 'mdi mdi-web';
                      }

                      isWeb = 'Web';
                      if (typeof order.device_tracking !== 'undefined') {
                        const appVersion = order.device_tracking.slice(
                          order.device_tracking.indexOf(':') + 1
                        );
                        if (
                          typeof appVersion !== 'undefined' &&
                          appVersion != ''
                        ) {
                          try {
                            const objInstall = JSON.parse(appVersion);
                            if (
                              typeof objInstall.number_of_installment !==
                              'undefined'
                            ) {
                              installdata =
                                objInstall.number_of_installment +
                                ' Month Installment';
                            }
                          } catch (e) {
                            installdata = '';
                          }
                        }
                      }
                    }
                  }

                  if (device_Version === 'web') {
                    deData = 'mdi mdi-laptop-windows';
                  } else if (device_Version === 'Desktop') {
                    deData = 'mdi mdi-laptop-windows';
                  } else if (device_Version === 'Mobile') {
                    if (order.os.toLowerCase() === 'android') {
                      deData = 'mdi mdi-cellphone';
                      osData = 'mdi mdi-android-debug-bridge';
                    } else if (order.os.toLowerCase() === 'ios') {
                      deData = 'mdi mdi-cellphone';
                      osData = 'mdi mdi-apple';
                    } else {
                      deData = 'mdi mdi-laptop-windows';
                    }
                  }
                  let installemntPayment = '';
                  let appDataPayment = '';
                  let installemnt_months = '';
                  if (order?.is_installment === '1') {
                    appDataPayment = order?.payment_info;
                    installemntPayment = JSON?.parse(appDataPayment);
                    const installment_details =
                      installemntPayment?.installment_details;
                    if (installment_details !== undefined) {
                      if (Object.keys(installment_details).length > 0) {
                        installemnt_months =
                          installemntPayment?.installment_details
                            ?.number_of_installment +
                          ' Month EMI ' +
                          ' ' +
                          installemntPayment?.installment_details
                            ?.amountPerMonth +
                          '/Month';
                      }
                    } else if (installemntPayment?.additionalInfo) {
                      installemnt_months =
                        installemntPayment?.additionalInfo
                          ?.number_of_installment +
                        ' Month EMI ' +
                        ' ' +
                        installemntPayment?.additionalInfo?.amountPerMonth +
                        '/Month';
                    }
                  }

                  const createdDateObj = moment(
                    new Date(order.created_at)
                  ).startOf('day');

                  const deliveryDateObj = moment(
                    new Date(order?.delivery_dt)
                  ).startOf('day');
                  const daysDifference = deliveryDateObj.diff(
                    createdDateObj,
                    'days'
                  );
                  let badgeColor = '';
                  if (daysDifference > 9) {
                    badgeColor = 'bg-danger';
                  } else {
                    badgeColor = 'bg-warning';
                  }

                  let paymentCls = '';
                  if (
                    order?.financial_status === '0' &&
                    order?.payment_mode !== 'cashondelivery' &&
                    (order?.ls_status !== 'Cancel' ||
                      order?.status !== 'cancel') &&
                    order?.status !== 'canceled'
                  ) {
                    paymentCls = '';
                  }

                  return (
                    <tbody key={order?.order_id} className={`${paymentCls}`}>
                      <tr>
                        <th scope="row">{pageIndex} </th>

                        <td>
                          <p className="m-0 d-flex gap-1">
                            <strong>{order?.reference_no} </strong>
                            <span className="text-capitalize d-flex gap-1">
                              {order?.payment_mode === 'cashondelivery' ? (
                                <Badge className="badge-soft-dark">COD</Badge>
                              ) : (
                                <Badge className="badge-soft-dark">
                                  CC{' '}
                                  <i
                                    className={`badge-soft-dark fs-10 fas ${paymentIcon}`}
                                  ></i>
                                </Badge>
                              )}
                            </span>
                          </p>
                          <span className="highlightInfo2 ">
                            {order?.created_at}
                          </span>
                        </td>

                        <td>
                          {order?.full_name}
                          <span className="noTxtWrap" title={order?.region}>
                            {order?.country}-{order?.region}
                          </span>
                        </td>

                        <td>
                          {order?.currency} {order?.grand_total}
                        </td>
                        <td>{order?.status}</td>
                        <td>
                          <Col>
                            <BrowserRouter forceRefresh={true}>
                              <Link
                                to={`marketplacesplitorders/${order?.id}?order_mode=split`}
                                className="btn btn-sm btn-outline-warning waves-effect waves-light mr-2"
                              >
                                <i className="mdi mdi-eye-outline"></i>{' '}
                              </Link>
                            </BrowserRouter>
                          </Col>
                        </td>
                      </tr>

                      {viewMoreBtn === isViewItemsVal && ActiveGrid === true ? (
                        <>
                          {splitStatus?.code === 100 ? (
                            <>
                              <tr>
                                {splitData.map((val, key) => {
                                  return (
                                    <td key={key} nowrap="nowrap">
                                      <Card card-h-100 OrderDetailsBox>
                                        <CardBody>
                                          <Row>
                                            <Col>
                                              <p>
                                                <strong>Reference No: </strong>
                                                {val?.referenceno}
                                              </p>
                                              <p>
                                                <strong>Grand total: </strong>
                                                {val?.grand_total}
                                              </p>
                                              <Link
                                                to={`/orders/${val?.order_id}/?order_mode=split`}
                                                className="btn btn-sm btn-outline-warning waves-effect waves-light mr-2"
                                              >
                                                <i className="mdi mdi-eye-outline"></i>{' '}
                                              </Link>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  );
                })}
                <Modal
                  isOpen={modal_backdrop}
                  toggle={() => {
                    tog_backdrop();
                  }}
                  backdrop={'static'}
                  id="staticBackdrop"
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Delete Order
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        setmodal_backdrop(false);
                      }}
                      aria-label="Close"
                    ></button>
                  </div>
                  {togglePopup === false ? (
                    <>
                      <div className="modal-body">
                        <p>Do you want to Delete this order?</p>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setmodal_backdrop(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={() => {
                            setTogglePopup(true);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {togglePopup === true ? (
                        <>
                          <div className="modal-body">
                            <AvForm
                              onValidSubmit={() => {
                                OrderDetailFunc();
                              }}
                            >
                              <p>Enter the password</p>
                              <Row className="mb-3">
                                <Col>
                                  <AvField
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={togglePassword}
                                    onChange={(e) => {
                                      setTogglePassword(e?.target?.value);
                                    }}
                                    required
                                  />
                                </Col>
                              </Row>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => {
                                    setmodal_backdrop(false);
                                  }}
                                >
                                  Cancel
                                </button>

                                <button type="submit" className="btn btn-dark">
                                  {deleteButtonLoad === true && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}{' '}
                                  Submit
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Modal>
              </Table>
            </div>
            <div className="dataTblFooter" nowrap="nowrap">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                  style={{ width: 'max-content' }}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-dark"
                    nowrap="nowrap"
                    sytyle={{ width: '30px' }}
                  >
                    {limitValue.limit} Items{' '}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          className="DropdownItem1"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={listOrders?.recordsTotal}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Reference No</th>
                  <th>Name</th>
                  <th>Grand Total</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>

      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default OrderInstallationList;
